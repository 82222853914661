<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <p style="font-size: 25px; color: #444444; margin-top: 15px">
        {{ $t("EditGroup") }}
      </p>
    </div>
    <div class="mt-6">
      <v-card>
        <div style="padding-top: 20px; margin-left: 2rem; margin-right: 2rem">
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                autocomplete="off"
                v-model="edituergroup.gpname"
                v-on:keyup="keymonitor"
                :error-messages="error.gpname"
                color="#8A8C8E"
              >
                <template v-slot:label>
                  <span>
                    {{ $t("GroupName") }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                autocomplete="off"
                readonly
                v-model="edituergroup.gpmember"
                :error-messages="error.gpmember"
                color="#8A8C8E"
                ><template v-slot:label>
                  <span>
                    {{ $t("GroupMember")
                    }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
            <v-col>
              <v-btn
                rounded
                width="100"
                class="text-capitalize mt-3"
                style="color: #444444; background: #f99d20; font-weight: normal"
                @click="GotoMem"
                >{{ $t("edit") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                autocomplete="off"
                readonly
                v-model="edituergroup.gprole"
                :error-messages="error.gprole"
                color="#8A8C8E"
              >
                <template v-slot:label>
                  <span>
                    {{ $t("Role") }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
            <v-col>
              <v-btn
                rounded
                width="100"
                class="text-capitalize mt-3"
                style="color: #444444; background: #f99d20; font-weight: normal"
                router
                :to="{
                  name: 'usergroupeditrole',
                  params: {
                    usergroupedit: 'usergroupedit',
                    ugid: `${this.$route.params.ugid}`,
                    ugrole: 'usergroupeditrole',
                  },
                }"
                >{{ $t("edit") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3" align="right">
              <v-btn
                color="#444444"
                class="text-capitalize"
                style="font-weight: normal"
                text
              >
                {{ $t("Advance") }}&nbsp;
                <img width="30" height="30" :src="Advanceimage.up" alt />
              </v-btn>
            </v-col>
          </v-row>
          <div v-show="!showper">
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  autocomplete="off"
                  :label="$t('Permission')"
                  v-model="edituergroup.gpper"
                  readonly
                  color="#8A8C8E"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn
                  rounded
                  width="100"
                  class="text-capitalize mt-3"
                  style="
                    color: #444444;
                    background: #f99d20;
                    font-weight: normal;
                  "
                  router
                  :to="{
                    name: 'usergroupeditpermission',
                    params: {
                      usergroupedit: 'usergroupedit',
                      ugid: `${this.$route.params.ugid}`,
                      ugper: 'usergroupeditpermission',
                    },
                  }"
                  >{{ $t("edit") }}</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </div>
        <div style="height: 30vh"></div>
        <v-card-actions class="pb-6 mr-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            text
            @click="goback"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-4 text-capitalize commonsavebtn"
            @click="updateUG"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
    <!-- Loadingdialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>
<script>
import enurl from "@/api/environment";
import axios from "axios";
import store from "@/store";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, utils],
  data() {
    return {
      url: enurl.apiUrl,
      showper: false,
      ugID: 0,
      UserGroupDataList: [],
      UserGroupMemberList: [],
      UserGroupRoleList: [],
      UserGroupPermitList: [],
      PersonalList: [],
      RoleList: [],
      PermissionList: [],
      taskSt: [],
      localrole: [],
      localpermit: [],
      localmemrole: [],
      TempEmpList: [],
      TempRoleList: [],
      TempPermitList: [],
      UserRoleList: [],
      tpl: [],
      trl: [],
      tml: [],
      LoadingDialog: false,
      routeback: "/usermanagement/usergroup",
      edituergroup: {
        gpname: null,
        gpmember: [],
        gprole: [],
        gpper: [],
      },
      editug: {
        empid: [],
        rlid: [],
        perid: [],
      },
      Advanceimage: {
        up: require("@/assets/images/up1@2x.png"),
        down: require("@/assets/images/down1@2x.png"),
      },
      error: {
        gpname: null,
        gpmember: null,
        gprole: null,
      },
    };
  },
  mounted() {
    this.GetRoleOfMember();
    this.GetUserGroup();
    //this.GetUserGroupPermit();
    //this.GetUserGroupMember();
    // this.GetUserGroupRole();
    //this.GetPersonal();
    //this.GetRole();
    //this.GetPermission();
  },
  methods: {
    GotoMem() {
      let self = this;
      self.ugID = self.$route.params.ugid;
      this.$router.push({
        name: "usergroupeditmember",
        params: {
          usergroupedit: "usergroupedit",
          ugid: self.ugID,
          ugmem: "usergroupeditmember",
        },
      }).catch(()=>{});
    },
    keymonitor: function () {
      localStorage.setItem(
        "localGPName",
        JSON.stringify(this.edituergroup.gpname)
      );
    },
    GetRoleOfMember() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}UserGroupMember/GetUserRoleByCompanyID`, tempp)
        .then(function (response) {
          self.UserRoleList = response.data.data;
        })
        .catch(function (error) {
          alert(error);
        });
    },
    goback() {
      localStorage.setItem("localGPName", JSON.stringify(null));
      localStorage.setItem("localmember", JSON.stringify(null));
      localStorage.setItem("localgrouprole", JSON.stringify(null));
      localStorage.setItem("localgrouppermit", JSON.stringify(null));
      this.$router.push("/usermanagement/usergroup").catch(()=>{});
    },
    // GetUserGroupPermit() {
    //   let self = this;
    //   self.LoadingDialog = true;
    //   let tempp = {
    //     user_Group_ID: self.$route.params.ugid,
    //     company_ID: store.state.companyID
    //   };
    //   axios
    //     .post(`${self.url}UserGroup/GetUserGroupInfo`, tempp)
    //     .then(function(response) {
    //       self.UserGroupPermitList = response.data.data;
    //       self.TempPermitList = self.UserGroupPermitList[0].permission_ID;
    //       self.LoadingDialog = false;
    //     });
    // },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    GetUserGroup() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        user_Group_ID: parseInt(self.$route.params.ugid),
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}UserGroup/GetUserGroupInfo`, tempp)
        .then(function (response) {
          self.UserGroupDataList = response.data.data;

          if (self.UserGroupDataList != null) {
            let lcgn = JSON.parse(localStorage.getItem("localGPName"));
            if (lcgn == null) {
              self.edituergroup.gpname = response.data.data.user_Group_Name;
              localStorage.setItem(
                "localGPName",
                JSON.stringify(self.edituergroup.gpname)
              );
            } else {
              self.edituergroup.gpname = lcgn;
            }

            //EmployeeName
            let tempmemolsit = self.getUniqueListBy(
              response.data.data.employeeList,
              "employee_Name"
            );
            let rolelist = [];
            self.edituergroup.gpmember = tempmemolsit.map(
              (x) => x.employee_Name
            );

            self.taskSt = JSON.parse(localStorage.getItem("localmember"));
            if (self.taskSt != null) {
              self.edituergroup.gpmember = [
                ...self.taskSt.map((x) => x.empname),
              ];

              self.TempEmpList = [...self.taskSt.map((x) => x.empid)];

              self.edituergroup.gpmember = [
                ...new Set(self.edituergroup.gpmember),
              ];

              if (self.TempEmpList != null) {
                for (let i = 0; i < self.TempEmpList.length; i++) {
                  self.tml.push({
                    employee_ID: self.TempEmpList[i],
                  });
                }
              }
            } else {
              let tempuid = response.data.data.employeeList.map(
                (x) => x.employee_ID
              );
              for (let x = 0; x < tempuid.length; x++) {
                self.tml.push({
                  employee_ID: tempuid[x],
                });
                for (let j = 0; j < self.UserRoleList.length; j++) {
                  if (self.UserRoleList[j].employee_ID == tempuid[x]) {
                    rolelist.push(self.UserRoleList[j].role_ID);
                  }
                }
              }
              let cheekycheese = rolelist.filter((data, index) => {
                return rolelist.indexOf(data) === index;
              });
              if (cheekycheese.length == 0) {
                localStorage.setItem("check_role_mem", 0);
              } else {
                localStorage.setItem("check_role_mem", cheekycheese);
              }
            }
            if (
              self.edituergroup.gpmember == null ||
              self.edituergroup.gpmember.length == 0
            ) {
              self.edituergroup.gpmember = null;
            }

            //RoleName
            self.localmemrole = parseInt(
              localStorage.getItem("check_role_mem")
            );
            self.edituergroup.gprole = response.data.data.roleList.map(
              (x) => x.role_Name
            );
            self.localrole = JSON.parse(localStorage.getItem("localgrouprole"));
            if (self.localrole != null) {
              self.edituergroup.gprole = [
                ...self.localrole.map((x) => x.rolename),
              ];
              self.TempRoleList = [...self.localrole.map((x) => x.roleid)];

              self.edituergroup.gprole = [...new Set(self.edituergroup.gprole)];
              if (self.TempRoleList != null) {
                for (let j = 0; j < self.TempRoleList.length; j++) {
                  self.trl.push({
                    role_ID: self.TempRoleList[j],
                  });
                }
              }
            } else {
              let temprid = response.data.data.roleList.map((x) => x.role_ID);
              for (let x = 0; x < temprid.length; x++) {
                self.trl.push({
                  role_ID: temprid[x],
                });
              }
            }
            if (
              self.edituergroup.gprole == null ||
              self.edituergroup.gprole.length == 0
            ) {
              self.edituergroup.gprole = null;
            }

            //PermissionName
            self.edituergroup.gpper = response.data.data.permitList.map(
              (x) => x.permission_Name
            );
            self.localpermit = JSON.parse(
              localStorage.getItem("localgrouppermit")
            );
            if (self.localpermit != null) {
              self.edituergroup.gpper = [
                ...self.localpermit.map((x) => x.pername),
              ];
              self.edituergroup.gpper = [...new Set(self.edituergroup.gpper)];
              self.TempPermitList = [...self.localpermit.map((x) => x.perid)];

              if (self.TempPermitList != null) {
                for (let j = 0; j < self.TempPermitList.length; j++) {
                  self.tpl.push({
                    permission_ID: self.TempPermitList[j],
                  });
                }
              }
            } else {
              let tempr = response.data.data.permitList.map(
                (x) => x.permission_ID
              );
              for (let x = 0; x < tempr.length; x++) {
                self.tpl.push({
                  permission_ID: tempr[x],
                });
              }
            }
          }
          // self.edituergroup.gpname = self.UserGroupDataList[0].user_Group_Name;employeeList
          self.LoadingDialog = false;
        });
    },
    // GetUserGroupMember() {
    //   let self = this;
    //   self.LoadingDialog = true;
    //   let tempp = {
    //     user_Group_ID: self.$route.params.ugid,
    //     company_ID: store.state.companyID
    //   };
    //   axios
    //     .post(`${self.url}UserGroup/GetUserGroupInfo`, tempp)
    //     .then(function(response) {
    //       self.UserGroupMemberList = response.data.data;
    //       self.TempEmpList = self.UserGroupMemberList[0].employee_ID;
    //       self.LoadingDialog = false;
    //     });
    // },
    // GetPersonal() {
    //   let self = this;
    //   self.LoadingDialog = true;
    //   self.taskSt = JSON.parse(localStorage.getItem("localmember"));
    //   let tempp = {
    //     company_ID: store.state.companyID
    //   };
    //   axios
    //     .post(`${self.url}PersonalInfo/GetPersonalInfoByCompanyID`, tempp)
    //     .then(function(response) {
    //       self.PersonalList = response.data.data;
    //       self.GetUserGroupMember();
    //       self.LoadingDialog = false;
    //       if (self.taskSt != null) {
    //         self.TempEmpList = self.taskSt;
    //         for (let i = 0; i < self.PersonalList.length; i++) {
    //           for (let j = 0; j < self.TempEmpList.length; j++) {
    //             if (self.TempEmpList[j] == self.PersonalList[i].employee_ID) {
    //               self.editug.empid.push(self.PersonalList[i].employee_ID);
    //               self.edituergroup.gpmember.push(
    //                 self.PersonalList[i].fullName
    //               );
    //             }
    //           }
    //         }
    //       } else {
    //         self.TML = self.TempEmpList;
    //         for (let m = 0; m < self.PersonalList.length; m++) {
    //           for (let n = 0; n < self.TML.length; n++) {
    //             if (self.TML[n] == self.PersonalList[m].employee_ID) {
    //               self.editug.empid.push(self.PersonalList[m].employee_ID);
    //               self.edituergroup.gpmember.push(
    //                 self.PersonalList[m].fullName
    //               );
    //             }
    //           }
    //         }
    //       }
    //     });
    // },
    // GetUserGroupRole() {
    //   let self = this;
    //   self.LoadingDialog = true;
    //   let tempp = {
    //     user_Group_ID: self.$route.params.ugid,
    //     company_ID: store.state.companyID
    //   };
    //   axios
    //     .post(`${self.url}UserGroup/GetUserGroupInfo`, tempp)
    //     .then(function(response) {
    //       self.UserGroupRoleList = response.data.data;
    //       self.TempRoleList = self.UserGroupRoleList[0].role_ID;
    //       self.LoadingDialog = false;
    //     });
    // },
    // GetRole() {
    //   let self = this;
    //   self.LoadingDialog = true;
    //   self.localrole = JSON.parse(localStorage.getItem("localgrouprole"));
    //   console.log(self.localrole);
    //   let tempp = {
    //     company_ID: store.state.companyID
    //   };
    //   axios
    //     .post(`${self.url}Role/GetRoleByCompanyID`, tempp)
    //     .then(function(response) {
    //       self.RoleList = response.data.data;
    //       self.GetUserGroupRole();
    //       self.LoadingDialog = false;
    //       if (self.localrole == null) {
    //         self.TRL = self.TempRoleList;
    //         for (let m = 0; m < self.RoleList.length; m++) {
    //           for (let n = 0; n < self.TRL.length; n++) {
    //             if (self.TRL[n] == self.RoleList[m].role_ID) {
    //               self.editug.rlid.push(self.RoleList[m].role_ID);
    //               self.edituergroup.gprole.push(self.RoleList[m].role_Name);
    //             }
    //           }
    //         }
    //       } else if (self.localrole != null) {
    //         self.TempRoleList = self.localrole;

    //         for (let i = 0; i < self.RoleList.length; i++) {
    //           for (let j = 0; j < self.TempRoleList.length; j++) {
    //             if (self.TempRoleList[j] == self.RoleList[i].role_ID) {
    //               self.editug.rlid.push(self.RoleList[i].role_ID);
    //               self.edituergroup.gprole.push(self.RoleList[i].role_Name);
    //             }
    //           }
    //         }
    //       }
    //     });
    // },

    // GetPermission() {
    //   let self = this;
    //   self.LoadingDialog = true;
    //   self.localpermit = JSON.parse(localStorage.getItem("localgrouppermit"));
    //   axios.get(`${self.url}Permission/GetPermission`).then(function(response) {
    //     self.PermissionList = response.data.data;
    //     self.GetUserGroupPermit();
    //     // localStorage.setItem("localmember",JSON.stringify(this.selected));
    //     self.LoadingDialog = false;
    //     if (self.localpermit != null) {
    //       self.TempPermitList = self.localpermit;
    //       for (let i = 0; i < self.PermissionList.length; i++) {
    //         for (let j = 0; j < self.TempPermitList.length; j++) {
    //           if (
    //             self.TempPermitList[j] == self.PermissionList[i].permission_ID
    //           ) {
    //             self.editug.perid.push(self.PermissionList[i].permission_ID);
    //             self.edituergroup.gpper.push(
    //               self.PermissionList[i].permission_Name
    //             );
    //           }
    //         }
    //       }
    //     } else {
    //       for (let m = 0; m < self.PermissionList.length; m++) {
    //         for (let n = 0; n < self.TPL.length; n++) {
    //           if (self.TPL[n] == self.PermissionList[m].permission_ID) {
    //             self.editug.perid.push(self.PermissionList[m].permission_ID);
    //             self.edituergroup.gpper.push(
    //               self.PermissionList[m].permission_Name
    //             );
    //           }
    //         }
    //       }
    //     }
    //   });
    // },
    showpermit() {
      let self = this;
      self.showper = !self.showper;
    },
    DeleteoldUserGP() {
      let self = this;
      self.LoadingDialog = true;
      let id = parseInt(self.$route.params.ugid);
      let tempdel = {
        user_Group_ID: id,
      };
      axios
        .post(`${self.url}UserGroup/DeleteOldUserGroupInfo`, tempdel)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    updateUG() {
      let self = this;
      self.LoadingDialog = true;
      self.error.gpname = !self.edituergroup.gpname
        ? "Please fill in the required fields"
        : "";
      self.error.gpmember = !self.edituergroup.gpmember
        ? "Please fill in the required fields"
        : "";
      self.error.gprole = !self.edituergroup.gprole
        ? "Please fill in the required fields"
        : "";
      let tempvali = self.ValidateUpdateTrue();
      if (tempvali == true) {
        if (self.localmemrole != 0) {
          if (
            self.trl[0].role_ID == self.localmemrole ||
            self.localmemrole == "NaN"
          ) {
            self.DeleteoldUserGP();
            // let tempmember = self.addtempmember();
            // let temprole = self.addtemprole();
            // let temppermit = self.addtemppermit();
            let temp = {
              user_Group_ID: parseInt(self.$route.params.ugid),
              user_Group_Name: self.edituergroup.gpname,
              company_ID: store.state.companyID,
              userGroupMemberData: self.tml,
              userGroupRoleData: self.trl,
              userGroupPermitData: self.tpl,
            };

            axios
              .post(`${self.url}UserGroup/UpdateUserGroup`, temp)
              .then(function (response) {
                if (response.data.status == 0) {
                  alert(response.data.message);
                  self.LoadingDialog = false;
                  localStorage.setItem("localGPName", JSON.stringify(null));
                  localStorage.setItem("localmember", JSON.stringify(null));
                  localStorage.setItem("localgrouprole", JSON.stringify(null));
                  localStorage.setItem(
                    "localgrouppermit",
                    JSON.stringify(null)
                  );
                  self.LogTrace(null, "Update User Group", 35, "Medium");
                  self.$router.push("/usermanagement/usergroup").catch(()=>{});
                }
              })
              .catch(function (error) {
                alert(error);
                self.LogTrace(error, "Update User Group Fail", 35, "Medium");
                self.LoadingDialog = false;
              });
          } else {
            alert("Role can't be different!");
            self.LoadingDialog = false;
          }
        } else {
          self.DeleteoldUserGP();
          // let tempmember = self.addtempmember();
          // let temprole = self.addtemprole();
          // let temppermit = self.addtemppermit();
          let temp = {
            user_Group_ID: parseInt(self.$route.params.ugid),
            user_Group_Name: self.edituergroup.gpname,
            company_ID: store.state.companyID,
            userGroupMemberData: self.tml,
            userGroupRoleData: self.trl,
            userGroupPermitData: self.tpl,
          };

          axios
            .post(`${self.url}UserGroup/UpdateUserGroup`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.LoadingDialog = false;
                localStorage.setItem("localGPName", JSON.stringify(null));
                localStorage.setItem("localmember", JSON.stringify(null));
                localStorage.setItem("localgrouprole", JSON.stringify(null));
                localStorage.setItem("localgrouppermit", JSON.stringify(null));
                localStorage.clear();
                self.LogTrace(null, "Update User Group", 35, "Medium");
                self.$router.push("/usermanagement/usergroup").catch(()=>{});
              }
            })
            .catch(function (error) {
              alert(error);
              self.LogTrace(error, "Update User Group Fail", 35, "Medium");
              self.LoadingDialog = false;
            });
        }
      } else {
        self.ValidateUpdateFalse();
        self.LoadingDialog = false;
      }
    },
    // addtempmember() {
    //   let self = this;
    //   let temparr = [
    //     {
    //       employee_ID: null
    //     }
    //   ];
    //   for (let i = 0; i < self.editug.empid.length; i++) {
    //     if (i == 0) {
    //       temparr[i].employee_ID = self.editug.empid[i];
    //     } else {
    //       temparr.push({ employee_ID: self.editug.empid[i] });
    //     }
    //   }
    //   return temparr;
    // },
    // addtemprole() {
    //   let self = this;
    //   let temparr = [
    //     {
    //       role_ID: null
    //     }
    //   ];
    //   for (let i = 0; i < self.editug.rlid.length; i++) {
    //     if (i == 0) {
    //       temparr[i].role_ID = self.editug.rlid[i];
    //     } else {
    //       temparr.push({ role_ID: self.editug.rlid[i] });
    //     }
    //   }
    //   return temparr;
    // },
    // addtemppermit() {
    //   let self = this;
    //   let temparr = [
    //     {
    //       permission_ID: null
    //     }
    //   ];
    //   for (let i = 0; i < self.editug.perid.length; i++) {
    //     if (i == 0) {
    //       temparr[i].permission_ID = self.editug.perid[i];
    //     } else {
    //       temparr.push({ permission_ID: self.editug.perid[i] });
    //     }
    //   }
    //   return temparr;
    // },
    ValidateUpdateTrue() {
      let self = this;
      if (
        self.edituergroup.gpname != null &&
        self.edituergroup.gpmember != null &&
        self.edituergroup.gprole != null &&
        self.edituergroup.gpname != "" &&
        self.edituergroup.gpmember != "" &&
        self.edituergroup.gprole != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    ValidateUpdateFalse() {
      let self = this;
      if (
        self.edituergroup.gpname == null ||
        self.edituergroup.gpmember == null ||
        self.edituergroup.gprole == null ||
        self.edituergroup.gpname == "" ||
        self.edituergroup.gpmember == "" ||
        self.edituergroup.gprole == ""
      ) {
        alert("Please fill in the required fields!");
      }
    },
  },
};
</script>
