<template>
  <div class="ml-5 mr-5">
    <br/>
    <v-row class="pl-md-0" style="margin-left: 15px; margin-right: 10px; margin-top: 0rem !important">
      <div class="d-flex">
        <img class="healthIcon mt-2" style="margin-top:auto;margin-bottom:auto;" :src="images.healthIcon" alt="healthIcon"/>
        <div class="ml-3 mt-2">
          <label style="font-size: 17px; color: #f89d1f" alt="backIcon">
            {{ $t("healthinfo") }}
          </label>
        </div>
      </div>
      
      <v-spacer />
      <div class="d-flex ml-3">
        <v-btn
          color="#F99D20"
          class="white--text text-capitalize"
          width="150"
          max-height="35"
          :disabled="
            $route.path ==
            '/selfservice/PersonalProfile/PersonalInformation/HealthInformation'
              ? permission.add
              : permission_management.add
          "
          @click="Adddialog = true"
          >{{ $t("Add") }}</v-btn
        >
      </div>
    </v-row>
    
    <div style="margin-top: 2rem">
      <v-data-table
        :headers="gethealthheader"
        :items="HealthData"
        :search="search"
        hide-default-footer
        hide-default-header
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :mobile-breakpoint="0"
      >
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr>
              <th>{{ $t("date") }}</th>
              <th>{{ $t("healthcondition") }}</th>
              <th>{{ $t("file") }}</th>
              <th>{{ $t("Actions") }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ format(item.createDate) }}</td>
            <td>{{ item.health_Condition }}</td>
            <td>
              <a
                :href="item.health_File"
                style="text-decoration: none; color: black"
                >{{ item.health_Filename }}</a
              >
            </td>

            <td>
              <v-btn
                icon
                @click="Editdia(item)"
                :disabled="
                  $route.path ==
                  '/selfservice/PersonalProfile/PersonalInformation/HealthInformation'
                    ? permission.edit
                    : permission_management.edit
                "
              >
                <img class="editIcon" :src="images.edit" alt="editIcon"/>
              </v-btn>
              <v-btn
                icon
                @click="Deletedia(item.health_ID)"
                :disabled="
                  $route.path ==
                  '/selfservice/PersonalProfile/PersonalInformation/HealthInformation'
                    ? permission.delete
                    : permission_management.delete
                "
              >
                <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="text-right" v-if="HealthData.length">
        <v-row justify="end" class="ml-5 mt-5 mr-5">
          <v-col cols="12" md="3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="#FFCD2C"
              :total-visible="6"
            ></v-pagination>
          </v-col>
          <v-col cols="12" md="2" class="mt-1">
            <v-select
              dense
              style="width: 120px; float: right"
              solo
              label="10/page"
              v-model="itemsPerPage"
              :items="items"
              @input="itemsPerPage = parseInt($event, 10)"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </div>
    
    <!-- loadingdialog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>

    
    <!-- add Health dialog -->
    <v-dialog v-model="Adddialog" persistent max-width="700px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span class="headline"> {{ $t("NewEntry") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container style="margin-left: -0.5rem; margin-top: -1.3rem">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-3">
                <v-text-field
                  autocomplete="off"
                  v-model="addhealth.health"
                  color="#F89D1F"
                  :error-messages="errorMessage.health"
                  required
                >
                  <template v-slot:label>
                    <span>
                      {{ $t("healthcondition")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
                <!-- <span v-if="errorMessage.health" class="red--text">
                  {{ $t("requirehealth") }}</span
                > -->
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <!-- <v-menu
                  v-model="date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      autocomplete="off"
                      v-model="computedDateFormatted"
                      class="kanit-medium"
                      color="#F99D20"
                      persistent-hint
                      append-icon="mdi-calendar-month-outline"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span>
                          {{ $t("date")
                          }}<span class="red--text"> *</span></span
                        >
                      </template>
                      <template v-slot:append>
                        <img
                          class="mr-3"
                          style="margin-top: 3px"
                          width="18px"
                          height="18px"
                          src="@/assets/images/Mask Group 160@2x.png"
                        />
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="addhealth.putdate"
                    no-title
                    @input="date = false"
                  ></v-date-picker>
                </v-menu> -->
                <label :style="{color: errorMessage.putdate ? ' #FF4B55' : '#8a8c8e'}"><span>{{ $t("date") }}<span class="red--text"> *</span></span></label>
                <date-picker :class="errorMessage.putdate ? 'custom-vuedatepicker-er-mg' : 'custom-vuedatepicker'"  v-bind:clearable="false" value-type="format" v-model="addhealth.putdate" 
                no-title format="DD/MM/YYYY" :editable="false" :style="{ width: '100%' }">
                </date-picker>
                <span v-if="errorMessage.putdate" class="error-message">
                  {{ $t("requiredate") }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="margin-left: -1rem" align="left" cols="6">
                <p class="ml-4">{{ addhealth.filename }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="left" cols="12" sm="6" md="3">
                <p>{{ $t("suppfile") }}<span class="red--text"> *</span></p>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-btn
                  color="#F89D1F"
                  class="white--text text-capitalize pickbtn"
                  style="text-align: left; float: left; margin-top: -10px"
                  width="150"
                  max-height="35"
                  @click="onPickFile"
                >
                  <img
                    width="20"
                    height="20"
                    class="mr-2"
                    :src="images.arrow"
                    alt
                  />
                  {{ $t("upload") }}
                  <input
                    ref="file"
                    @change="onFilePicked"
                    accept="application/pdf"
                    type="file"
                    style="display: none"
                    id="file"
                  />
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <p style="color: #f74747">**{{ $t("imagefiletype") }}**</p>
              </v-col>
            </v-row>
            <v-col
              align="left"
              cols="12"
              sm="6"
              md="6"
              style="margin-left: -1rem"
            >
              <span v-if="errorMessage.filename" class="red--text">
                {{ $t("requirefile") }}</span
              >
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            text
            @click="Cancel"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            v-show="showHealthBtnWithEid"
            color="#F99D20"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="AddHealthInformation()"
            >{{ $t("Add") }}</v-btn
          >
          <v-btn
            v-show="showHealthBtnWithCde"
            color="#F99D20"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="AddHealthInformationWithCode()"
            >{{ $t("Add") }}</v-btn
          >
          <v-btn
            v-show="showHealthBtnWithSid"
            color="#F99D20"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="AddHealthInformationWithSid()"
            >{{ $t("Add") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit Health dialog -->
    <v-dialog v-model="editdialog" persistent max-width="700px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span class="headline">Edit Entry</span>
        </v-card-title>
        <v-card-text>
          <v-container style="margin-left: -0.5rem; margin-top: -1.3rem">
            <v-row>
              <v-col cols="12" sm="6" md="6" class="mt-2">
                <v-text-field
                  v-model="edithealth.health"
                  :error-messages="errorMessageEdit.edithealth"
                  color="#F89D1F"
                  required
                >
                  <template v-slot:label>
                    <span>
                      {{ $t("healthcondition")
                      }}<span class="red--text"> *</span></span
                    >
                  </template></v-text-field
                >
                <!-- <span v-if="errorMessageEdit.edithealth" class="red--text">
                  {{ $t("requirehealth") }}</span
                > -->
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <!-- <v-menu
                  v-model="dateupdate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  fluid
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      readonly
                      v-model="computedDateFormat"
                      class="kanit-medium"
                      color="#F99D20"
                      persistent-hint
                      append-icon="mdi-calendar-month-outline"
                      v-on="on"
                    >
                      <template v-slot:label>
                        <span>
                          {{ $t("date")
                          }}<span class="red--text"> *</span></span
                        >
                      </template>
                      <template v-slot:append>
                        <img
                          class="mr-3"
                          style="margin-top: 3px"
                          width="18px"
                          height="18px"
                          src="@/assets/images/Mask Group 160@2x.png"
                        />
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="edithealth.putdate"
                    no-title
                    @input="dateupdate = false"
                  ></v-date-picker>
                </v-menu> -->
                <label :style="{color: errorMessageEdit.editputdate ? ' #FF4B55' : '#8a8c8e'}"><span>{{ $t("date") }}<span class="red--text"> *</span></span></label>
                <date-picker :class="errorMessageEdit.editputdate ? 'custom-vuedatepicker-er-mg' : 'custom-vuedatepicker'" v-bind:clearable="false" value-type="format" v-model="edithealth.putdate" 
                  no-title format="DD/MM/YYYY" :editable="false" :style="{ width: '100%' }">
                </date-picker>
                <span v-if="errorMessageEdit.editputdate" class="error-message">
                  {{ $t("requiredate") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="margin-left: -1rem" align="left" cols="6">
                <p class="ml-4">{{ edithealth.filename }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col align="left" cols="12" sm="6" md="3">
                <p>{{ $t("suppfile") }}<span class="red--text"> *</span></p>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-btn
                  color="#F89D1F"
                  class="white--text text-capitalize"
                  style="text-align: left; float: left; margin-top: -10px"
                  width="150"
                  max-height="35"
                  @click="onPickFileUpdate"
                >
                  <img
                    width="20"
                    height="20"
                    class="mr-2"
                    :src="images.arrow"
                    alt
                  />

                  {{ $t("upload") }}
                  <input
                    ref="file"
                    @change="onFilePickedUpdate"
                    accept="application/pdf"
                    type="file"
                    style="display: none"
                    id="files"
                  />
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <p style="color: #f74747">**{{ $t("imagefiletype") }}**</p>
              </v-col>
            </v-row>
            <v-col
              align="left"
              cols="12"
              sm="6"
              md="6"
              style="margin-left: -1rem"
            >
              <span v-if="errorMessageEdit.editfilename" class="red--text">
                {{ $t("requirefile") }}</span
              >
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            text
            @click="editdialog = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            v-show="showHealthBtnWithEid"
            color="#F99D20"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="UpdateHealthInfo()"
            >{{ $t("update") }}</v-btn
          >
          <v-btn
            v-show="showHealthBtnWithCde"
            color="#F99D20"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="UpdateHealthInfoEmpcode()"
            >{{ $t("update") }}</v-btn
          >
          <v-btn
            v-show="showHealthBtnWithSid"
            color="#F99D20"
            class="mr-2 text-capitalize"
            width="120"
            max-height="35"
            @click="UpdateHealthInfoEmpsid()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- delete department dialog -->
    <v-dialog v-model="Deletedialog" persistent max-width="532">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 17px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2 text-capitalize commoncancelbtn"
            outlined
            @click="Deletedialog = false"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-2 commondeletebtn text-capitalize"
            @click="DeleteHealth()"
            >{{ $t("delete") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  name: "HealthInformation",
  mixins: [LogTrace, utils, CheckViewAddEditDelete],
  data() {
    return {
      search: null,
      permission: {
        add: true,
        edit: true,
        delete: true,
      },
      permission_management: {
        add: true,
        edit: true,
        delete: true,
      },
      editdialog: false,
      Deletedialog: false,
      showHealthBtnWithEid: false,
      showHealthBtnWithCde: false,
      showHealthBtnWithSid: false,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 15, 20],
      chosenfile: null,
      health_ID: 0,
      url: enurl.apiUrl,
      companyid: store.state.companyID,
      addhealth: {
        health: null,
        putdate: "",
        filename: "",
        fileUrl: null,
      },
      errorMessage: {
        health: null,
        putdate: "",
        filename: "",
      },
      errorMessageEdit: {
        edithealth: null,
        editputdate: "",
        editfilename: "",
      },
      edithealth: {
        eid: 0,
        id: null,
        health: null,
        putdate: "",
        filename: "",
        fileUrl: null,
      },
      base64: null,
      date: false,
      dateupdate: false,
      showup: false,
      loadingdialog: false,
      uploaddialog: false,
      Adddialog: false,
      fileUrl: "",
      headers: [],
      HealthData: [],
    };
  },
  watch: {
    "addhealth.health"(v) {
      if (v) this.errorMessage.health = null;
    },
    "edithealth.health"(v) {
      if (v) this.errorMessageEdit.edithealth = null;
    },
    "addhealth.putdate"(v) {
      if (v) this.errorMessage.putdate = "";
    },
    "edithealth.putdate"(v) {
      if (v) this.errorMessageEdit.editputdate = "";
    },
    "addhealth.filename"(v) {
      if (v) this.errorMessage.filename = "";
    },
    "edithealth.filename"(v) {
      if (v) this.errorMessageEdit.editfilename = "";
    },
  },

  computed: {
    gethealthheader() {
      return [
        {
          text: this.$t("date"),
          align: "start",
          value: "createDate",
          sortable: true,
        },
        {
          text: this.$t("healthcondition"),
          value: "health_Condition",
          sortable: true,
        },
        { text: this.$t("file"), value: "health_Filename", sortable: true },
        { text: "Action", sortable: false },
      ];
    },
    images() {
      return this.$store.state.images;
    }
  },

  mounted() {
    this.permission = this.CheckViewAddEditDelete(139, 140, 141, 142);
    this.permission_management = this.CheckViewAddEditDelete(
      211,
      212,
      213,
      214
    );
    this.disable_btn();
    if (
      this.$route.path ==
      "/selfservice/PersonalProfile/PersonalInformation/HealthInformation"
    ) {
      this.GetHealthWithSid();
      this.showHealthBtnWithEid = false;
      this.showHealthBtnWithCde = false;
      this.showHealthBtnWithSid = true;
    } else if (this.$route.params.id != null) {
      this.GetHealth();
      this.showHealthBtnWithEid = true;
      this.showHealthBtnWithCde = false;
      this.showHealthBtnWithSid = false;
    } else if (localStorage.empcode != null) {
      this.GetHealthWithCode();
      this.showHealthBtnWithEid = false;
      this.showHealthBtnWithCde = true;
      this.showHealthBtnWithSid = false;
    } else {
      this.showHealthBtnWithEid = false;
      this.showHealthBtnWithCde = true;
      this.showHealthBtnWithSid = false;
    }
  },
  methods: {
    //permission
    disable_btn() {
      let self = this;
      if (
        self.$route.path ==
        "/selfservice/PersonalProfile/PersonalInformation/HealthInformation"
      )
      {
        if (self.permission.add == false)
        {
          self.permission.add = false;
        }
        if (self.permission.edit == false)
        {
          self.permission.edit = false;
        }
        if (self.permission.delete == false)
        {
          self.permission.delete = false;
        }
      }
      else
      {
        if (self.permission_management.add == false)
        {
          self.permission_management.add = false;
        }
        if (self.permission_management.edit == false)
        {
          self.permission_management.edit = false;
        }
        if (self.permission_management.delete == false)
        {
          self.permission_management.delete = false;
        }
      }
    },

    errorAlertEdit() {
      let { errorMessageEdit, edithealth } = this;
      errorMessageEdit.edithealth = !edithealth.health
        ? this.$t("requirehealth")
        : "";
      errorMessageEdit.editputdate = !edithealth.putdate
        ? "Please fill in the required fields"
        : "";
      errorMessageEdit.editfilename = !edithealth.filename
        ? "Please fill in the required fields"
        : "";
    },

    errorAlert() {
      let { errorMessage, addhealth } = this;
      errorMessage.health = !addhealth.health
        ? this.$t("requirehealth")
        : "";

      errorMessage.putdate = !addhealth.putdate
        ? "Please fill in the required fields"
        : "";

      errorMessage.filename = !addhealth.filename
        ? "Please fill in the required fields"
        : "";
    },

    Cancel() {
      let self = this;
      self.Adddialog = false;
      self.addhealth.health = null;
      self.addhealth.putdate = "";
      self.addhealth.fileUrl = null;
      self.addhealth.filename = "";
      self.errorMessage.health = null;
      self.errorMessage.putdate = "";
      self.errorMessage.filename = "";
      self.errorMessage.edithealth = null;
      self.errorMessage.editputdate = "";
      self.errorMessage.editfilename = "";
    },

    Editdia(temp) {
      let self = this;
      self.editdialog = true;
      self.edithealth.id = temp.health_ID;
      self.edithealth.health = temp.health_Condition;
      self.edithealth.putdate = self.$moment(temp.createDate).format('DD/MM/YYYY');
      self.edithealth.fileUrl = temp.health_File;
      self.edithealth.filename = temp.health_Filename;
      self.edithealth.eid = temp.employee_ID;
    },

    onPickFile() {
      let self = this;
      self.$refs.file.value = null;
      document.getElementById("file").click();
    },

    onPickFileUpdate() {
      let self = this;
      self.$refs.file.value = null;
      document.getElementById("files").click();
    },

    DeleteHealth() {
      let self = this;
      self.loadingdialog = true;
      let id = self.health_ID;
      let temp = {
        health_ID: id,
      };
      axios.post(`${self.url}HealthInformation/DeleteHealthInformation`, temp)
      .then(function (response) {
        if (response.data.status == 0)
        {
          alert(response.data.message);
          //self.LogTrace(null, "Delete  Health Information", 18, "High");
          self.Deletedialog = false;
          self.loadingdialog = false;
          if (self.$route.path == "/selfservice/PersonalProfile/PersonalInformation/HealthInformation")
          {
            self.GetHealthWithSid();
          }
          else if (self.$route.params.id != undefined)
          {
            self.GetHealth();
          }
          if (localStorage.empcode != null)
          {
            self.GetHealthWithCode();
          }
        }
      })
      .catch(function (error) {
        alert(error);
        self.LogTrace(error, "Delete  Health Information", 18, "High");
        self.loadingdialog = false;
      });
    },

    Deletedia(id) {
      this.health_ID = id;
      this.Deletedialog = true;
    },

    onFilePicked() {
      let self = this;
      var selectedFile = document.getElementById("file").files;
      //Check File is not Empty
      if (selectedFile.length > 0) {
        // Select the very first file from list
        var fileToLoad = selectedFile[0];
        self.addhealth.filename = selectedFile[0].name;
        // FileReader function for read the file.
        var fileReader = new FileReader();
        // var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
          self.base64 = fileLoadedEvent.target.result.split(",")[1];
          self.fileUrl = self.base64;
          // self.addhealth.fileUrl=base64.split(",")[1];
          // Print data in console

          //console.log(self.fileUrl);
        };
        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
        selectedFile = null;
      }
    },
    
    onFilePickedUpdate() {
      let self = this;
      var selectedFile = document.getElementById("files").files;
      //Check File is not Empty
      if (selectedFile.length > 0) {
        // Select the very first file from list
        var fileToLoad = selectedFile[0];
        self.edithealth.filename = selectedFile[0].name;
        // FileReader function for read the file.
        var fileReader = new FileReader();
        // var base64;
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
          self.base64 = fileLoadedEvent.target.result.split(",")[1];
          self.edithealth.fileUrl = self.base64;
          // self.addhealth.fileUrl=base64.split(",")[1];
          // Print data in console

          //console.log(self.edithealth.fileUrl);
        };
        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
        selectedFile = null;
      }
    },

    UpdateHealthInfo() {
      let self = this;
      self.errorAlertEdit();
      if (self.edithealth.fileUrl != "" && self.edithealth.putdate != "" && self.edithealth.putdate != null && self.edithealth.health != null && self.edithealth.health != "")
      {
        self.loadingdialog = true;
        let tempdept = {
          health_ID: self.edithealth.id,
          health_Condition: self.edithealth.health,
          health_Filename: self.edithealth.filename,
          health_File: self.edithealth.fileUrl,
          // date: self.edithealth.putdate,
          date: self.$moment(self.edithealth.putdate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          employee_ID: self.edithealth.eid,
        };
        axios.post(`${self.url}HealthInformation/UpdateHealthInformation`,tempdept)
        .then(function (response) {
          self.loadingdialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.editdialog = false;
            self.GetHealth();
            //self.LogTrace(null, "Update  Health Information", 18, "Medium");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error,"Update Health Information Fail",18,"Medium");
          alert(error);
          self.loadingdialog = false;
        });
      }
      else
      {
        alert("Please fill in the fields");
        self.loadingdialog = false;
      }
    },

    UpdateHealthInfoEmpcode() {
      let self = this;
      self.errorAlertEdit();
      if (self.edithealth.fileUrl != "" && self.edithealth.putdate != "" && self.edithealth.health != null && self.edithealth.health != "")
      {
        self.loadingdialog = true;
        let tempdept = {
          health_ID: self.edithealth.id,
          health_Condition: self.edithealth.health,
          health_Filename: self.edithealth.filename,
          health_File: self.edithealth.fileUrl,
          // date: self.edithealth.putdate,
          date: self.$moment(self.edithealth.putdate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          employee_ID: localStorage.empcode,
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}HealthInformation/UpdateHealthInformationEmpCode`,tempdept)
        .then(function (response) {
          self.loadingdialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.editdialog = false;
            self.GetHealthWithCode();
            //self.LogTrace(null, "Update Health Information", 18, "Medium");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error,"Update Health Information Fail",18,"Medium");
          alert(error);
          self.loadingdialog = false;
        });
      }
      else
      {
        alert("Please fill in the fields");
        self.loadingdialog = false;
      }
    },

    UpdateHealthInfoEmpsid() {
      let self = this;
      self.errorAlertEdit();
      if (self.edithealth.fileUrl != "" && self.edithealth.putdate != "" && self.edithealth.health != null && self.edithealth.health != "")
      {
        self.loadingdialog = true;
        let tempdept = {
          health_ID: self.edithealth.id,
          health_Condition: self.edithealth.health,
          health_Filename: self.edithealth.filename,
          health_File: self.edithealth.fileUrl,
          // date: self.edithealth.putdate,
          date: self.$moment(self.edithealth.putdate, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          employee_ID: store.state.employeeID,
        };
        axios.post(`${self.url}HealthInformation/UpdateHealthInformation`,tempdept)
        .then(function (response) {
          self.loadingdialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.editdialog = false;
            //self.loadingdialog = false;
            self.GetHealthWithSid();
            //self.LogTrace(null, "Update Health Information", 18, "Medium");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error,"Update  Health Information Fail",18,"Medium");
          alert(error);
          self.loadingdialog = false;
        });
      }
      else
      {
        alert("Please fill in the fields");
        self.loadingdialog = false;
      }
    },

    AddHealthInformation() {
      let self = this;
      self.errorAlert();
      if (self.addhealth.fileUrl != "" && self.addhealth.putdate != "" && self.addhealth.health != null && self.addhealth.health != "" && self.fileUrl != "")
      {
        self.loadingdialog = true;
        let tempdept = {
          health_Condition: self.addhealth.health,
          health_Filename: self.addhealth.filename,
          health_File: self.fileUrl,
          // date: new Date(self.addhealth.putdate),
          date: self.$moment(self.addhealth.putdate,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          employee_ID: parseInt(self.$route.params.id),
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}HealthInformation/AddHealthInformation`, tempdept)
        .then(function (response) {
          self.loadingdialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.fileUrl = "";
            self.addhealth.filename = "";
            self.addhealth.putdate = "";
            self.addhealth.health = null;
            self.Adddialog = false;
            self.GetHealth();
            //self.LogTrace(null, "Add Health Information", 18, "Low");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Add Health Information Fail", 18, "Low");
          alert(error);
          self.loadingdialog = false;
        });
      }
      else
      {
        alert("Please fill in the fields");
        self.loadingdialog = false;
      }
    },

    AddHealthInformationWithCode() {
      let self = this;
      self.errorAlert();
      if (self.addhealth.fileUrl != "" && self.addhealth.putdate != "" && self.addhealth.health != null && self.addhealth.health != "")
      {
        self.loadingdialog = true;
        let tempdept = {
          health_Condition: self.addhealth.health,
          health_Filename: self.addhealth.filename,
          health_File: self.fileUrl,
          // date: new Date(self.addhealth.putdate),
          date: self.$moment(self.addhealth.putdate,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          employee_ID: localStorage.empcode,
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}HealthInformation/AddHealthInformationwithEmpCode`,tempdept)
        .then(function (response) {
          self.loadingdialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.fileUrl = "";
            self.addhealth.filename = "";
            self.addhealth.putdate = "";
            self.addhealth.health = null;
            self.Adddialog = false;
            self.GetHealthWithCode();
            //self.LogTrace(null, "Add Health Information", 18, "Low");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Add Health Information Fail", 18, "Low");
          alert(error);
          self.loadingdialog = false;
        });
      }
      else
      {
        alert("Please fill in the fields");
        self.loadingdialog = false;
      }
    },

    AddHealthInformationWithSid() {
      let self = this;
      self.errorAlert();
      if (self.addhealth.fileUrl != "" && self.addhealth.putdate != "" && self.addhealth.health != null && self.addhealth.health != "" && self.fileUrl != "")
      {
        self.loadingdialog = true;
        let tempdept = {
          health_Condition: self.addhealth.health,
          health_Filename: self.addhealth.filename,
          health_File: self.fileUrl,
          // date: new Date(self.addhealth.putdate),
          date: self.$moment(self.addhealth.putdate,'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          employee_ID: store.state.employeeID,
          company_ID: store.state.companyID,
        };
        axios.post(`${self.url}HealthInformation/AddHealthInformation`, tempdept)
        .then(function (response) {
          self.loadingdialog = false;
          if (response.data.status == 0)
          {
            alert(response.data.message);
            self.fileUrl = "";
            self.addhealth.filename = "";
            self.addhealth.putdate = "";
            self.addhealth.health = null;
            self.Adddialog = false;
            self.GetHealthWithSid();
            //self.LogTrace(null, "Add Health Information", 18, "Low");
          }
          else
          {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          self.LogTrace(error, "Add Health Information Fail", 18, "Low");
          alert(error);
          self.loadingdialog = false;
        });
      }
      else
      {
        alert("Please fill in the fields");
        self.loadingdialog = false;
      }
    },

    GetHealth() {
      let self = this;
      self.loadingdialog = true;
      let tempp = {
        employee_ID: self.$route.params.id,
      };
      axios.post(`${self.url}HealthInformation/GetHealthInformationByEmpID`, tempp)
      .then(function (response) {
        if(response.data.status === 0)
        {
          self.HealthData = response.data.data;
          self.loadingdialog = false;
        }
        //self.LogTrace(null, "Get Health Information", 18, "Critical");
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Health Information Fail", 18, "Critical");
        //alert(error);
        self.loadingdialog = false;
      });
    },

    GetHealthWithSid() {
      let self = this;
      self.loadingdialog = true;
      let tempp = {
        employee_ID: store.state.employeeID.toString(),
      };
      axios.post(`${self.url}HealthInformation/GetHealthInformationByEmpID`, tempp)
      .then(function (response) {
        if(response.data.status === 0)
        {
          self.HealthData = response.data.data;
          self.loadingdialog = false;
        }
        // self.HealthData = response.data.data;
        // self.loadingdialog = false;
        //self.LogTrace(null, "Get Health Information", 18, "Critical");
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Health Information Fail", 18, "Critical");
        //alert(error);
        self.loadingdialog = false;
      });
    },

    GetHealthWithCode() {
      let self = this;
      self.loadingdialog = true;
      let tempp = {
        employee_ID: localStorage.empcode,
      };
      axios.post(`${self.url}HealthInformation/GetHealthInformationByEmpCode`,tempp)
      .then(function (response) {
        if(response.data.status === 0)
        {
          self.HealthData = response.data.data;
          self.loadingdialog = false;
        }
        // self.HealthData = response.data.data;
        // self.loadingdialog = false;
        //self.LogTrace(null, "Get Health Information", 18, "Critical");
      })
      .catch(function (error) {
        self.LogTrace(error, "Get Health Information Fail", 18, "Critical");
        //alert(error);
        self.loadingdialog = false;
      });
    },

    format(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
  },
};
</script>
<style scoped>
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
.typeofleave .v-input__slot {
  min-height: 20px !important;
  width: 10rem !important;
}

>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table tr td {
  height: 70px;
}
>>> .theme--light.v-data-table thead tr th {
  font-size: 16px;
  font-weight: 100;
  color: #f99d20;
}
@media (min-width: 767px) and (max-width: 768px) {
  .pickbtn {
    margin-left: -11rem !important;
  }
}
</style>
