<template>
  <div>
    <div class="pt-7">
      <v-row class="pl-md-0 pt-9" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="12" md="3" lg="3">
          <v-text-field
            :placeholder="$t('Search') + ' with Permission Name'"
            v-model="forsearch"
            color="#F99D20"
            dense
            outlined
            autocomplete="off"
            prepend-inner-icon="mdi-magnify"
            style="font-size:14px; margin-top:-0.7rem; margin-left:1rem;"
            @keyup.enter="Search()"></v-text-field>
        </v-col>
        <!-- <v-col cols="12" xs="12" sm="12" md="3" lg="3">
          <v-text-field
            autocomplete="off"
            color="#F99D20"
            v-model.lazy="forsearch"
            dense
            :placeholder="$t('Search')"
            style="margin-top: -0.3rem; margin-left: 1rem"
          ></v-text-field>
        </v-col>
        <v-btn
          color="#F99D20"
          @click="Search()"
          class="white--text text-capitalize ml-6 mb-2"
          width="120"
          max-height="35"
        >
          <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
        </v-btn> -->
      </v-row>
    </div>
    <v-data-table
      :items="PermissionList"
      style="margin-left: 40px; margin-right: 40px"
      :headers="headers"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      hide-default-header
      @page-count="pageCount = $event"
      :mobile-breakpoint="0"
    >
      <template v-slot:header="{ props: {} }">
        <thead>
          <tr>
            <th>{{ $t("PermissionID") }}</th>
            <th>{{ $t("PermissionName") }}</th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td style="width: 200px; text-align: left">
            {{ item.permission_No }}
          </td>
          <td style="width: 200px; text-align: left">
            {{ item.permission_Name }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="text-right">
      <v-row justify="end" class="ml-5 mt-5 mr-5">
        <v-col cols="12" md="3">
          <v-pagination
            v-model="page"
            :length="pageCount"
            color="#FFCD2C"
            :total-visible="6"
          ></v-pagination>
        </v-col>
        <v-col cols="12" md="2" class="mt-1">
          <v-select
            dense
            style="width: 120px; float: right"
            solo
            label="10/page"
            v-model="itemsPerPage"
            :items="items"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <!-- loadingdialog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, utils],
  data() {
    return {
      loadingdialog: false,
      url: enurl.apiUrl,
      PermissionList: [],
      perlength: 0,
      forsearch: "",
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      headers: [
        {
          text: "Permission ID",
          value: "permission_No",
          align: "left",
          sortable: false,
        },

        {
          text: "Permission Name",
          value: "permission_Name",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    this.GetPermission();
  },
  watch: {
    forsearch: function () {
      this.GetPermission();
    },
  },
  methods: {
    GetIndex(val) {
      let self = this;
      let data = self.PermissionList.indexOf(val);
      data++;
      return data;
    },
    GetPermission() {
      let self = this;
      if (self.perlength == 0 || self.perlength != self.PermissionList.length) {
        self.loadingdialog = true;
        axios
          .get(`${self.url}Permission/GetPermission`)
          .then(function (response) {
            self.PermissionList = response.data.data;
            self.perlength = self.PermissionList.length;
            self.LogTrace(null, "Get Permission", 36, "Critical");
            self.loadingdialog = false;
          })
          .catch(function (error) {
            self.LogTrace(error, "Get Permission Fail", 36, "Critical");
            //alert(error);
            self.loadingdialog = false;
          });
      }
    },
    Search() {
      let self = this;
      if (self.forsearch != "" && self.forsearch != undefined) {
        let List = self.PermissionList;
        self.PermissionList = [];
        let temp = List.filter(
          (v) =>
            v.permission_No
              .toLowerCase()
              .indexOf(self.forsearch.toLowerCase()) > -1 ||
            v.permission_Name
              .toLowerCase()
              .indexOf(self.forsearch.toLowerCase()) > -1
        );
        for (let i = 0; i < temp.length; i++) {
          self.PermissionList.push(temp[i]);
        }
      } else {
        alert("Please enter anything!!");
        self.GetPermission();
      }
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .v-data-table tr td {
  height: 70px;
  text-align: center;
  width: 800px;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>