<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
    <div>
      <p class="txt-header">
        {{ $t("DefaultLanguage") }}
      </p>

      <v-card style="margin-bottom: 5rem" class="mt-6">
        <v-container class="ml-2">
          <v-radio-group
            :disabled="disradio"
            v-model="radioGroup"
            :mandatory="false"
            class="list"
          >
            <v-radio
              v-for="(option, index) in mylanglist"
              :key="index"
              :value="index"
              :label="option.txt"
              color="#707070"
              @click="handleChange(option.val)"
            ></v-radio>
          </v-radio-group>
        </v-container>

        <v-card-actions class="pb-6 mr-5">
          <v-spacer></v-spacer>
          <div v-show="showedit">
            <v-btn
              :disabled="permission.edit"
              class="text-capitalize commonsavebtn"
              @click="editgone"
              >{{ $t("edit") }}</v-btn
            >
          </div>
          <div>
            <v-btn
              v-show="!showedit"
              outlined
              class="mr-5 text-capitalize commoncancelbtn"
              text
              @click="Cancel"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              :disabled="permission.edit"
              v-show="!showedit"
              class="text-capitalize commonsavebtn "
              @click="UpdateDefaultLangugageSetting"
              >{{ $t("save") }}</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </div>
    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import LogTrace from "@/function/Log.js";
import CheckViewEdit from "@/function/RolePermissions.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, CheckViewEdit],
  data() {
    return {
      permission: {
        edit: true,
      },
      LoadingDialog: false,
      url: enurl.apiUrl,
      disradio: true,
      radioGroup: 0,
      showedit: true,
      langList: ["English", "Thai", "Vietnam", "Chinese", "Myanmar"],

      LanguageName: "",
    };
  },
  mounted() {
    this.GetDefaultLanguageSetting();
    this.permission = this.CheckViewEdit(118, 119);
  },
  computed: {
    mylanglist() {
      return [
        { val: "English", txt: this.$t("EnglishLanguage") },
        { val: "Thai", txt: this.$t("Thai") },
        { val: "Myanmar", txt: this.$t("Myanmar") },
        { val: "Vietnam", txt: this.$t("Vietnam") },
        { val: "Chinese", txt: this.$t("ChineseLanguage") },
      ];
    },
  },
  methods: {
    Cancel() {
      this.GetDefaultLanguageSetting();
    },
    editgone() {
      let self = this;
      self.disradio = false;
      self.showedit = false;
    },
    handleChange(e) {
      let self = this;
      self.LanguageName = e;
    },
    GetDefaultLanguageSetting() {
      try {
        let self = this;
        let temp = { company_ID: store.state.companyID };
        self.LoadingDialog = true;
        axios
          .post(
            `${self.url}DefaultLanguageSetting/GetDefaultLanguageSettingByCID`,
            temp
          )
          .then(function (response) {
            self.LogTrace(null, "Get DefaultLanguageSetting", 48, "Critical");
            let temp = response.data.data[0].language_Name;
            let tempdata = self.mylanglist.findIndex(
              (item) => item.val == temp
            );
            self.radioGroup = tempdata;
            self.LoadingDialog = false;
          });
      } catch (error) {
        //alert(error);
        self.LogTrace(error, "Get DefaultLanguageSetting Fail", 48, "Critical");
      }
    },
    UpdateDefaultLangugageSetting() {
      let self = this;
      let temp = {
        language_Name: self.LanguageName,
        company_ID: store.state.companyID,
      };
      axios
        .post(
          `${self.url}DefaultLanguageSetting/UpdateDefaultLanguageSettingByCompany`,
          temp
        )
        .then(function (response) {
          self.LoadingDialog = true;
          if (response.data.status == 0) {
            alert(response.data.message);
            self.LogTrace(null, "Update DefaultLanguageSetting", 48, "Medium");
            self.disradio = true;
            self.showedit = true;
            self.LoadingDialog = false;
            self.GetDefaultLanguageSetting();
          }
        })
        .catch(function (error) {
          self.LogTrace(
            error,
            "Update DefaultLanguageSetting Fail",
            48,
            "Medium"
          );
          alert(error);
          self.LoadingDialog = false;
        });
    },
  },
};
</script>

<style scoped>
@media (max-width: 414px) {
  .fix-btn {
    width: 100px !important;
  }
}
</style>
<style>
.list .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  height: 3rem !important;
}
</style>