<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <p style="font-size: 25px; color: #444444; margin-top: 15px">
        {{ $t("NewGroup") }}
      </p>
    </div>
    <v-card class="mt-6">
      <v-row class="pl-md-0 mt-2" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            autocomplete="off"
            v-model="adduergroup.gpname"
            v-on:keyup="keymonitor"
            color="#8A8C8E"
            :error-messages="error.gpname"
          >
            <template v-slot:label>
              <span>
                {{ $t("GroupName") }}<span class="red--text"> *</span></span
              >
            </template></v-text-field
          >
        </v-col>
      </v-row>
      <v-row
        class="pl-md-0"
        style="margin-left: 15px; margin-right: 10px; margin-top: -15px"
      >
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            autocomplete="off"
            v-model="adduergroup.gpmember"
            color="#8A8C8E"
            :error-messages="error.gpmember"
            readonly
          >
            <template v-slot:label>
              <span>
                {{ $t("GroupMember") }}<span class="red--text"> *</span></span
              >
            </template></v-text-field
          >
        </v-col>
        <v-col>
          <v-btn
            rounded
            width="100"
            class="text-capitalize mt-3"
            color="#C6E8CB"
            style="color: #22a534; font-weight: normal"
            router
            :to="{
              name: 'usergroupmember',
              params: { ugmem: 'usergroupmember' },
            }"
            >{{ $t("Add") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row
        class="pl-md-0"
        style="margin-left: 15px; margin-right: 10px; margin-top: -15px"
      >
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            autocomplete="off"
            v-model="adduergroup.gprole"
            color="#8A8C8E"
            :error-messages="error.gprole"
            readonly
          >
            <template v-slot:label>
              <span> {{ $t("Role") }}<span class="red--text"> *</span></span>
            </template></v-text-field
          >
        </v-col>
        <v-col>
          <v-btn
            rounded
            width="100"
            class="text-capitalize mt-3"
            color="#C6E8CB"
            style="color: #22a534; font-weight: normal"
            router
            :to="{ name: 'usergrouprole', params: { ugrole: 'usergrouprole' } }"
            >{{ $t("Assign") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row
        class="pl-md-0"
        style="margin-left: 15px; margin-right: 10px; margin-top: 0px"
      >
        <v-col cols="12" sm="6" md="4" align="right">
          <v-btn
            color="#444444"
            class="text-capitalize"
            style="font-weight: normal"
            text
          >
            {{ $t("Advance") }}&nbsp;
            <img width="30" height="30" :src="Advanceimage.up" alt />
          </v-btn>
        </v-col>
      </v-row>
      <div v-show="!showper">
        <v-row
          class="pl-md-0"
          style="margin-left: 15px; margin-right: 10px; margin-top: 0px"
        >
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              autocomplete="off"
              :label="$t('Permission')"
              v-model="adduergroup.gppermit"
              color="#8A8C8E"
              readonly
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              rounded
              width="100"
              color="#C6E8CB"
              style="color: #22a534; font-weight: normal"
              class="text-capitalize mt-3"
              router
              :to="{
                name: 'usergrouppermission',
                params: { ugper: 'usergrouppermission' },
              }"
              >{{ $t("Assign") }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <div style="height: 30vh"></div>
      <v-card-actions class="pb-6 mr-5">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2 text-capitalize commoncancelbtn"
          outlined
          text
          @click="goback"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          class="mr-4 text-capitalize commonsavebtn"
          @click="AddUserGroup"
          >{{ $t("save") }}</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- Loadingdialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>
<script>
import enurl from "@/api/environment";
import axios from "axios";
import store from "@/store";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, utils],
  data() {
    return {
      url: enurl.apiUrl,
      showper: false,
      routeback: "/usermanagement/usergroup",
      LoadingDialog: false,
      grpname:null,
      adduergroup: {
        gpname: null,
        gpmember: null,
        gprole: null,
        gppermit: null,
      },
      error: {
        gpname: null,
        gpmember: null,
        gprole: null,
      },
      tpl: [],
      trl: [],
      tml: [],
      localmem: [],
      localrole: [],
      localpermit: [],
      localmemrole: [],
      TempEmpList: [],
      TempRoleList: [],
      TempPermitList: [],
      Advanceimage: {
        up: require("@/assets/images/up1@2x.png"),
        down: require("@/assets/images/down1@2x.png"),
      },
    };
  },
  mounted() {
    this.GetUserGroup();
  },
  created() {
    let taskSt = JSON.parse(localStorage.getItem("localGPName"));
    this.adduergroup.gpname != null ? this.adduergroup.gpname = taskSt.grpname : this.adduergroup.gpname = '' ;
  },
  methods: {
    goback() {
      localStorage.setItem("localGPName", JSON.stringify(null));
      localStorage.setItem("localmember", JSON.stringify(null));
      localStorage.setItem("localgrouprole", JSON.stringify(null));
      localStorage.setItem("localgrouppermit", JSON.stringify(null));
      this.$router.push("/usermanagement/usergroup").catch(()=>{});
    },
    keymonitor: function () {
      let tem = {
        grpname: this.adduergroup.gpname,
      };
      localStorage.setItem("localGPName", JSON.stringify(tem));
    },
    GetUserGroup() {
      let self = this;
      // self.LoadingDialog = true;

      //EmpName
      self.localmem = JSON.parse(localStorage.getItem("localmember"));
      if (self.localmem != null) {
        self.adduergroup.gpmember = [...self.localmem.map((x) => x.empname)];

        self.TempEmpList = [...self.localmem.map((x) => x.empid)];

        self.adduergroup.gpmember = [...new Set(self.adduergroup.gpmember)];

        if (self.TempEmpList != null) {
          for (let i = 0; i < self.TempEmpList.length; i++) {
            self.tml.push({
              employee_ID: self.TempEmpList[i],
            });
          }
        }
      } else {
        self.adduergroup.gpmember = null;
      }

      //RoleName
      self.localmemrole = parseInt(localStorage.getItem("check_role_mem"));
      self.localrole = JSON.parse(localStorage.getItem("localgrouprole"));
      if (self.localrole != null && self.localrole.length != 0) {
        self.adduergroup.gprole = [...self.localrole.map((x) => x.rolename)];

        self.TempRoleList = [...self.localrole.map((x) => x.roleid)];

        self.adduergroup.gprole = [...new Set(self.adduergroup.gprole)];

        if (self.TempRoleList != null) {
          for (let j = 0; j < self.TempRoleList.length; j++) {
            self.trl.push({
              role_ID: self.TempRoleList[j],
            });
          }
        }
      } else {
        self.adduergroup.gprole = null;
      }

      //PermissionName
      self.localpermit = JSON.parse(localStorage.getItem("localgrouppermit"));
      if (self.localpermit != null) {
        self.adduergroup.gppermit = [...self.localpermit.map((x) => x.pername)];
        self.adduergroup.gppermit = [...new Set(self.adduergroup.gppermit)];
        self.TempPermitList = [...self.localpermit.map((x) => x.perid)];

        if (self.TempPermitList != null) {
          for (let j = 0; j < self.TempPermitList.length; j++) {
            self.tpl.push({
              permission_ID: self.TempPermitList[j],
            });
          }
        }
      } else {
        self.adduergroup.gppermit = null;
      }

      // self.LoadingDialog = false;
    },
    AddUserGroup() {
      let self = this;
      self.LoadingDialog = true;
      self.error.gpname = !self.adduergroup.gpname
        ? "Please fill in the required fields"
        : "";
      self.error.gpmember = !self.adduergroup.gpmember
        ? "Please fill in the required fields"
        : "";
      self.error.gprole = !self.adduergroup.gprole
        ? "Please fill in the required fields"
        : "";
      if (
        self.adduergroup.gpname != null &&
        self.adduergroup.gpname != "" &&
        self.adduergroup.gpmember != null &&
        self.adduergroup.gpmember != "" &&
        self.adduergroup.gprole != null &&
        self.adduergroup.gprole != ""
      ) {
        if (self.localmemrole != 0) {
          if (self.trl[0].role_ID == self.localmemrole) {
            let temp = {
              user_Group_Name: self.adduergroup.gpname,
              company_ID: store.state.companyID,
              userGroupMemberData: self.tml,
              userGroupRoleData: self.trl,
              userGroupPermitData: self.tpl,
            };
            axios
              .post(`${self.url}UserGroup/AddUserGroup`, temp)
              .then(function (response) {
                if (response.data.status == 0) {
                  alert(response.data.message);
                  self.LoadingDialog = false;
                  localStorage.setItem("localGPName", JSON.stringify(null));
                  localStorage.setItem("localmember", JSON.stringify(null));
                  localStorage.setItem("localgrouprole", JSON.stringify(null));
                  localStorage.setItem(
                    "localgrouppermit",
                    JSON.stringify(null)
                  );
                  localStorage.clear();
                  self.LogTrace(null, "Add User Group", 35, "Low");
                  self.$router.push("/usermanagement/usergroup").catch(()=>{});
                }
              })
              .catch(function (error) {
                //alert(error);
                self.LogTrace(error, "Add User Group Fail", 35, "Low");
                self.LoadingDialog = false;
              });
          } else {
            alert("Role can't be different!");
            self.LoadingDialog = false;
          }
        } else {
          let temp = {
            user_Group_Name: self.adduergroup.gpname,
            company_ID: store.state.companyID,
            userGroupMemberData: self.tml,
            userGroupRoleData: self.trl,
            userGroupPermitData: self.tpl,
          };
          axios
            .post(`${self.url}UserGroup/AddUserGroup`, temp)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.LoadingDialog = false;
                localStorage.setItem("localGPName", JSON.stringify(null));
                localStorage.setItem("localmember", JSON.stringify(null));
                localStorage.setItem("localgrouprole", JSON.stringify(null));
                localStorage.setItem("localgrouppermit", JSON.stringify(null));
                localStorage.clear();
                self.LogTrace(null, "Add User Group", 35, "Low");
                self.$router.push("/usermanagement/usergroup").catch(()=>{});
              }
            })
            .catch(function (error) {
              alert(error);
              self.LogTrace(error, "Add User Group Fail", 35, "Low");
              self.LoadingDialog = false;
            });
        }

        // let tempmember = self.addtempmember();
        // let temprole = self.addtemprole();
        // let temppermit = self.addtemppermit();
      } else {
        alert("Fill in the required field.");
        self.LoadingDialog = false;
      }
    },
    addtempmember() {
      let self = this;
      let temparr = [
        {
          employee_ID: null,
        },
      ];
      for (let i = 0; i < self.usergroupmember.length; i++) {
        if (i == 0) {
          temparr[i].employee_ID = self.usergroupmember[i].employee_ID;
        } else {
          temparr.push({ employee_ID: self.usergroupmember[i].employee_ID });
        }
      }
      return temparr;
    },
    addtemprole() {
      let self = this;
      let temparr = [
        {
          role_ID: null,
        },
      ];
      for (let i = 0; i < self.usergrouprole.length; i++) {
        if (i == 0) {
          temparr[i].role_ID = self.usergrouprole[i].role_ID;
        } else {
          temparr.push({ role_ID: self.usergrouprole[i].role_ID });
        }
      }
      return temparr;
    },
    addtemppermit() {
      let self = this;
      let temparr = [
        {
          permission_ID: null,
        },
      ];
      for (let i = 0; i < self.usergrouppermit.length; i++) {
        if (i == 0) {
          temparr[i].permission_ID = self.usergrouppermit[i].permission_ID;
        } else {
          temparr.push({
            permission_ID: self.usergrouppermit[i].permission_ID,
          });
        }
      }
      return temparr;
    },
  },
};
</script>
