<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem">
      <p class="txt-header">
        {{ $t("salarydocument") }}
      </p>
    <div style="height: auto" class="mt-6">
      <v-card class="d-flex flex-wrap flex-column pa-3">
        <v-col class="pb-0" cols="12" xs="12" sm="4" md="3" lg="2" xl="2">
          <div class="ml-2 mb-2">
            {{ $t("Year") }}<span class="red--text">*</span>
          </div>
          <div class="d-flex">
            <div>
              <v-autocomplete class="ml-2" v-model="filteryear" :items="yearitems" outlined dense color="#F99D20"
                @change="YearChange(filteryear)"></v-autocomplete>
            </div>
          </div>
        </v-col>
        <p v-if="defaultpasscode == 1" class="ml-5" style="color: #686469">{{ $t("theSetPwdTxt") }}</p>
        <p v-if="defaultpasscode == 2" class="ml-5" style="color: #686469">{{ $t("theSetPwdTxt13digit") }}</p>
        <p v-if="defaultpasscode == 3" class="ml-5" style="color: #686469">{{ $t("theSetPwdTxt6digit") }}</p>
        <span v-if="validID == false" class="ml-5" style="color: #FF2727">{{ $t("changepw") }}</span>

        <v-data-table :headers="headers" :items="filteredPayslip" :page.sync="page" :items-per-page="itemsPerPage"
          hide-default-footer hide-default-header @page-count="pageCount = $event" class="ml-8 mr-8 mt-4"
          :mobile-breakpoint="0">
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th class="b-text" style="cursor: pointer">
                  {{ $t("payslip") }}
                </th>
                <th class="text-center">{{ $t("Actions") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td style="border-bottom: 1px solid #fff">
                {{ GetMonthName(item.period_Start_Date) }} (
                {{ formatDate(item.period_Start_Date) }} -
                {{ formatDate(item.period_End_Date) }})
              </td>
              <td style="border-bottom: 1px solid #fff" class="text-center">
                <v-btn icon @click="GetPDF(item)">
                  <img :src="images.pdf" width="24px" height="24px" />
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-right" v-if="Payslip.length">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination v-model="page" :length="pageCount" color="#FFCD2C" :total-visible="6"></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select dense style="width: 120px; float: right" solo label="10/page" v-model="itemsPerPage"
                :items="items" @input="itemsPerPage = parseInt($event, 10)"></v-select>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <!-- Loading Dialog -->
      <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

      <!-- alert dialog -->
      <!-- <v-dialog>
        <v-card-actions style="display:flex;">
            <v-spacer></v-spacer>
            <v-btn class="mr-3 text-capitalize commoncancelbtn" outlined text @click="Cancel">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn color="#F99D20" class="mr-8 text-capitalize commonsavebtn fix-btn"
              @click="downloadPDF">
              {{ $t("Print") }}
            </v-btn>
          </v-card-actions>
      </v-dialog> -->
      <!-- print dialog -->
      <!-- <v-dialog v-model="printDialog" max-width="78%" style="overflow-x: hidden;">
        <v-card style="background-color: white;overflow-x: hidden;">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <iframe id="pdf_frame" :src="pdfString" ref="content" frameborder="0" seamless
                style="width: 100%;height: 600px;border: none"></iframe>
            </v-col>
          </v-row>
          <v-card-actions style="display:flex;">
            <v-spacer></v-spacer>
            <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="120" max-height="35" text @click="Cancel">
              {{ $t("Cancel") }}
            </v-btn>
            <v-btn color="#F99D20" class="mr-8 text-capitalize white--text fix-btn" width="150" max-height="35"
              @click="downloadPDF">
              {{ $t("Print") }}
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog> -->
      <!-- <div style="visibility: hidden">
        <iframe id="i_frame" v-if="iframeShow" width="100%" height="100%" :src="pdfString" frameborder="0" scrolling="no"
          name="frame1"></iframe>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import jsPDF from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ThaiFontBoldBase64 from "@/function/global.js";
import ThaiFontBase64 from "@/function/global.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";


export default {
  components:{
    LoadingDialog,
  },
  name: "SalaryDocument",
  mixins: [utils, ThaiFontBoldBase64,
    ThaiFontBase64],
  data() {
    return {
      thelang: '',
      iframeShow: false,
      pdfString: "",
      ytd_income: null,
      ytd_tax: null,
      ytd_ssf: null,
      filteryear: new Date().getFullYear(),
      filteredPayslip: [],
      yearitems: [],
      url: enurl.apiUrl,
      Payslip: [],
      PDFData: [],
      total_Income: null,
      total_Deduction: null,
      personalID_Number: null,
      validID: true,
      net_Income: null,
      LoadingDialog: false,
      printDialog: false,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      images: {
        pdf: require("@/assets/images/pdf.jpg"),
      },
      defaultpasscode: null,
      headers: [
        {
          text: "Pay Slip",
          align: "left",
          sortable: false,
        },

        {
          text: "Actions",
          align: "right",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    thelang() {
      this.whatthehead()
    }
  },
  computed: {
    thethaihead() {
      return this.whatthehead();
    }
  },
  mounted() {
    let self = this;
    self.GetPaidData();
    self.getOtherSetting();
    self.timerInterval = setInterval(() => {
      self.thelang = localStorage.getItem('Templang')
    }, 100);
    //self.GetYTD();
    self.YearChange(self.filteryear);
  },
  methods: {
    whatthehead() {
      if (this.thelang == "ประเทศไทย") {
        return true;
      } else {
        return false;
      }
    },
    Cancel() {
      this.printDialog = false;
      this.iframeShow = false;
    },
    formatedDate(date) {
    let d = new Date(date);
    let day = ('0' + d.getDate()).slice(-2);
    let month = ('0' + (d.getMonth() + 1)).slice(-2);
    let year = d.getFullYear();

    return `${day}${month}${year}`; 
    },
    async getOtherSetting() {
      try {
        let self = this;
        self.LoadingDialog = true;
        let temp = {
          user_ID: parseInt(self.$store.state.userid),
          company_ID: parseInt(self.$store.state.companyID),
        };
        await axios
          .post(`${self.url}Other_Setting/GetOtherSetting`, temp)
          .then(function (response) {
            let res = response.data.data;
            self.defaultpasscode = res.defaultpasscode
          });
      } catch (ex) {
        throw ex;
      }
    },
    isPersonalIDValid(id) {
      return id && id !== "N/A" && id.length === 13;
    },
    Getpdfobj() {
      let self = this;
      const defaultpasscode = self.defaultpasscode;
      let password = '';
      const personalID = self.personalID_Number;

      if (defaultpasscode === 1) {
        password = self.formatedDate(self.PDFData.emp_BD); 
      } else if (defaultpasscode === 2) {
        this.validID = this.isPersonalIDValid(personalID);
        if (this.validID) {
          password = personalID;
        } else {
          password = self.formatedDate(self.PDFData.emp_BD);
        }
      } else {
        this.validID = this.isPersonalIDValid(personalID);

        if (this.validID) {
          password = personalID.slice(-6);
        } else {
          password = self.formatedDate(self.PDFData.emp_BD);
        }
      }
      const doc = new jsPDF({
        orientation: 'l',
        unit: 'mm',
        format: [210, 139.7],// Width (210mm for A4) and custom height.
        encryption: {
          userPassword: password,   // Set your desired user password
          ownerPassword: password, // Set your desired owner password
          userPermissions: ["print", "copy"]    // Optional: Define user permissions
        }
      });
      doc.addFileToVFS("THSarabunPSK.ttf", self.ThaiFontBase64());
      doc.addFileToVFS("THSarabunPSKBold.ttf", self.ThaiFontBoldBase64());
      doc.addFont("THSarabunPSKBold.ttf", "THSarabunPSKBold", "normal");
      doc.addFont("THSarabunPSK.ttf", "THSarabunPSK", "normal");
      let boldfont = "THSarabunPSKBold";
      let normalfont = "THSarabunPSK";
      doc.setFont(boldfont);
      doc.setFontSize(14);
      doc.text(13, 15, self.PDFData.company_Name);
      doc.text(180, 15, self.$t("Payslip"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(13, 23, self.$t("employeeid") + ": " + store.state.employeeCode);

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(75, 23, self.$t("EmployeeName") + ": " + store.state.firstName + " " + store.state.lastName);

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(
        155,
        23,
        self.$t("PaymentDate") + ": " + self.formatDate(self.PDFData.payment_Date)
      );

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(
        13,
        30,
        self.$t("position") + ": " + store.state.position_Name
      );

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(
        75,
        30,
        self.$t("department") + ": " + self.PDFData.department_Name
      );

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(
        155,
        30,
        self.$t("BankAccount") +
        ": " +
        self.PDFData.bank_acc, {
        maxWidth: 50,
      }
      );

      doc.setFont(boldfont);
      doc.setFontSize(13);
      doc.text(33, 45, self.$t("Income"));

      doc.setFont(boldfont);
      doc.setFontSize(13);
      doc.text(95, 45, self.$t("Deductions"));

      doc.setFont(boldfont);
      doc.setFontSize(13);
      doc.text(165, 45, self.$t("ytd"));

      doc.line(10, 47, 200, 47); //xline2

      // Income
      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(14, 53, self.$t("Salary"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(65, 53, self.numberWithCommas(self.PDFData.salary), { align: "right" });

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(14, 59, self.$t("OT"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(65, 59, self.numberWithCommas(self.PDFData.ot), { align: "right" });

      // Deduction
      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(75, 53, self.$t("social_security"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(135, 53, self.numberWithCommas(self.PDFData.social_Security_Fund), { align: "right" });

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(75, 59, self.$t("witholding"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(135, 59, self.numberWithCommas(self.PDFData.personal_Tax), { align: "right" });

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(75, 65, self.$t("provident_fund"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(135, 65, self.numberWithCommas(self.PDFData.provident_Fund_Amount), { align: "right" });

      // doc.setFont(normalfont);
      // doc.setFontSize(14);
      // doc.text(75, 71, self.$t("stamp"));

      // doc.setFont(normalfont);
      // doc.setFontSize(14);
      // doc.text(113, 71, self.numberWithCommas(self.PDFData.stamp));

      // doc.setFont(normalfont);
      // doc.setFontSize(14);
      // doc.text(75, 77, self.$t("late"));

      // doc.setFont(normalfont);
      // doc.setFontSize(14);
      // doc.text(113, 77, self.numberWithCommas(self.PDFData.late));

      // YTD
      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(144, 53, self.$t("ytdincome"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(195, 53, self.ytd_income, { align: "right" });

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(144, 59, self.$t("ytdwitholding"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(195, 59, self.ytd_tax, { align: "right" });

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(144, 65, self.$t("accmulatedssf"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(195, 65, self.ytd_ssf, { align: "right" });

      let incomeheight = 65;
      self.PDFData.revenue.map((data) => {
        doc.setFont(normalfont);
        doc.setFontSize(14);
        doc.text(14, incomeheight, self.thethaihead == true ? data.income_Type_Name_TH : data.income_Type_Name_EN ? data.income_Type_Name_EN : "");

        doc.setFont(normalfont);
        doc.setFontSize(14);
        doc.text(65, incomeheight, self.numberWithCommas(data.amount), { align: "right" });
        incomeheight = incomeheight + 7;
      });
      let deductionheight = 71;
      self.PDFData.deduction.map((data) => {
        let name = data.deduction;

        doc.setFont(normalfont);
        doc.setFontSize(14);

        doc.text(75, deductionheight, name);

        doc.setFont(normalfont);
        doc.setFontSize(14);
        doc.text(135, deductionheight, self.numberWithCommas(data.amount), { align: "right" });
        deductionheight = deductionheight + 6;
      });
      let mylineheight = 0;
      if (incomeheight > deductionheight) {
        mylineheight = incomeheight;
      } else if (deductionheight > incomeheight) {
        mylineheight = deductionheight;
      } else {
        mylineheight = incomeheight;
      }
      mylineheight = mylineheight + 4;

      doc.line(10, mylineheight, 140, mylineheight);
      //88
      doc.setFillColor("#D9D9D9");
      // doc.setLineWidth(0.6);
      doc.setLineWidth(0.2); // Set the line width to 2 (or any desired value)
      doc.rect(10, mylineheight, 34, 11, "FD"); // Draw the rectangle with the "S" parameter to indicate drawing only the border
      doc.fillStroke();

      doc.setFillColor("#D9D9D9");
      // doc.setLineWidth(0.6);
      doc.setLineWidth(0.2); // Set the line width to 2 (or any desired value)
      doc.rect(70, mylineheight, 34, 11, "FD"); // Draw the rectangle with the "S" parameter to indicate drawing only the border
      doc.fillStroke();

      mylineheight = mylineheight + 4;

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(14, mylineheight, self.$t("TotalIncome"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(65, mylineheight, self.total_Income, { align: "right" });

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(75, mylineheight, self.$t("TotalDeductions"));

      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(135, mylineheight, self.total_Deduction, { align: "right" });
      doc.setLineWidth(0.2);
      let totalhorizontalline = mylineheight + 2;
      doc.line(10, totalhorizontalline, 140, totalhorizontalline);

      let firstverticalline = mylineheight + 5;
      doc.line(70, 40, 70, firstverticalline);
      let secondverticalline = mylineheight + 8;
      doc.line(140, 40, 140, secondverticalline);

      doc.setFillColor("#D9D9D9");
      // doc.setLineWidth(0.6);
      doc.setLineWidth(0.2); // Set the line width to 2 (or any desired value)
      doc.rect(10, totalhorizontalline, 100, 6, "FD"); // Draw the rectangle with the "S" parameter to indicate drawing only the border
      doc.fillStroke();
      mylineheight = mylineheight + 6;
      // alert(mylineheight);
      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(95, mylineheight, self.$t("netpay"));

      doc.setLineWidth(0.2);
      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(
        135,
        mylineheight,
        self.net_Income, { align: "right" }
      );

      doc.rect(10, 40, 190, mylineheight - 38);
      doc.setFont(normalfont);
      doc.setFontSize(14);
      doc.text(12, mylineheight + 8, self.$t("text"), { maxWidth: 190 });
      const paymentdate = self.formatDatePDFName(self.PDFData.payment_Date);
      doc.save(`Payslip-${self.PDFData.company_Name}-${paymentdate}`)
    },
    numberWithCommas(x) {
      if (x != null) {
        const value = parseFloat(x).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return value;
      }
    },
    YearChange(y) {
      //Filter Payslip array based on a specific year
      let self = this;
      self.filteredPayslip = self.Payslip.filter((item) => {
        const startYear = new Date(item.period_Start_Date).getFullYear();
        return startYear === y;
      });
      //self.GetYTD()
    },
    GetMonthName(d) {
      const date = new Date(d); // Current date
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthIndex = date.getMonth();
      const monthName = monthNames[monthIndex];
      return monthName;
    },
    formatDatePDFName(date) {
      if (date) {
        let spliteDateAndTime = date.split("T");
        let Date = spliteDateAndTime[0].split("-");
        return Date[0] + Date[1] + Date[2];
      }
    },
    formatDate(date) {
      if (date) {
        let spliteDateAndTime = date.split("T");
        let Date = spliteDateAndTime[0].split("-");
        return Date[2] + "/" + Date[1] + "/" + Date[0];
      }
    },
    GetPaidData() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
        employee_ID: store.state.employeeID,
      };
      axios
        .post(`${self.url}SalaryDocument/GetPaidSalaryDocuments`, temp)
        .then(function (response) {
          self.Payslip = response.data.data;
          self.yearitems = [
            ...new Set(
              self.Payslip.flatMap((item) => [
                new Date(item.period_Start_Date).getFullYear(),
                new Date(item.period_End_Date).getFullYear(),
              ])
            ),
          ];
          self.YearChange(self.filteryear);
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    GetPDF(item) {
      let self = this;
      self.printDialog = true;
      self.LoadingDialog = true;
      let temp = {
        employee_ID: store.state.employeeID,
        paymentDue_ID: item.payDue_ID,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}SalaryDocument/GetSalaryDocumentByPayDueID`, temp)
        .then(function (response) {
          self.PDFData = response.data.data[0];
          self.GetYTD(self.PDFData.payment_Date);
          const firstItem = response.data.data[0];
          self.personalID_Number = firstItem.personalID_Number;
          // Count Total Income salary + ot + revenue amounts
          const revenueTotal = firstItem.revenue
            ? firstItem.revenue.reduce((acc, curr) => acc + curr.amount, 0)
            : 0;

          const totalIncome =
            (firstItem.salary || 0) + (firstItem.ot || 0) + revenueTotal;
          const formattedTotalIncome = totalIncome.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          // Count Total Deduction ssf + tax + stamp + late + pvd + deduction amounts
          const deductionTotal = firstItem.deduction
            ? firstItem.deduction.reduce((acc, curr) => acc + curr.amount, 0)
            : 0;

          const totaldeduct =
            (firstItem.social_Security_Fund || 0) +
            (firstItem.personal_Taxs || 0) +
            (firstItem.provident_Fund_Amount || 0) +
            (firstItem.stamp || 0) +
            (firstItem.late || 0) +
            deductionTotal;
          const formattedTotalDeduct = totaldeduct.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          //Net Income
          const netcome = totalIncome - totaldeduct;
          const formattedNetIncome = netcome.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          self.total_Income = formattedTotalIncome;
          self.total_Deduction = formattedTotalDeduct;
          self.net_Income = formattedNetIncome;
          self.LoadingDialog = false;
          // self.downloadPDF();
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    GetYTD(payment_date) {
      let self = this;
      self.LoadingDialog = true;
      self.printDialog = true;
      let temp = {
        employee_ID: store.state.employeeID,
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}SalaryDocument/GetYTDByEmpID`, temp)
        .then(function (response) {
          let res = response.data.data;
          let year = res.filter((item) => {
            const temp = new Date(item.payment_Date).getFullYear();
            return temp === self.filteryear;
          });
          if (year.length != 0) {
            let ytd = year.filter((item) => {
              const temp = new Date(item.payment_Date).getMonth();
              const p_date = new Date(payment_date).getMonth();
              return temp <= p_date
            });



            // Count Total Income salary + ot + revenue amounts
            const revenueTotal = ytd.reduce((sum, item) => sum + item.revenue.reduce((sum, item) => sum + item.amount, 0), 0)

            const totalIncome =
              ytd.reduce((sum, item) => sum + item.salary, 0) + ytd.reduce((sum, item) => sum + item.ot, 0) + revenueTotal;

            const formattedNetIncome = totalIncome.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            //SSF
            const ssf = ytd.reduce((acc, curr) => acc + curr.social_Security_Fund, 0);
            const formattedSSF = ssf.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            //TAx
            const tax = ytd.reduce((acc, curr) => acc + curr.personal_Taxs, 0);
            const formattedTax = tax.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
            self.ytd_income = formattedNetIncome;
            self.ytd_tax = formattedTax;
            self.ytd_ssf = formattedSSF;
            self.Getpdfobj();
            self.LoadingDialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
  },
};
</script>

<style scoped>
.pdf-container {
  background-color: #aaa9ae;
  margin-left: 3rem;
  margin-right: 3rem;
}

#middlecol {
  width: 100%;
}

#middlecol table {
  max-width: 2000px;
  width: 100% !important;
  table-layout: fixed;
}

::v-deep .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}

::v-deep .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}

::v-deep .theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row),
.theme--light.v-data-table tbody tr:not(:last-child) th:not(.v-data-table__mobile-row) {
  border: unset;
}

::v-deep .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}

::v-deep .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}

::v-deep .v-data-table tr td {
  height: 70px;
}

::v-deep .theme--light.v-data-table thead tr th {
  font-size: 16px;
  font-weight: normal;
  color: #f99d20;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

tbody tr:hover {
  background-color: #fff9f0 !important;
}
</style>