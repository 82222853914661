<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <p style="font-size: 25px; color: #43425d; margin-top: 15px">
        {{ $t("UserGroup") }}
      </p>
    </div>
    <v-card class="mt-6">
      <div>
        <v-row
          class="pl-md-0 pt-9"
          style="margin-left: 15px; margin-right: 10px"
        >
          <v-col cols="12" xs="12" sm="4" md="4" lg="4">
            <v-text-field
              autocomplete="off"
              v-model="searchtxt"
              :placeholder="$t('Search')"
              color="#F99D20"
              dense
              style="margin-top: -0.3rem; margin-left: 1rem"
            ></v-text-field>
          </v-col>
          <v-btn
            color="#F99D20"
            @click="Search"
            class="white--text text-capitalize ml-5"
            width="120"
            max-height="35"
          >
            <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
          </v-btn>
        </v-row>
        <v-data-table
          style="margin-left: 40px; margin-right: 40px"
          :items="GroupList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :headers="headers"
          hide-default-footer
          @page-count="pageCount = $event"
          hide-default-header
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("GroupID") }}</th>
                <th>{{ $t("GroupName") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <label class="form-checkbox">
                  <input
                    type="checkbox"
                    :value="
                      item.user_Group_ID != null
                        ? {
                            user_Group_ID: item.user_Group_ID,
                            user_Group_Name: item.user_Group_Name,
                          }
                        : { user_Group_ID: emmpid, user_Group_Name: emmpname }
                    "
                    v-model="selected"
                    v-on:click="checkonce()"
                  />
                  <i class="form-icon"></i>
                </label>
              </td>
              <td>{{ GetIndex(item) }}</td>
              <td>{{ item.user_Group_Name }}</td>
            </tr>
          </template>
        </v-data-table>
        <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#FFCD2C"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-card-actions class="pb-6 mr-5">
        <v-spacer></v-spacer>
        <v-btn
          style="border-radius: 18px"
          class="mr-2 text-capitalize"
          width="120"
          height="40"
          text
          @click="Cancel"
          >{{ $t("Cancel") }}</v-btn
        >
        <v-btn
          color="#F99D20"
          class="mr-4 text-capitalize"
          style="border-radius: 18px"
          width="120"
          height="40"
          @click="save"
          >{{ $t("Save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- loadingdialog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import LoadingDialog from "@/components/Loading.vue";
import { utils } from "@/function/AuthenticatedRedirect.js";
export default {
  components:{
    LoadingDialog,
  },
  mixins: [utils],
  data() {
    return {
      GroupList: [],
      loadingdialog: false,
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      searchtxt: "",
      selectAll: false,
      selected: [],
      tempuser: [],
      headers: [
        {
          text: "Group ID",
          value: "user_Group_ID",
          align: "left",
          sortable: true,
        },
        {
          text: "Group Name",
          value: "user_Group_Name",
          align: "left",
          sortable: true,
        },
      ],
    };
  },
  watch: {
    searchtxt: function () {
      this.GetGroup();
    },
  },
  mounted() {
    this.GetGroup();
  },
  methods: {
    GetIndex(val) {
      let self = this;
      let data = self.GroupList.indexOf(val);
      data++;
      return data;
    },
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        let List = self.GroupList;
        self.GroupList = [];
        let temp = List.filter(
          (v) =>
            v.user_Group_Name
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1
        );

        for (let i = 0; i < temp.length; i++) {
          self.GroupList.push(temp[i]);
        }
      } else {
        alert("Please enter text");
        self.GetGroup();
      }
    },
    checkonce() {
      this.selected = [];
    },
    Cancel() {
      let self = this;
      localStorage.setItem("NewUserEditUserGp", JSON.stringify(null));
      self.$router.push({ name: "newuserassign" }).catch(()=>{});
    },
    save() {
      let self = this;
      localStorage.setItem("NewUserEditUserGp", JSON.stringify(self.selected));
      self.GetRoleByUserGroup();
    },
    GetRoleByUserGroup() {
      let self = this;
      if (self.selected.length != 0) {
        let ugpId = self.selected[0].user_Group_ID;
        let tempp = {
          company_ID: store.state.companyID,
          user_Group_ID: ugpId,
        };
        axios
          .post(`${self.url}UserGroupRole/GetUserGroupRole`, tempp)
          .then(function (response) {
            let temprole = response.data.data;
            localStorage.setItem("NewUserEditRole", JSON.stringify(temprole));
            self.$router.push({ name: "newuserassign" }).catch(()=>{});
          });
      } else {
        self.$router.push({ name: "newuserassign" }).catch(()=>{});
      }
    },
    GetGroup() {
      let self = this;
      //self.loadingdialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}UserGroup/GetUserGroup`, tempp)
        .then(function (response) {
          self.GroupList = response.data.data;
          //self.loadingdialog = false;
          self.tempuser = JSON.parse(localStorage.getItem("NewUserEditUserGp"));
          if (self.tempuser != null) {
            self.selected.push({
              user_Group_ID: self.tempuser[0].user_Group_ID,
              user_Group_Name: self.tempuser[0].user_Group_Name,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>