<template>
  <div>
    <!-- <div style="padding-left: 15px; padding-right: 1%; margin-bottom: 1rem">
      <v-row class="mr-0" @click="back" style="cursor: pointer">
        <v-btn icon disabled color="white" class="mt-6 ml-1">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p
          class="headtext"
          style="font-size: 25px; margin-left: 4px;  margin-top: 20px;"
        >
          {{ $t("EmployementLayOffReport") }}
        </p>
      </v-row>
    </div> -->
    <div style="margin-left: 1%;display:flex;">
      <v-btn icon color="white" style="cursor: pointer" class="mt-6 ml-1" @click="back()">
          <img src="@/assets/images/back button.png" class="ml-2 mb-1" />
        </v-btn>
        <p class="ml-4 headtext txt-header">
          {{ $t("EmployementLayOffReport") }}
        </p>
    </div>
    <v-card class="pt-4 commonmb" style="margin-left: 1.5rem; margin-right: 1.5rem">
      <v-row class="pl-md-0 mt-0" style="margin-left: 15px; margin-right: 10px">
        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <p class="mt-0" style="font-size: 16px">
            {{ $t("startdate_report") }}
          </p>
          <date-picker
            width="100%"
            @change="OnChangeStartDate()"
            v-bind:clearable="false"
            v-model="chartStartDate"
            format="DD/MM/YYYY"
            placeholder="DD/MM/YYYY"
            class="fromdate"
            style="width: 150px"
          >
            <template slot="icon-calendar">
              <img
                src="@/assets/images/calendaricon.png"
                style="width: 20px; height: 20px"
              />
            </template>
          </date-picker>
        </v-col>

        <v-col cols="12" xs="12" sm="6" md="3" lg="2">
          <p class="mt-0">{{ $t("enddata") }}</p>
          <date-picker
            @change="OnChangeEndDate()"
            v-bind:clearable="false"
            class="todate"
            v-model="chartEndDate"
            format="DD/MM/YYYY"
            width="100%"
            placeholder="DD/MM/YYYY"
            style="width: 150px"
          >
            <template slot="icon-calendar">
              <img
                src="@/assets/images/calendaricon.png"
                style="width: 20px; height: 20px"
              />
            </template>
          </date-picker>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" xs="12" sm="4" md="3" lg="2" xl="2">
          <v-autocomplete
            v-model="expit"
            :items="exportItem"
            item-text="exptxt"
            item-value="expval"
            color="#F99D20"
            class="movelf ddl"
            clearable
            outlined
            :placeholder="$t(`Export`)"
            style="
              min-width: 150px;
              max-width: 150px;
              border-radius: 5px;
              margin-top: 1.8rem;
            "
            dense
            v-on:change="changetoexport(expit)"
            :disabled="permission.export"
          >
            <v-icon
              slot="append"
              class="v-icon notranslate mdi mdi-chevron-down theme--light"
            />
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row class="chartbg" justify="center">
        <line-chart
          v-if="loaded"
          :chartData="chartdata"
          :options="options"
          class="linecht"
        ></line-chart>
      </v-row>
      <div>
        <p
          style="
            font-size: 18px;
            margin-left: 22px;
            margin-top: 18px;
            color: #f99d20;
          "
        >
          {{ $t("Terminations") }} ({{ TerminationList.length }})
        </p>

        <div class="mt-5">
          <v-data-table
            style="margin-left: 2rem; margin-right: 2rem"
            :items="TerminationList"
            hide-default-footer
            hide-default-header
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            :mobile-breakpoint="0"
          >
            <template v-slot:header="{ props: {} }">
              <thead>
                <tr>
                  <th>{{ $t("EmployeeID") }}</th>
                  <th>{{ $t("employee_name") }}</th>
                  <th>{{ $t("employeetype") }}</th>
                  <th>{{ $t("department") }}</th>
                  <th>{{ $t("division") }}</th>
                  <th>{{ $t("position") }}</th>
                  <th>{{ $t("hired_date") }}</th>
                  <th>{{ $t("terminate_date") }}</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.employee_Code }}</td>
                <td>{{ item.employee_Name }}</td>
                <td>{{ item.employeeType }}</td>
                <td>{{ item.department_Name }}</td>
                <td>{{ item.division_Name }}</td>
                <td>{{ item.position_Name }}</td>
                <td>{{ DateFormat(item.hire_Date) }}</td>
                <td>{{ DateFormat(item.termination_Date) }}</td>
              </tr>
            </template>
          </v-data-table>
          <div class="text-right" v-if="TerminationList.length">
            <PaginationComponent
            :page.sync="page"
            :pageCount="pageCount"
            :itemsPerPage.sync="itemsPerPage"
        /> 
          </div>
        </div>
      </div>
    </v-card>
    <!-- PDF Dialog -->
    <v-dialog v-model="pdfDialog" max-width="100%">
      <v-card>
        <div class="canvas_div_pdf">
          <v-card-text ref="content">
            <div style="margin-left: 1rem">
              <v-row class="pl-md-0 mt-0 pt-2">
                <v-col cols="12" xs="12" sm="6" md="3" lg="2">
                  <p class="mt-0 mb-1 ml-1">{{ DateFormatonTop(todaydate) }}</p>
                  <p class="headtext" style="font-size: 28px">
                    {{ $t("Terminations") }}
                  </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  class="mr-0 pr-0"
                  cols="3"
                  xs="6"
                  sm="2"
                  md="1"
                  lg="1"
                  xl="1"
                >
                  <v-img
                    class="logo"
                    style="padding: -10px; padding-right: 0px"
                    :src="logo.image"
                    max-height="60"
                    max-width="60"
                    min-width="60"
                    min-height="60"
                    contain
                  />
                </v-col>
                <v-col
                  style="min-width: 150px"
                  cols="4"
                  xs="6"
                  sm="1"
                  md="1"
                  lg="1"
                  xl="1"
                  class="mt-2 ml-0 pl-0"
                >
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: 0px;
                      font-size: 18px;
                      color: #0fa7d8 !important;
                    "
                  >
                    Optimistic
                  </p>
                  <p
                    style="
                      padding-left: 0px;
                      margin-bottom: -5px;
                      font-size: 12px;
                      color: #f99d20 !important;
                    "
                  >
                    Powered by Optimistic
                  </p>
                </v-col>
              </v-row>
              <v-row class="pl-md-0 mt-0" style="margin-right: 10px">
                <v-col class="mt-0" cols="12" xs="12" sm="6" md="3" lg="2">
                  <p class="mt-0 mb-0">{{ $t("Dates") }}</p>
                  <p class="headtext">
                    {{ DateFormatonTopbet(chartStartDate) }} -
                    {{ DateFormatonTopbet(chartEndDate) }}
                  </p>
                </v-col>
              </v-row>
              <hr />
            </div>
            <v-row class="chartbg" justify="center">
              <line-chart
                v-if="loaded"
                :chartData="chartdata"
                :options="options"
                class="linecht"
              ></line-chart>
            </v-row>
            <div>
              <p
                style="
                  font-size: 18px;
                  margin-left: 22px;
                  margin-top: 18px;
                  color: #f99d20;
                "
              >
                {{ $t("Terminations") }} ({{ TerminationList.length }})
              </p>
              <v-data-table
                style="margin-left: 2rem; margin-right: 2rem"
                :items="TerminationList"
                hide-default-footer
                hide-default-header
                :items-per-page="TerminationList.length"
              >
                <template v-slot:header="{ props: {} }">
                  <thead>
                    <tr>
                      <th>{{ $t("EmployeeID") }}</th>
                      <th>{{ $t("employee_name") }}</th>
                      <th>{{ $t("employeetype") }}</th>
                      <th>{{ $t("department") }}</th>
                      <th>{{ $t("division") }}</th>
                      <th>{{ $t("position") }}</th>
                      <th>{{ $t("hired_date") }}</th>
                      <th>{{ $t("terminate_date") }}</th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.employee_Code }}</td>
                    <td>{{ item.employee_Name }}</td>
                    <td>{{ item.employeeType }}</td>
                    <td>{{ item.department_Name }}</td>
                    <td>{{ item.division_Name }}</td>
                    <td>{{ item.position_Name }}</td>
                    <td>{{ DateFormat(item.hire_Date) }}</td>
                    <td>{{ DateFormat(item.termination_Date) }}</td>
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-card-text>
        </div>
        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            class="mr-3 text-capitalize fix-btn"
            width="150"
            max-height="35"
            text
            @click="
              pdfDialog = false;
              expit = '';
            "
          >
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            color="#F99D20"
            class="mr-3 text-capitalize white--text fix-btn"
            width="150"
            max-height="35"
            style="font-weight: normal"
            @click="PrintPDF()"
          >
            {{ $t("Print") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>

<script>
import LineChart from "@/components/LineChart";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import DatePicker from "vue2-datepicker";
import axios from "axios";
import moment from "moment";
import enurl from "@/api/environment";
import "vue2-datepicker/index.css";
import store from "@/store";
import XLSX from "xlsx";
import CheckViewExport from "@/function/RolePermissions.js";
import $ from "jquery";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';

export default {
  components: {
    LineChart,
    DatePicker,
    LoadingDialog,
    PaginationComponent
  },
  mixins: [CheckViewExport],
  data() {
    return {
      permission: {
        export: true,
      },
      todaydate: new Date(),
      expit: "",
      sheetTitle: null,
      pdfDialog: false,
      loaded: true,
      url: enurl.apiUrl,
      LoadingDialog: false,
      page: 1,
      pageCount: 15,
      itemsPerPage: 5,
      chartStartDate: null,
      chartEndDate: new Date(),
      logo: {
        image: require("@/assets/images/logo@2x.png"),
      },
      exportItem: [
        { expval: 1, exptxt: "Excel" },
        { expval: 2, exptxt: "PDF" },
        { expval: 3, exptxt: "CSV" },
      ],
      TerminationList: [],
      AdditionList: [],
      thetoto: 0,
      thetofi: 0,
      monthname: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      chartdata: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            data: [0, 1, 10, 20, 21, 25, 25, 27, 28, 30, 40, 100],
            fill: false,
            borderColor: "#F69C1F",
            backgroundColor: "#F69C1F",
            borderWidth: 1,
          },
        ],
      },
      options: {
        elements: {
          line: {
            tension: 0,
          },
        },
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "No. of Employees",
                fontColor: "#000",
                fontStyle: "bold",
              },
              ticks: {
                min: 0,
                max: 250,
                stepSize: 50,
                reverse: false,
                beginAtZero: true,
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Time Period",
                fontColor: "#000",
                fontStyle: "bold",
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
              },
              ticks: {
                callback: function (value) {
                  return value.trimStart().split(" ")[0];
                },
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        tooltips: {
          displayColors: false,
          callbacks: {
            label: (tooltipItem) =>
              `${tooltipItem.yLabel}, ${this.thespaceback(tooltipItem.label)}`,
            title: () => null,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    let self = this;
    self.chartStartDate = new Date();
    self.chartStartDate.setDate(1);
    self.chartStartDate.setMonth(0);
    self.chartdata;
    self.GetTerminationList();
    self.permission = self.CheckViewExport(174, 175);
  },
  watch: {
    // chartdata: function () {
    //   this.renderChart(this.chartdata, this.options);
    // },
  },
  methods: {
    thespaceback(text) {
      return text.trimStart().split(" ")[1];
    },
    OnChangeStartDate() {
      let self = this;
      if (
        moment(self.chartStartDate) < moment(self.chartEndDate).add(1, "day")
      ) {
        self.GetTerminationList();
      } else {
        alert("Start date can’t be after end date.");
      }
    },
    OnChangeEndDate() {
      let self = this;
      if (
        moment(self.chartStartDate) < moment(self.chartEndDate).add(1, "day")
      ) {
        self.GetTerminationList();
      } else {
        alert("End date can’t be before start date.");
      }
    },
    DateFormatonTop(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${month}/${day}/${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    DateFormatonTopbet(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${year}-${month}-${day}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    DateFormat(value) {
      let output = "-";
      let temp = new Date(value);
      try {
        let day = temp.getDate().toString().padStart(2, 0);
        let month = (temp.getMonth() + 1).toString().padStart(2, 0);
        let year = temp.getFullYear();
        output = `${day}/${month}/${year}`;
        return output;
      } catch (err) {
        return output;
      }
    },
    back() {
      this.$router.push(`/employmentreport`).catch(()=>{});
    },
    TerDateFormat(value) {
      let temp = new Date(value + "Z");
      let day = temp.getDate().toString().padStart(2, 0);
      let output = `${day}`;
      return output;
    },
    // Print PDF
    PrintPDF() {
      html2canvas($(".canvas_div_pdf")[0], {
        allowTaint: true,
        useCORS: true,
      }).then(function (canvas) {
        var imgWidth = 210;
        var pageHeight = 290;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF("p", "mm");
        var position = 0;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;
        }
        doc.save("Terminations Report.pdf");
      });
    },
    // Get Temination Data
    GetTerminationList() {
      try {
        let self = this;
        let startyear = null;
        let endyear = null;
        let company_ID = store.state.companyID;
        let startday = self.chartStartDate.getDate();
        let startmonth = self.chartStartDate.getMonth();
        let startyears = self.chartStartDate.getFullYear();
        let endday = self.chartEndDate.getDate();
        let endmonth = self.chartEndDate.getMonth();
        let endyears = self.chartEndDate.getFullYear();
        let labels = [];
        let dshow = [];
        if (self.chartStartDate != null || self.chartEndDate != null)
        {
          let tempsdate = self.chartStartDate.setHours(0);
          self.chartStartDate.setMinutes(0);
          self.chartStartDate.setSeconds(0);
          startyear = moment(tempsdate)
            .add(1, "day")
            .utc()
            .format("DD/MM/YYYY");
          let tempedate = self.chartEndDate.setHours(0);
          self.chartEndDate.setMinutes(0);
          self.chartEndDate.setSeconds(0);
          endyear = moment(tempedate).utc().format("DD/MM/YYYY");
        }
        let url = `${self.url}Termination/GetTerminationByDate?companyID=${company_ID}`;
        if (startyear != null)
        {
          url += `&startDate=${startyear}`;
        }
        if (endyear != null)
        {
          url += `&endDate=${endyear}`;
        }

        self.LoadingDialog = true;
        axios.get(url).then(function (response) {
          if(response.data.status == 0)
          {
            self.TerminationList = response.data.data;
            if (self.TerminationList.length != 0)
            {
              self.thetoto = self.TerminationList[0].all_Hired_Total_Staff;
              self.thetofi = self.TerminationList[0].all_Total_Staff;
              self.AdditionList = self.TerminationList[0].addition_List;
            }
            if (startyears == endyears)
            {
              if (startmonth == endmonth)
              {
                if (startday == endday)
                {
                  labels.push(self.monthname[startmonth] + startday);
                }
                else
                {
                  let nu = endday - startday + 1;
                  let dp = startday;
                  for (let i = 0; i < nu; i++)
                  {
                    let ae = self.thetoto - self.thetofi;
                    let howmany = [];
                    for (let k = 0; k < self.AdditionList.length; k++)
                    {
                      let addate = new Date(self.AdditionList[k].hire_Date + "Z");
                      let adyear = addate.getFullYear();
                      let admonth = addate.getMonth();
                      let adday = addate.getDate();
                      if (startyears == adyear)
                      {
                        if (startmonth == admonth)
                        {
                          if (adday <= dp) ae = ae + 1;
                        }
                      }
                    }
                    for (let j = 0; j < self.TerminationList.length; j++)
                    {
                      let dy = new Date(self.TerminationList[j].termination_Date + "Z");
                      let tod = 0;
                      if (dy != null && dy != "")
                      {
                        let yr = dy.getFullYear();
                        let dr = dy.getDate();
                        let mr = dy.getMonth();
                        if (startyears == yr)
                        {
                          if (startmonth == mr)
                          {
                            if (dp == dr)
                            {
                              tod++;
                              howmany.push(tod);
                            }
                            if (dp > dr)
                            {
                              ae = ae - 1;
                            }
                          }
                        }
                      }
                    }
                    if (ae == 0)
                    {
                      labels.push(self.monthname[startmonth] + dp + " " + "0%");
                    }
                    else
                    {
                      labels.push(self.monthname[startmonth] +dp +" " +parseInt((howmany.length / ae) * 100) +"%");
                    }
                    dp++;
                    dshow.push(howmany.length);
                  }
                }
              }
              else
              {
                let nu = endmonth - startmonth + 1;
                let mp = startmonth;
                for (let i = 0; i < nu; i++)
                {
                  let ae = self.thetoto - self.thetofi;
                  let howmany = [];
                  for (let k = 0; k < self.AdditionList.length; k++)
                  {
                    let addate = new Date(self.AdditionList[k].hire_Date + "Z");
                    let adyear = addate.getFullYear();
                    let admonth = addate.getMonth();
                    if (startyears == adyear)
                    {
                      if (admonth <= mp)
                      {
                        ae = ae + 1;
                      }
                    }
                  }
                  //console.log("the"+ i+ "total is "+ ae)
                  for (let j = 0; j < self.TerminationList.length; j++)
                  {
                    let dy = new Date(self.TerminationList[j].termination_Date + "Z");
                    let tod = 0;
                    if (dy != null && dy != "")
                    {
                      let yr = dy.getFullYear();
                      let mr = dy.getMonth();
                      if (startyears == yr)
                      {
                        if (mp == mr)
                        {
                          tod++;
                          howmany.push(tod);
                        }
                        if (mp > mr)
                        {
                          ae = ae - 1;
                        }
                      }
                    }
                  }
                  //console.log("the final total is "+ ae)
                  labels.push(self.monthname[mp] +" " +parseInt((howmany.length / ae) * 100) +"%");
                  mp++;
                  dshow.push(howmany.length);
                }
              }
            }
            else
            {
              let nu = endyears - startyears + 1;
              let yp = startyears;
              for (let i = 0; i < nu; i++)
              {
                let ae = self.thetoto - self.thetofi;
                let howmany = [];
                for (let k = 0; k < self.AdditionList.length; k++)
                {
                  let addate = new Date(self.AdditionList[k].hire_Date + "Z");
                  let adyear = addate.getFullYear();
                  if (adyear <= yp)
                  {
                    ae = ae + 1;
                  }
                }
                for (let j = 0; j < self.TerminationList.length; j++)
                {
                  let dy = new Date(self.TerminationList[j].termination_Date + "Z");
                  let tod = 0;
                  if (dy != null && dy != "" && dy != undefined)
                  {
                    let yr = dy.getFullYear();
                    if (yp == yr)
                    {
                      tod++;
                      howmany.push(tod);
                    }
                    if (yp > yr)
                    {
                      ae = ae - 1;
                    }
                  }
                }
                if (ae == 0)
                {
                  labels.push(yp + " " + "0%");
                }
                else
                {
                  labels.push(yp + " " + parseInt((howmany.length / ae) * 100) + "%");
                }
                yp++;
                dshow.push(howmany.length);
              }
            }

            let barDataSets = [];
            barDataSets.push({
              label: "My First dataset",
              data: dshow,
              borderWidth: 1,
              backgroundColor: "#F69C1F",
              borderColor: "#F69C1F",
              fill: false,
            });
            self.chartdata = {
              labels: labels,
              datasets: barDataSets,
            };
          }
          else
          {
            //alert(response.data.message);
            self.TerminationList = [];
          }
          self.LoadingDialog = false;
        });
      } catch (error) {
        alert("error");
        self.LoadingDialog = false;
      }
    },
    // Export
    changetoexport(v) {
      let self = this;
      self.LoadingDialog = true;
      if (v == 1) {
        // Excel
        if (self.TerminationList.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
          for (let v of self.TerminationList) {
            if (localStorage.Templang == "ประเทศไทย") {
              wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.employee_Name,
                ["ประเภทพนักงาน"]: v.employeeType,
                ["แผนก"]: v.department_Name,
                ["ฝ่าย"]: v.division_Name,
                ["ตำแหน่ง"]: v.position_Name,
                ["วันที่ได้รับการว่าจ้าง"]: self.DateFormat(v.hire_Date),
                ["วันสิ้นสุดการทำงาน"]: self.DateFormat(v.termination_Date),
              });
            } else {
              wsdata.push({
                ["Employee ID"]: v.employee_Code,
                ["Employee Name"]: v.employee_Name,
                ["Employee Type"]: v.employeeType,
                ["Department"]: v.department_Name,
                ["Division"]: v.division_Name,
                ["Position"]: v.position_Name,
                ["Hire Date"]: self.DateFormat(v.hire_Date),
                ["Termination Date"]: self.DateFormat(v.termination_Date),
              });
            }
          }
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Terminations Report`;
          self.sheetTitle = `Terminations`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
          XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      } else if (v == 2) {
        // PDF
        self.pdfDialog = true;
        self.LoadingDialog = false;
      } else {
        //CSV
        if (self.TerminationList.length != 0) {
          let tempsheetname = "";
          let wb = XLSX.utils.book_new();
          let wsdata = [];
          for (let v of self.TerminationList) {
            if (localStorage.Templang == "ประเทศไทย") {
              wsdata.push({
                ["รหัสพนักงาน"]: v.employee_Code,
                ["ชื่อพนักงาน"]: v.employee_Name,
                ["ประเภทพนักงาน"]: v.employeeType,
                ["แผนก"]: v.department_Name,
                ["ฝ่าย"]: v.division_Name,
                ["ตำแหน่ง"]: v.position_Name,
                ["วันที่ได้รับการว่าจ้าง"]: self.DateFormat(v.hire_Date),
                ["วันสิ้นสุดการทำงาน"]: self.DateFormat(v.termination_Date),
              });
            } else {
              wsdata.push({
                ["Employee ID"]: v.employee_Code,
                ["Employee Name"]: v.employee_Name,
                ["Employee Type"]: v.employeeType,
                ["Department"]: v.department_Name,
                ["Division"]: v.division_Name,
                ["Position"]: v.position_Name,
                ["Hire Date"]: self.DateFormat(v.hire_Date),
                ["Termination Date"]: self.DateFormat(v.termination_Date),
              });
            }
          }
          const ws = XLSX.utils.json_to_sheet(wsdata);
          tempsheetname = `Terminations Report`;
          self.sheetTitle = `Terminations`;
          XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
          XLSX.writeFile(wb, `${tempsheetname}.csv`, {
            compression: true,
          });
          self.LoadingDialog = false;
        } else {
          alert("Your selected date range doesn't have Data.");
          self.LoadingDialog = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.chartbg {
  background-color: #f8f8f8;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0px 2px 6px #00000029;
  opacity: 1;
}
.linecht {
  height: 368px;
  width: 800px;
  position: "relative";
}
.container {
  display: flex;
  justify-content: center;
  height: 350px;
  width: 930px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 3rem;
  opacity: 1;
}
@media only screen and (max-width: 850px) {
  .linecht {
    width: 200px !important;
  }
  .ddl {
    float: left;
  }
}
@media (min-width: 768px) and (max-width: 1400px) {
  .linecht {
    width: 600px !important;
  }
  .ddl {
    float: right;
  }
}
@media only screen and (max-width: 1900px) {
  .movelf {
    margin-right: 2rem;
  }
}
.ddl {
  float: right;
}
>>> .mx-input {
  border: 2px solid #f99d20 !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  color: #f99d20;
  font-weight: normal;
  font-size: 13px;
}
.font {
  font-size: 16px;
}
.logo {
  margin-left: 30px;
}
@media (max-width: 860px) {
  .logo {
    margin-left: 0px;
  }
}
@media (min-width: 950px) and (max-width: 1200px) {
  .logo {
    margin-left: 0px;
  }
}
</style>