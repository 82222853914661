<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <p style="font-size: 25px; color: #43425d; margin-top: 15px">
        {{ $t("NewRole") }}
      </p>
    </div>
    <div class="mt-6">
      <v-card>
        <div style="padding-top: 20px; margin-left: 2rem; margin-right: 2rem">
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                autocomplete="off"
                v-on:keyup="keymonitor"
                color="#F99D20"
                v-model="add.roleid"
                :error-messages="error.roleid"
                dense
                ><template v-slot:label>
                  <span>
                    {{ $t("RoleCode") }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                autocomplete="off"
                color="#F99D20"
                v-on:keyup="keymonitor"
                v-model="add.rolename"
                :error-messages="error.rolename"
                dense
                ><template v-slot:label>
                  <span>
                    {{ $t("RoleName") }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                autocomplete="off"
                v-model="add.roleper"
                color="#F99D20"
                :error-messages="error.permission"
                readonly
                dense
                ><template v-slot:label>
                  <span>
                    {{ $t("Permission")
                    }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
            <v-col>
              <v-btn
                rounded
                width="100"
                router
                :to="{ name: 'roleper', params: { roleper: 'rolepermission' } }"
                class="text-capitalize mr-5"
                color="#C6E8CB"
                style="color: #22a534"
                >{{ $t("Assign") }}</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <div style="height: 40vh"></div>
        <v-card-actions class="pb-6 mr-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="mr-2 text-capitalize commoncancelbtn"
            @click="back()"
            text
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="mr-4 text-capitalize commonsavebtn"
            @click="addRole()"
            >{{ $t("save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
    <!-- laodingdialog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import enurl from "@/api/environment";
import axios from "axios";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  computed: {
    ...mapGetters(["selected"]),
  },
  mixins: [LogTrace, utils],
  data() {
    return {
      loadingdialog: false,
      localpermit: [],
      TempPermitList: [],
      tpl: [],
      url: enurl.apiUrl,
      error: {
        roleid: null,
        rolename: null,
        permission: null,
      },
      add: {
        roleid: "",
        rolename: "",
        roleper: "",
      },
      
    };
  },
  mounted() {
    this.getRole();
  },
  created() {
    if(JSON.parse(localStorage.getItem("roleCode")) != null){
      let taskSt = JSON.parse(localStorage.getItem("roleCode"));
      this.add.roleid = taskSt.roleid;
      this.add.rolename = taskSt.roleCode;
    }
  },

  methods: {
    keymonitor: function () {
      let tem = {
        roleid: this.add.roleid,
        roleCode: this.add.rolename,
      };
      localStorage.setItem("roleCode", JSON.stringify(tem));
    },
    back() {
      localStorage.setItem("roleCode", JSON.stringify(null));
      localStorage.setItem("localroleper", JSON.stringify(null));
      //this.$router.push({ name: "usermanagement" });
      this.$router.push("/usermanagement/usergroup/role").catch(()=>{});
    },
    getRole() {
      let self = this;
      self.loadingdialog = true;
      self.localpermit = JSON.parse(localStorage.getItem("localroleper"));
      if (self.localpermit != null) {
        self.add.roleper = [...self.localpermit.map((x) => x.pername)];
        self.add.roleper = [...new Set(self.add.roleper)];
        self.TempPermitList = [...self.localpermit.map((x) => x.perid)];

        if (self.TempPermitList != null) {
          for (let j = 0; j < self.TempPermitList.length; j++) {
            self.tpl.push({
              permission_ID: self.TempPermitList[j],
            });
          }
        }
        self.loadingdialog = false;
      } else {
        self.add.roleper = null;
        self.loadingdialog = false;
      }
    },

    addRole() {
      let self = this;
      self.loadingdialog = true;
      self.error.roleid = !self.add.roleid
        ? "Please fill in the required fields"
        : "";
      self.error.rolename = !self.add.rolename
        ? "Please fill in the required fields"
        : "";

      self.error.permission = !self.add.roleper
        ? "Please fill in the required fields"
        : "";

      let tempvali = self.ValidateAddTrue();
      if (tempvali == true) {
        // let temp = self.psuharr();

        let roleshift = {
          role_Name: self.add.rolename,
          role_Code: self.add.roleid,
          rolePerData: self.tpl,
          company_ID: store.state.companyID,
        };

        axios
          .post(`${self.url}Role/AddRole`, roleshift)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              self.$router.push("/usermanagement/usergroup/role").catch(()=>{});
              localStorage.setItem("roleCode", JSON.stringify(null));
              localStorage.setItem("localroleper", JSON.stringify(null));
              self.LogTrace(null, "Add Role", 37, "Low");
              self.loadingdialog = false;
            }
          })
          .catch(function (error) {
            alert(error);
            self.LogTrace(error, "Add Role Fail", 37, "Low");
            self.loadingdialog = false;
          });
      } else {
        this.ValidateAddFalse();
        self.loadingdialog = false;
      }
    },
    ValidateAddTrue() {
      let self = this;
      if (
        self.add.roleid != null &&
        self.add.rolename != null &&
        self.add.roleid != "" &&
        self.add.roleper != null &&
        self.add.roleper != "" &&
        self.add.rolename != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    ValidateAddFalse() {
      let self = this;
      if (
        self.add.roleid == null ||
        self.add.rolename == null ||
        self.add.roleid == "" ||
        self.add.permission == null ||
        self.add.permission == "" ||
        self.add.rolename == ""
      ) {
        alert("Please fill in the required fields!");
      }
    },
    psuharr() {
      let self = this;
      let temparr = [
        {
          permission_ID: null,
        },
      ];
      for (let i = 0; i < self.selected.length; i++) {
        if (i == 0) {
          temparr[i].permission_ID = self.selected[i].permission_ID;
        } else {
          temparr.push({
            permission_ID: self.selected[i].permission_ID,
          });
        }
      }
      return temparr;
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
