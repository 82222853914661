<template>
  <div style="height: auto">
    <v-card>
      <div style="margin-left: 40px; margin-right: 40px; padding-top: 40px">
        <v-btn
          color="#F99D20"
          class="white--text text-capitalize mr-5"
          width="150"
          max-height="35"
          @click="addDialog = true"
          :disabled="permission.add"
          >{{ $t("AddPeriod") }}</v-btn
        >

        <v-data-table
          :headers="headers"
          :items="PayPeriodName"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          hide-default-header
          @page-count="pageCount = $event"
          class="mt-4"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th>{{ $t("PayrollCalculationPeriod") }}</th>
                <th>{{ $t("Actions") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td class="text-left" style="border-bottom: 1px solid #fff">
                {{ item.period_Name }}
              </td>
              <td class="text-left" style="border-bottom: 1px solid #fff">
                <v-btn icon @click="GetPayRollPeriodItems(item.payroll_Period_ID)" :disabled="permission.edit">
                  <img class="editIcon" :src="images.edit" alt="editIcon"/>
                </v-btn>
                <v-btn icon @click="Deletedia(item.payroll_Period_ID)" :disabled="permission.delete">
                  <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-row justify="end" class="ml-5 mt-5 mr-5" v-if="PayPeriodName.length">
          <PaginationComponent
            :page.sync="page"
            :pageCount="pageCount"
            :itemsPerPage.sync="itemsPerPage"
        />
        </v-row>
      </div>
    </v-card>

    <!-- add Dialog -->
    <v-dialog v-model="addDialog" persistent max-width="720">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p
            class="mt-3 ml-2 fixed-title"
            style="font-size: 22px; color: #444444"
          >
            {{ $t("PayrollCalculationPeriod") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="d-flex mt-3">
              <v-label>
                {{ $t("PeriodName") }}<span class="red--text"> *</span></v-label
              >
              <v-text-field
                v-model="periodname"
                autocomplete="off"
                :error-messages="errorPeriodName"
                color="#F99D20"
                @keypress="checkPeriodName()"
                dense
                outlined
                style="margin-top: -0.4rem; margin-left: 1rem"
              ></v-text-field>
            </div>
            <v-row v-for="(item, index) in caldata" :key="item.value">
              <div style="max-width: 200px">
                <v-col>
                  <label>Item<span class="red--text"> *</span></label>
                  <v-text-field
                    v-model="item.item"
                    autocomplete="off"
                    class="kanit-medium fixed"
                    color="#F99D20"
                    @keypress="checkItemError(index)"
                    :error-messages="item.itemError"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </div>
              <!-- <div style="max-width: 200px"> -->
                <v-col>
                  <label
                    >{{ $t("start_date")
                    }}<span class="red--text"> *</span></label
                  >
                  <date-picker 
                    :class="item.startDateError ? 'custom-vuedatepicker-er-mg1' : 'custom-vuedatepicker1'"  
                    v-bind:clearable="false" 
                    value-type="YYYY-MM-DD" 
                    v-model="item.startdate" 
                    no-title 
                    format="DD/MM/YYYY" 
                    @change="AddallowDates(item.startdate, item.enddate)"
                    @input="checkstartDateError(index)"
                    :editable="false" 
                    :style="{ width: '100%' }">
                  </date-picker>
                  <span v-if="item.startDateError" class="error-message ml-2">
                    {{ item.startDateError }}
                  </span>
                  <!-- <v-menu
                    v-model="item.startDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        append-icon="mdi-calendar-month-outline"
                        v-model="computedDateFormatted1[item.startdateid]"
                        class="kanit-medium"
                        persistent-hint
                        v-on="on"
                        color="#F99D20"
                        :error-messages="item.startDateError"
                        outlined
                        readonly
                        dense
                        autocomplete="off"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="AddallowDates(item.startdate, item.enddate)"
                      v-model="item.startdate"
                      no-title
                      @input="checkstartDateError(index)"
                    ></v-date-picker>
                  </v-menu> -->
                </v-col>
              <!-- </div>
              <div style="max-width: 200px"> -->
                <v-col>
                  <label>
                    {{ $t("enddata") }}<span class="red--text"> *</span>
                  </label>
                  <date-picker 
                    :class="item.endDateError ? 'custom-vuedatepicker-er-mg1' : 'custom-vuedatepicker1'"  
                    v-bind:clearable="false" 
                    value-type="YYYY-MM-DD" 
                    v-model="item.enddate" 
                    no-title 
                    format="DD/MM/YYYY" 
                    @change="AddEndallowDates(item.startdate, item.enddate)"
                    @input="checkendDateError(index)"
                    :editable="false" 
                    :style="{ width: '100%' }">
                  </date-picker>
                  <span v-if="item.endDateError" class="error-message ml-2">
                    {{ item.endDateError }}
                  </span>
                  <!-- <v-menu
                    v-model="item.endDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        append-icon="mdi-calendar-month-outline"
                        v-model="computedDateFormatted2[item.endateid]"
                        class="kanit-medium"
                        persistent-hint
                        v-on="on"
                        color="#F99D20"
                        :error-messages="item.endDateError"
                        outlined
                        readonly
                        dense
                        autocomplete="off"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="AddEndallowDates(item.startdate, item.enddate)"
                      v-model="item.enddate"
                      no-title
                      @input="checkendDateError(index)"
                    ></v-date-picker>
                  </v-menu> -->
                </v-col>
              <!-- </div> -->
              <v-col cols="12" sm="1" align="center">
                <v-btn style="width: 20px" @click="deleteRowAdd(item.id)" v-show="item.counter" icon class="mt-6">
                  <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
                </v-btn>
              </v-col>
            </v-row>
            <v-btn icon @click="addItem">
              <img class="plusIcon" :src="images.plusIcon" alt="plusIcon"/>
            </v-btn>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="mr-5 text-capitalize fixed-btn commoncancelbtn"
              outlined
              @click="cancelAdd"
            >
              {{ $t("Cancel") }}</v-btn
            >
            <v-btn
              class="mr-5 text-capitalize fixed-btn commonsavebtn"
              style="float: right"
              @click="AddPayRollPeriod"
              >{{ $t("Save") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- edit dialog -->
    <v-dialog v-model="editDialog" persistent max-width="720">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p
            class="mt-3 ml-2 fixed-title"
            style="font-size: 22px; color: #444444"
          >
            {{ $t("PayrollCalculationPeriod") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="d-flex mt-3">
              <v-label>
                {{ $t("PeriodName") }}
                <span class="red--text"> *</span>
              </v-label>
              <v-text-field
                v-model="editperiodname"
                autocomplete="off"
                color="#F99D20"
                :error-messages="editErrorPeriodName"
                dense
                @keypress="checkPeriodNameEdit()"
                outlined
                style="margin-top: -0.4rem;margin-left: 1rem;"
              >
              </v-text-field>
            </div>
            <v-row v-for="(item, index) in Editcaldata" :key="item.value">
              <div style="max-width: 200px">
                <v-col>
                  <div style="max-width: 200px">
                  <label>Item<span class="red--text"> *</span></label>
                  <v-text-field
                    v-model="item.item"
                    class="kanit-medium fixed"
                    color="#F99D20"
                    @keypress="checkItemErrorEdit(index)"
                    :error-messages="item.itemError"
                    outlined
                    dense
                  ></v-text-field>
                  </div>
              </v-col>
              </div>
              <!-- <div style="max-width: 200px"> -->
                <v-col>
                  <label>
                    {{ $t("start_date") }}
                    <span class="red--text"> *</span>
                  </label>
                  <date-picker 
                    :class="item.startDateError ? 'custom-vuedatepicker-er-mg1' : 'custom-vuedatepicker1'"  
                    v-bind:clearable="false" 
                    value-type="YYYY-MM-DD" 
                    v-model="item.startdate" 
                    no-title 
                    format="DD/MM/YYYY" 
                    @change="allowDates(item.startdate, item.enddate)"
                    @input="checkstartDateErrorEdit(index)"
                    :editable="false" 
                    :style="{ width: '100%' }">
                  </date-picker>
                  <span v-if="item.startDateError" class="error-message ml-2">
                    {{ item.startDateError }}
                  </span>
                  <!-- <v-menu
                    v-model="item.startDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        append-icon="mdi-calendar-month-outline"
                        v-model="computedDateFormatted3[item.startdateid]"
                        class="kanit-medium"
                        persistent-hint
                        v-on="on"
                        color="#F99D20"
                        :error-messages="item.startDateError"
                        outlined
                        readonly
                        dense
                        autocomplete="off"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="allowDates(item.startdate, item.enddate)"
                      v-model="item.startdate"
                      no-title
                      @input="checkstartDateErrorEdit(index)"
                    ></v-date-picker>
                  </v-menu> -->
                </v-col>
              <!-- </div>
              <div style="max-width: 200px"> -->
                <v-col>
                  <label> {{ $t("enddata") }}
                    <span class="red--text"> *</span>
                  </label>
                  <date-picker 
                    :class="item.endDateError ? 'custom-vuedatepicker-er-mg1' : 'custom-vuedatepicker1'"  
                    v-bind:clearable="false" 
                    value-type="YYYY-MM-DD" 
                    v-model="item.enddate" 
                    no-title 
                    format="DD/MM/YYYY" 
                    @change="EndallowDates(item.startdate, item.enddate)"
                    @input="checkendDateErrorEdit(index)"
                    :editable="false" 
                    :style="{ width: '100%' }">
                  </date-picker>
                  <span v-if="item.endDateError" class="error-message ml-2">
                    {{ item.endDateError }}
                  </span>
                  <!-- <v-menu
                    v-model="item.endDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    fluid
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        append-icon="mdi-calendar-month-outline"
                        v-model="computedDateFormatted4[item.endateid]"
                        class="kanit-medium"
                        persistent-hint
                        v-on="on"
                        :error-messages="item.endDateError"
                        color="#F99D20"
                        readonly
                        outlined
                        autocomplete="off"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      @change="EndallowDates(item.startdate, item.enddate)"
                      v-model="item.enddate"
                      no-title
                      @input="checkendDateErrorEdit(index)"
                    ></v-date-picker>
                  </v-menu> -->
                </v-col>
              <!-- </div> -->
              <v-col cols="12" sm="1" align="center">
                <v-btn style="width: 20px" @click="deleteUpdateRow(item.id)" v-show="item.counter" icon class="mt-6">
                  <img class="deleteIcon" :src="images.delete" alt="deleteIcon"/>
                </v-btn>
              </v-col>
            </v-row>
            <v-btn icon @click="EditaddItem">
              <img class="plusIcon" :src="images.plusIcon" alt="plusIcon"/>
            </v-btn>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="mr-5 text-capitalize fixed-btn commoncancelbtn"
              outlined
              @click="Cancel"
              >{{ $t("Cancel") }}</v-btn
            >
            <v-btn
              class="mr-5 text-capitalize fixed-btn commonsavebtn"
              style="float: right"
              @click="UpdatePayRollPeriod"
            >
              {{ $t("Save") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog -->
    <v-dialog v-model="deletedialog" class="dialog" max-width="450">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <p style="margin-top: 10px; margin-bottom: -30px">
            {{ $t("deleteitem") }}
          </p>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col align="center" cols="12">
                <p style="font-size: 16px; color: #444444" class="pt-4">
                  {{ $t("confirmdelete") }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-6" style="margin-right: 3%">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="text-capitalize fixed-btn commoncancelbtn"
              @click="deletedialog = false"
              text
              >{{ $t("Cancel") }}</v-btn
            >
            <v-btn
              class="buttonAlign text-capitalize fixed-btn commondeletebtn"
              @click="DeleteBank"
              >{{ $t("Delete") }}</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading Dialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import store from "@/store";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import CheckViewAddEditDelete from "@/function/RolePermissions.js";
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';

export default {
  components:{
    LoadingDialog,
    PaginationComponent
  },
  mixins: [LogTrace, utils, CheckViewAddEditDelete],
  data: (vm) => ({
    permission: {
      add: true,
      edit: true,
      delete: true,
    },
    page: 1,
    pageCount: 15,
    itemsPerPage: 10,
    showdel: true,
    editperiodname: null,
    editErrorPeriodName: "",
    payroll_Period_ID: 0,
    url: enurl.apiUrl,
    LoadingDialog: false,
    periodname: null,
    errorPeriodName: "",
    sid: 1,
    dsid: 1,
    deid: 1,
    sidd: 0,
    dsidd: 0,
    deidd: 0,
    startDate: false,
    dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
    caldata: [
      {
        counter: false,
        condi: 1,
        id: 0,
        item: "",
        startDate: false,
        startdateid: 0,
        startdate: "",
        endDate: false,
        endateid: 0,
        enddate: "",
        delete: false,
        itemError: "",
        startDateError: "",
        endDateError: "",
      },
    ],
    Editcaldata: [
      {
        counter: false,
        condi: 1,
        id: 0,
        item: "",
        startDate: false,
        startdateid: 0,
        startdate: "",
        endDate: false,
        endateid: 0,
        enddate: "",
        delete: false,
        itemError: "",
        startDateError: "",
        endDateError: "",
      },
    ],

    PayPeriodName: [],
    deletedialog: false,
    addDialog: false,
    editDialog: false,
    PayPeriodItems: [],
    headers: [
      {
        text: "Year",
        value: "year",
        sortable: true,
      },
      {
        text: "Month",
        value: "empname",
        sortable: true,
      },
      {
        text: "Effective Start Date",
        value: "stardate",

        sortable: false,
      },
      {
        text: "Effective End Date",
        value: "endate",

        sortable: false,
      },

      {
        text: "Action",
        value: "action",
        align: "center",
        sortable: true,
      },
    ],
    headers1: [
      {
        text: "Payroll Calculation Period",
        align: "left",
        sortable: false,
      },
      {
        text: "Action",
        align: "left",
        sortable: false,
      },
    ],
  }),

  computed: {
    computedDateFormatted1() {
      let format = [];
      for (let i = 0; i < this.caldata.length; i++) {
        format.push(this.formatDate(this.caldata[i].startdate));
      }
      return format;
    },
    computedDateFormatted2() {
      let format = [];
      for (let i = 0; i < this.caldata.length; i++) {
        format.push(this.formatDate(this.caldata[i].enddate));
      }
      return format;
    },
    computedDateFormatted3() {
      let format = [];
      for (let i = 0; i < this.Editcaldata.length; i++) {
        format.push(this.formatDate(this.Editcaldata[i].startdate));
      }
      return format;
    },
    computedDateFormatted4() {
      let format = [];
      for (let i = 0; i < this.Editcaldata.length; i++) {
        format.push(this.formatDate(this.Editcaldata[i].enddate));
      }
      return format;
    },
    images() {
      return this.$store.state.images;
    }
  },
  mounted() {
    this.GetPayRollCalculationPeriod();
    this.permission = this.CheckViewAddEditDelete(45, 46, 47, 48);
  },
  methods: {
    checkPeriodName() {
      this.errorPeriodName = "";
    },
    checkItemError(index) {
      this.caldata[index].itemError = "";
    },
    checkstartDateError(index) {
      this.caldata[index].startDate = false;
      this.caldata[index].startDateError = "";
    },
    checkendDateError(index) {
      this.caldata[index].endDate = false;
      this.caldata[index].endDateError = "";
    },
    checkPeriodNameEdit() {
      this.editErrorPeriodName = "";
    },
    checkItemErrorEdit(index) {
      this.Editcaldata[index].itemError = "";
    },
    checkstartDateErrorEdit(index) {
      this.Editcaldata[index].startDate = false;
      this.Editcaldata[index].startDateError = "";
    },
    checkendDateErrorEdit(index) {
      this.Editcaldata[index].endDate = false;
      this.Editcaldata[index].endDateError = "";
    },
    //Edit dialog Date check start date and end date
    allowDates(val, endval) {
      let j = null;
      if (val != "" && endval != "") {
        for (var i = 0; i < this.Editcaldata.length; i++) {
          if (this.Editcaldata[i].startdate === val) {
            j = i;
          }
        }

        if (val > this.Editcaldata[j].enddate) {
          alert("Please ensure that the Start date is not after End date");

          return false;
        } else {
          return true;
        }
      }
    },
    //Add dialog Date check start date and end date
    AddallowDates(val, endval) {
      let j = null;
      if (val != "" && endval != "") {
        for (var i = 0; i < this.caldata.length; i++) {
          if (this.caldata[i].startdate === val) {
            j = i;
          }
        }

        if (val > this.caldata[j].enddate) {
          alert("Please ensure that the Start date is not after End date");

          return false;
        } else {
          return true;
        }
      }
    },
    //Add dialog Date check start date and end date
    AddEndallowDates(val, endval) {
      let j = null;
      if (val != "" && endval != "") {
        for (var i = 0; i < this.caldata.length; i++) {
          if (this.caldata[i].startdate === val) {
            j = i;
          }
        }

        if (this.caldata[j].enddate < val) {
          alert("Please ensure that the Start Date is not after End Date.");
          return false;
        } else {
          return true;
        }
      }
    },
    //Edit dialog Date check start date and end date
    EndallowDates(val, endval) {
      let j = null;
      if (val != "" && endval != "") {
        for (var i = 0; i < this.Editcaldata.length; i++) {
          if (this.Editcaldata[i].startdate === val) {
            j = i;
          }
        }

        if (this.Editcaldata[j].enddate < val) {
          alert("Please ensure that the Start Date is not after End Date.");
          return false;
        } else {
          return true;
        }
      }
    },
    //Validation For Start Date is Greater than End date
    ValidationInAdd() {
      let self = this;
      for (let j in self.caldata) {
        if (self.caldata[j].startdate > self.caldata[j].enddate) {
          return false;
        }
      }
      return true;
    },
    //Validation for End Date is smaller than Start Date
    EndDateValidationInAdd() {
      let self = this;
      for (let j in self.caldata) {
        if (self.caldata[j].enddate < self.caldata[j].startdate) {
          alert("Please ensure that the Start Date is not after End Date.");
          return false;
        } else {
          return true;
        }
      }
      return true;
    },
    //Validation For End Date is Smaller than Start date
    EndValidationInEdit() {
      let self = this;
      for (let j in self.Editcaldata) {
        if (self.Editcaldata[j].enddate < self.Editcaldata[j].startdate) {
          // alert("Please ensure that the Start Date is not after End Date.")
          return false;
        } else {
          return true;
        }
      }
      return true;
    },
    //Validation For Start Date is Greater than End date
    ValidationInEdit() {
      let self = this;
      for (let j in self.Editcaldata) {
        if (self.Editcaldata[j].startdate > self.Editcaldata[j].enddate) {
          alert("Please ensure that the Start Date is not after End Date.");
          return false;
        }
      }
      return true;
    },
    //Remove row in Update
    deleteUpdateRow() {
      let self = this;
      self.Editcaldata.splice(self.Editcaldata.length - 1, 1);
      self.Editcaldata[self.Editcaldata.length - 1].counter = true;
      for (let i = 0; i < this.Editcaldata.length; i++) {
        if (self.Editcaldata.length == 1) {
          this.Editcaldata[i].counter = false;
        }
      }
      self.sidd = self.sidd - 1;
      self.dsidd = self.dsidd - 1;
      self.deidd = self.deidd - 1;
    },
    //Remove row in Add
    deleteRowAdd() {
      let self = this;
      self.caldata.splice(self.caldata.length - 1, 1);
      self.caldata[self.caldata.length - 1].counter = true;
      for (let i = 0; i < this.caldata.length; i++) {
        if (self.caldata.length == 1) {
          this.caldata[i].counter = false;
        }
      }
      self.sid = self.sid - 1;
      self.dsid = self.dsid - 1;
      self.deid = self.deid - 1;
    },
    /* Update Pay Roll Calculation Period */
    UpdatePayRollPeriod() {
      let self = this;
      self.ValidateEditError();
      let temp = self.ValidateEdit();
      let validate = self.ValidationInEdit();
      let endval = self.EndValidationInEdit();

      // let StartEndVali = self.allowDates();
      if (temp == true) {
        if (validate == true && endval == true) {
          let PeriodItems = self.PeriodItemsUpdateList();
          self.DeleteoldData();
          let tempdept = {
            payroll_Period_ID: self.payroll_Period_ID,
            period_Name: self.editperiodname,
            company_ID: store.state.companyID,
            itemDatas: PeriodItems,
          };
          self.LoadingDialog = true;

          axios
            .post(`${self.url}PayrollPeriod/UpdatePayrollPeriod`, tempdept)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.Editcaldata = [];
                self.editperiodname = null;
                self.editDialog = false;
                self.LoadingDialog = false;
                self.GetPayRollCalculationPeriod();
                self.LogTrace(
                  null,
                  "Update PayRollPeriodCalculaton",
                  42,
                  "Medium"
                );
              }
            })
            .catch(function (error) {
              self.LogTrace(
                error,
                "Update PayRollPeriodCalculaton Fail",
                42,
                "Medium"
              );
              alert(error + "Correlation ID is 42");
              self.LoadingDialog = false;
            });
        } else {
          self.LoadingDialog = false;
        }
      } else {
        self.LoadingDialog = false;
      }
    },
    /*Update list of Payroll period items */
    PeriodItemsUpdateList() {
      let self = this;
      let temparr = [
        {
          item: null,
          start_Date: null,
          end_Date: null,
          company_ID: null,
        },
      ];

      for (let i = 0; i < self.Editcaldata.length; i++) {
        if (i == 0) {
          temparr[i].item = self.Editcaldata[i].item;
          temparr[i].start_Date = self.Editcaldata[i].startdate;
          temparr[i].end_Date = self.Editcaldata[i].enddate;
          temparr[i].company_ID = store.state.companyID;
        } else {
          temparr.push({
            item: self.Editcaldata[i].item,
            start_Date: self.Editcaldata[i].startdate,
            end_Date: self.Editcaldata[i].enddate,
            company_ID: store.state.companyID,
          });
        }
      }
      return temparr;
    },
    Cancel() {
      let self = this;
      self.editDialog = false;
      //self.Editcaldata = [];
    },
    cancelAdd() {
      let self = this;
      self.addDialog = false;
      self.periodname = null;
      self.errorPeriodName = "";
      for (var i = 0; i < self.caldata.length; i++) {
        self.caldata[i].startdate = "";
        self.caldata[i].enddate = "";
        self.caldata[i].item = null;
        self.caldata[i].counter = false;
        self.caldata[i].itemError = "";
        self.caldata[i].startDateError = "";
        self.caldata[i].endDateError = "";

        if (i >= 1) {
          self.caldata.splice(1, self.caldata.length);
        }
      }
      self.sid = 1;
      self.dsid = 1;
      self.deid = 1;
    },
    /*Get list of Payroll period items */
    GetPayRollPeriodItems(id) {
      let self = this;
      self.payroll_Period_ID = id;
      self.editDialog = true;
      self.LoadingDialog = true;
      let temp = { payroll_Period_ID: self.payroll_Period_ID };
      axios
        .post(`${self.url}PayrollPeriod/GetPayrollPeriodByPayrollCalID`, temp)
        .then(function (response) {
          self.PayPeriodItems = response.data.data;
          self.editperiodname = response.data.data.period_Name;

          self.Editcaldata = [];
          self.sidd = 0;
          self.dsidd = 0;
          self.deidd = 0;

          if (self.PayPeriodItems.datas.length >= 1) {
            for (let i = 0; i < self.PayPeriodItems.datas.length; i++) {
              let plusid = self.sidd++;
              let startid = self.dsidd++;
              let endid = self.deidd++;

              self.Editcaldata.push({
                id: plusid,
                startdateid: startid,
                endateid: endid,
                item: self.PayPeriodItems.datas[i].item,
                startdate: self.PayPeriodItems.datas[i].start_Date,
                enddate: self.PayPeriodItems.datas[i].end_Date,
                delete: true,
                itemError: "",
                startDateError: "",
                endDateError: "",
              });
              if (self.Editcaldata[0].condi == 1) {
                self.Editcaldata.splice(0, 1);
              }
              for (let i = 0; i < self.Editcaldata.length; i++) {
                if (i > 0) {
                  self.Editcaldata[i].counter = true;
                }
                if (i == 0) {
                  self.Editcaldata[i].counter = false;
                }
                if (i > 1) {
                  self.Editcaldata[i - 1].counter = false;
                }
              }
            }

            if (self.Editcaldata.length > self.PayPeriodItems.datas.length) {
              self.Editcaldata.splice(0, 1);
            }
          } else if (self.PayPeriodItems.datas.length == 0) {
            let plusid = self.sidd++;
            let startid = self.dsidd++;
            let endid = self.deidd++;
            self.Editcaldata.push({
              id: plusid,
              startdateid: startid,
              endateid: endid,
              item: null,
              startdate: "",
              enddate: "",
              delete: false,
              itemError: "",
              startDateError: "",
              endDateError: "",
            });
            self.Editcaldata.splice(0, 1);
            for (let i = 0; i < self.Editcaldata.length; i++) {
              if (i >= 1) {
                self.Editcaldata[i - 1].counter = false;
              }
            }
          }

          self.LoadingDialog = false;
        })
        .catch(function (error) {
          alert(error + "Correlation ID is 42");
          self.LoadingDialog = false;
        });
    },
    /* Validate */
    Validate() {
      let self = this;
      // let lastindex = self.caldata[self.caldata.length - 1];
      //alert(lastindex)
      for (let i in self.caldata) {
        if (
          self.caldata[i].item === "" ||
          self.caldata[i].startdate === "" ||
          self.caldata[i].enddate === "" ||
          self.periodname === null
        ) {
          alert("Please fill in all required fields'");
          return false;
        }
      }
      return true;
    },
    ValidateError() {
      this.errorPeriodName = !this.periodname
        ? "Please fill in the required fields"
        : "";
      for (let i in this.caldata) {
        this.caldata[i].itemError = !this.caldata[i].item
          ? "Please fill in the required fields"
          : "";
        this.caldata[i].startDateError = !this.caldata[i].startdate
          ? "Please fill in the required fields"
          : "";
        this.caldata[i].endDateError = !this.caldata[i].enddate
          ? "Please fill in the required fields"
          : "";
      }
    },
    /* Validate */
    ValidateEdit() {
      let self = this;
      // let lastindex = self.caldata[self.caldata.length - 1];
      //alert(lastindex)
      for (let i in self.Editcaldata) {
        if (
          self.Editcaldata[i].item === "" ||
          self.Editcaldata[i].startdate === "" ||
          self.Editcaldata[i].enddate === "" ||
          self.editperiodname === "" ||
          self.editperiodname === null
        ) {
          alert("Required All Fields");
          return false;
        }
      }
      return true;
    },
    ValidateEditError() {
      this.editErrorPeriodName = !this.editperiodname
        ? "Please fill in the required fields"
        : "";

      for (let i in this.Editcaldata) {
        this.Editcaldata[i].itemError = !this.Editcaldata[i].item
          ? "Please fill in the required fields"
          : "";

        this.Editcaldata[i].startDateError = !this.Editcaldata[i].startdate
          ? "Please fill in the required fields"
          : "";
        this.Editcaldata[i].endDateError = !this.Editcaldata[i].enddate
          ? "Please fill in the required fields"
          : "";
      }
    },
    /* Add Pay Roll Calculation Period */
    AddPayRollPeriod() {
      let self = this;

      let Validate = self.Validate();
      let ValiDAte = self.ValidationInAdd();
      let endval = self.EndDateValidationInAdd();
      self.ValidateError();
      if (Validate == true) {
        if (ValiDAte == true && endval == true) {
          let PeriodItems = self.PeriodItemsList();
          let tempdept = {
            period_Name: self.periodname,
            company_ID: store.state.companyID,
            itemData: PeriodItems,
          };
          self.LoadingDialog = true;
          axios
            .post(`${self.url}PayrollPeriod/AddPayrollPeriod`, tempdept)
            .then(function (response) {
              if (response.data.status == 0) {
                alert(response.data.message);
                self.periodname = null;
                //self.caldata=[];
                self.addDialog = false;
                self.LoadingDialog = false;
                for (var i = 0; i < self.caldata.length; i++) {
                  self.caldata[i].startdate = "";
                  self.caldata[i].enddate = "";
                  self.caldata[i].item = "";
                  self.caldata[i].counter = false;
                  if (i >= 1) {
                    self.caldata.splice(1, self.caldata.length);
                  }
                }
                self.sid = 1;
                self.dsid = 1;
                self.deid = 1;
                self.GetPayRollCalculationPeriod();
                self.LogTrace(null, "Add PayRollPeriodCalculaton", 42, "Low");
              }
            })
            .catch(function (error) {
              self.LogTrace(
                error,
                "Add PayRollPeriodCalculaton Fail",
                42,
                "Low"
              );
              alert(error + "Correlation ID is 42");
              self.LoadingDialog = false;
            });
        } else {
          self.LoadingDialog = false;
        }
      } else {
        self.LoadingDialog = false;
      }
    },
    /*Add list of Payroll period items */
    PeriodItemsList() {
      let self = this;
      let temparr = [
        {
          item: "",
          start_Date: null,
          end_Date: null,
          company_ID: null,
        },
      ];

      for (let i = 0; i < self.caldata.length; i++) {
        if (i == 0) {
          temparr[i].item = self.caldata[i].item;
          temparr[i].start_Date = self.caldata[i].startdate;
          temparr[i].end_Date = self.caldata[i].enddate;
          temparr[i].company_ID = store.state.companyID;
        } else {
          temparr.push({
            item: self.caldata[i].item,
            start_Date: self.caldata[i].startdate,
            end_Date: self.caldata[i].enddate,
            company_ID: store.state.companyID,
          });
        }
      }
      return temparr;
    },
    /* Get PayRoll Calculation Period By CompanyID*/
    GetPayRollCalculationPeriod() {
      let self = this;
      self.LoadingDialog = true;
      let temp = { company_ID: store.state.companyID };
      axios
        .post(
          `${self.url}PayrollCalculationPeriod/GetPayrollCalculationPeriodByCompanyID`,
          temp
        )
        .then(function (response) {
          self.PayPeriodName = response.data.data;
          self.LoadingDialog = false;
          self.LogTrace(null, "Get PayrollCalculationPeriod", 42, "Critical");
        })
        .catch(function (error) {
          self.LogTrace(
            error,
            "Get PayrollCalculationPeriod Fail",
            42,
            "Critical"
          );
          //alert(error + "Correlation ID is 42");
          self.LoadingDialog = false;
        });
    },
    /* Format date dd/mn/yyyy */
    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },
    /* Add Items  */
    addItem() {
      let self = this;
      let plusid = self.sid++;
      let startid = self.dsid++;
      let endid = self.deid++;
      self.caldata.push({
        id: plusid,
        startdateid: startid,
        endateid: endid,
        item: "",
        startdate: "",
        enddate: "",
        counter: true,
        errorPeriodName: "",
        itemError: "",
        startDateError: "",
        endDateError: "",
      });
      for (let i = 0; i < self.caldata.length; i++) {
        if (i >= 1) {
          self.caldata[i - 1].counter = false;
        }
      }
    },
    /* Add Items  in Edit*/
    EditaddItem() {
      let self = this;
      let plusid = self.sidd++;
      let startid = self.dsidd++;
      let endid = self.deidd++;
      self.Editcaldata.push({
        id: plusid,
        startdateid: startid,
        endateid: endid,
        item: "",
        startdate: "",
        enddate: "",
        counter: true,
        itemError: "",
        startDateError: "",
        endDateError: "",
      });
      for (let i = 0; i < self.Editcaldata.length; i++) {
        if (i > 1) {
          self.Editcaldata[i - 1].counter = false;
        }
      }
    },
    /* Delete Old Data of Period Items */
    DeleteoldData() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.payroll_Period_ID;
      let tempdel = {
        payroll_Period_ID: id,
      };
      axios
        .post(`${self.url}PayrollPeriod/DeleteOldPayRollPeriod`, tempdel)
        .then(function (response) {
          if (response.data.status == 0) {
            self.LoadingDialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    //Get ID
    Deletedia(id) {
      this.payroll_Period_ID = id;
      this.deletedialog = true;
    },
    //Delete Pay roll Calculation Period
    DeleteBank() {
      let self = this;
      self.LoadingDialog = true;
      let id = self.payroll_Period_ID;
      let temp = {
        payroll_Period_ID: id,
      };
      axios
        .post(`${self.url}PayrollPeriod/DeletePayRollCalPeriod`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            alert(response.data.message);
            self.deletedialog = false;
            self.LoadingDialog = false;
            self.GetPayRollCalculationPeriod();
            self.LogTrace(
              null,
              "Delete PayRoll Calculation Period",
              42,
              "High"
            );
          }
        })
        .catch(function (error) {
          self.LogTrace(
            error,
            "Delete PayRoll Calculation Period Fail",
            42,
            "High"
          );
          alert(error + "Correlation ID is 42");
          self.LoadingDialog = false;
        });
    },
    //Remove Item IN Add dialog
    removeiteminAdd() {
      let self = this;
      self.caldata.splice(self.caldata.length - 1, 1);
      self.caldata[self.caldata.length - 1].counter = true;
      for (var i = 0; i < self.caldata.length; i++) {
        if (self.caldata.length == 1) {
          self.caldata[i].counter = false;
        }
      }
      self.sid = self.sid - 1;
      self.dsid = self.dsid - 1;
      self.deid = self.deid - 1;
    },
    //Remove Item In Edit dialog
    removeiteminEdit() {
      let self = this;
      self.Editcaldata.splice(self.Editcaldata.length - 1, 1);
      self.Editcaldata[self.Editcaldata.length - 1].counter = true;
      for (var i = 0; i < self.Editcaldata.length; i++) {
        if (self.Editcaldata.length == 1) {
          self.Editcaldata[i].counter = false;
        }
      }
      self.sidd = self.sidd - 1;
      self.dsidd = self.dsidd - 1;
      self.deidd = self.deidd - 1;
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .v-data-table tr td {
  height: 70px;
  text-align: center;
  width: 800px;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 16px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
@media screen and (max-width: 320px) {
  .fixed {
    margin-top: 1.3rem;
  }
  .fixed-btn {
    width: 90px !important;
  }
  .fixed-title {
    margin-right: 1rem;
  }
}

@media (min-width: 1280px) and (min-height: 800px) {
  .buttonAlign {
    margin-left: 10px;
  }
}

@media (max-width: 1050px) and (max-height: 1440px) {
  .buttonAlign {
    margin-left: 10px;
  }
}

@media (min-width: 1000px) and (min-height: 600px) {
  .buttonAlign {
    margin-left: 10px;
  }
}
</style>
