<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <p style="font-size: 25px; color: #43425d; margin-top: 15px">
        {{ $t("EditUser") }}
      </p>
    </div>
    <v-card class="mt-6">
      <v-card-subtitle
        class="mt-3 ml-2"
        style="font-size: 20px; font-weight: 300; color: black"
      >
        {{ $t("UserDetails") }}</v-card-subtitle
      >
      <div>
        <v-form ref="formEdit" lazy-validation>
          <v-row class="ml-1 mr-4" wrap>
            <v-col cols="12" sm="6" md="3" class="ml-4 mr-4">
              <v-text-field
                readonly
                autocomplete="off"
                color="#F99D20"
                v-model="edituser.employeename"
                ><template v-slot:label>
                  <span>
                    {{ $t("EmployeeName")
                    }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
          </v-row>
          <v-row class="ml-1 mr-4" wrap>
            <v-col cols="12" sm="6" md="3" class="ml-4 mr-4">
              <v-text-field
                autocomplete="off"
                color="#F99D20"
                v-model="edituser.usergroup"
                readonly
                ><template v-slot:label>
                  <span> {{ $t("UserGroup") }}</span>
                </template></v-text-field
              >
            </v-col>
            <v-col class="mt-3">
              <v-btn
                class="text-capitalize accountbtn"
                router
                :to="{
                  name: 'usereditgp',
                  params: {
                    uid: `${this.$route.params.uid}`,
                    usergroup: 'usergroup',
                  },
                }"
                >{{ $t("edit") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="ml-1 mr-4" wrap>
            <v-col cols="12" sm="6" md="3" class="ml-4 mr-4">
              <v-text-field
                autocomplete="off"
                color="#F99D20"
                :rules="userroleEdit"
                v-model="edituser.userrole"
                readonly
                ><template v-slot:label>
                  <span>
                    {{ $t("Role") }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
            <v-col class="mt-3">
              <v-btn
                class="text-capitalize accountbtn"
                router
                :to="{
                  name: 'usereditrole',
                  params: { uid: `${this.$route.params.uid}`, role: 'role' },
                }"
                >{{ $t("edit") }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="ml-1 mr-4" wrap>
            <v-col cols="12" sm="6" md="3" class="ml-4 mr-4">
              <v-text-field
                autocomplete="off"
                :rules="useridEdit"
                color="#F99D20"
                v-model="edituser.userid"
                ><template v-slot:label>
                  <span>
                    {{ $t("username") }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
            <v-col cols="12" sm="6" md="3" class="ml-3 mr-4">
              <v-text-field
                autocomplete="off"
                v-show="!showbtn"
                :type="show ? 'text' : 'password'"
                v-on:keyup="keymonitor"
                color="#F99D20"
                :rules="passwordEdit"
                v-model.lazy="edituser.password"
                ><template v-slot:label>
                  <span>
                    {{ $t("password") }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
              <v-text-field
                autocomplete="off"
                v-show="showbtn"
                v-on:keyup="keymonitor"
                color="#F99D20"
                :rules="passwordEdit"
                v-model.lazy="edituser.password"
                ><template v-slot:label>
                  <span>
                    {{ $t("password") }}<span class="red--text"> *</span></span
                  >
                </template></v-text-field
              >
            </v-col>
            <v-col cols="12" sm="6" md="3" class="ml-1 mr-4">
              <v-btn
                :disabled="lockdisable"
                class="mt-3"
                icon
                @click="showlock = !showlock"
              >
                <img :src="showlock ? lockimage.lock : lockimage.unlock" />
              </v-btn>
            </v-col>
          </v-row>

          <div>
            <v-row class="mt-3">
              <v-col cols="12" sm="6" md="4" align="right">
                <v-btn
                  color="#444444"
                  class="text-capitalize mr-2"
                  style="font-weight: normal"
                  text
                >
                  {{ $t("Advance") }} &nbsp;
                  <img width="30" height="30" :src="Advanceimage.up" alt />
                </v-btn>
              </v-col>
            </v-row>
            <div v-show="arrow">
              <v-row class="ml-1 mr-4" wrap>
                <v-col cols="12" sm="6" md="4" class="ml-4 mr-4">
                  <v-text-field
                    autocomplete="off"
                    :label="$t('Permission')"
                    color="#F99D20"
                    v-model="edituser.userpermis"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col class="mt-3">
                  <v-btn
                    router
                    :to="{
                      name: 'usereditper',
                      params: {
                        uid: `${this.$route.params.uid}`,
                        permission: 'permission',
                      },
                    }"
                    class="text-capitalize accountbtn"
                    >{{ $t("edit") }}</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </div>
        </v-form>
      </div>

      <v-card-actions class="pb-6 mr-5">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          class="mr-7 text-capitalize commoncancelbtn"
          @click="goto"
          text
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          class="text-capitalize commonsavebtn"
          @click="UpdateEmpInfo"
          >{{ $t("save") }}</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- loadingdialog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>

  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, utils],
  data() {
    return {
      url: enurl.apiUrl,
      size: 10,
      userroleEdit: [],
      useridEdit: [],
      passwordEdit: [],
      arrow: true,
      showlock: false,
      showbtn: true,
      loadingdialog: false,
      characters: "a-z,A-Z,0-9",
      edituser: {
        employeename: null,
        usergroup: [],
        userrole: [],
        userid: null,
        password: null,
        userpermis: [],
      },
      lockdisable: true,
      user_ID: 0,
      emp: [],
      getusergp: [],
      TempGpList: [],
      updateusergp: [],
      updaterole: [],
      updateper: [],
      show: false,
      Advanceimage: {
        up: require("@/assets/images/up1@2x.png"),
        down: require("@/assets/images/down1@2x.png"),
      },
      lockimage: {
        lock: require("@/assets/images/padlock.png"),
        unlock: require("@/assets/images/padunlock.png"),
      },
      tempuser: null,
      temprole: null,
      tempper: null,
      tempusergpname: [],
      tempuserrole: [],
      tempuserper: [],
      UserNameList: [],
      rolelist: [],
    };
  },
  watch: {
    "edituser.userid"() {
      this.useridEdit = [];
    },
    "edituser.password"() {
      this.passwordEdit = [];
    },
    "edituser.userrole"() {
      this.userroleEdit = [];
    },
  },
  mounted() {
    let self = this;
    self.GetEmpByID();
    self.GetUserNameByCompany();
    self.GetRoleByUserGroup();
  },

  methods: {
    GetRoleByUserGroup() {
      let self = this;
      let ugpId = JSON.parse(localStorage.getItem("UserEditUserGp"));
      if (ugpId != null && ugpId != undefined) {
        let tempp = {
          company_ID: store.state.companyID,
          user_Group_ID: ugpId[0].user_Group_ID,
        };
        axios
          .post(`${self.url}UserGroupRole/GetUserGroupRole`, tempp)
          .then(function (response) {
            self.rolelist = response.data.data;
            localStorage.setItem("TempUserGroup", null);
          });
      } else {
        let ugpIds = localStorage.getItem("TempUserGroup");
        if (ugpIds != null && ugpIds != "") {
          let temp = {
            company_ID: store.state.companyID,
            user_Group_ID: parseInt(ugpIds),
          };
          axios
            .post(`${self.url}UserGroupRole/GetUserGroupRole`, temp)
            .then(function (response) {
              self.rolelist = response.data.data;
            });
        }
      }
    },
    ValidateUserGroupRole() {
      let self = this;
      let RoleId = JSON.parse(localStorage.getItem("UserEditRole"));
      let RoleIds = localStorage.getItem("TempRole");
      if (
        self.updateusergp.length != 0 &&
        self.updaterole.length != 0 &&
        RoleId != null
      ) {
        if (self.rolelist[0].role_ID == RoleId[0].role_ID) {
          return true;
        } else {
          return false;
        }
      } else if (
        self.updateusergp.length != 0 &&
        self.updaterole.length != 0 &&
        RoleIds != ""
      ) {
        if (self.rolelist[0].role_ID == parseInt(RoleIds)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    keymonitor: function (event) {
      if (event.key == "Backspace") {
        this.edituser.password = null;
        this.show = true;
      }
    },
    GetEmpByID() {
      let self = this;
      self.loadingdialog = true;

      let tempp = {
        employee_ID: parseInt(self.$route.params.uid),
        company_ID: store.state.companyID,
      };

      axios
        .post(`${self.url}Confirmemployee/GetConfirmEmployeeInfo`, tempp)
        .then(function (response) {
          self.emp = response.data.data;

          if (self.emp != null) {
            self.edituser.employeename = response.data.data.employee_Name;
            self.user_ID = response.data.data.user_ID;
            let lckshow = response.data.data.is_Locked;
            if (lckshow == true) {
              self.showlock = lckshow;
              self.lockdisable = false;
            } else {
              self.showlock = lckshow;
              self.lockdisable = true;
            }

            //CombinewithLocalstore
            //UserGroup
            self.edituser.usergroup = response.data.data.empUserGroup.map(
              (x) => x.user_Group_Name
            );
            self.tempuser = JSON.parse(localStorage.getItem("UserEditUserGp"));
            if (self.tempuser != null) {
              self.edituser.usergroup = [
                // ...self.edituser.usergroup,
                ...self.tempuser.map((x) => x.user_Group_Name),
              ];

              self.tempusergpname = [
                ...self.tempuser.map((x) => x.user_Group_ID),
              ];

              self.edituser.usergroup = [...new Set(self.edituser.usergroup)];

              if (self.tempusergpname != null) {
                for (let i = 0; i < self.tempusergpname.length; i++) {
                  self.updateusergp.push({
                    user_Group_ID: self.tempusergpname[i],
                  });
                }
              }
            } else {
              let tempuid = response.data.data.empUserGroup.map(
                (x) => x.user_Group_ID
              );
              for (let x = 0; x < tempuid.length; x++) {
                self.updateusergp.push({
                  user_Group_ID: tempuid[x],
                });
              }
              // console.log(self.updateusergp);
            }

            //CombinewithLocalstore
            //UserRole

            self.edituser.userrole = response.data.data.empRoleData.map(
              (x) => x.role_Name
            );
            self.temprole = JSON.parse(localStorage.getItem("UserEditRole"));
            if (self.temprole != null) {
              self.edituser.userrole = [
                ...self.temprole.map((x) => x.role_Name),
              ];
              self.tempuserrole = [...self.temprole.map((x) => x.role_ID)];

              self.edituser.userrole = [...new Set(self.edituser.userrole)];
              if (self.tempuserrole != null) {
                for (let j = 0; j < self.tempuserrole.length; j++) {
                  self.updaterole.push({
                    role_ID: self.tempuserrole[j],
                  });
                  //console.log(self.updaterole)
                }
              }
            } else {
              let temprid = response.data.data.empRoleData.map(
                (x) => x.role_ID
              );
              for (let x = 0; x < temprid.length; x++) {
                self.updaterole.push({
                  role_ID: temprid[x],
                });
              }
              //console.log(self.updaterole);
            }

            //CombinewithLocalstore
            //UserPermission
            self.edituser.userpermis = response.data.data.empPermisData.map(
              (x) => x.permission_Name
            );
            self.tempper = JSON.parse(localStorage.getItem("UserEditPer"));
            if (self.tempper != null) {
              self.edituser.userpermis = [
                ...self.tempper.map((x) => x.pername),
              ];
              self.edituser.userpermis = [...new Set(self.edituser.userpermis)];
              self.tempuserper = [...self.tempper.map((x) => x.perid)];

              if (self.tempuserper != null) {
                for (let j = 0; j < self.tempuserper.length; j++) {
                  self.updateper.push({
                    permission_ID: self.tempuserper[j],
                  });
                  //console.log(self.updaterole)
                }
              }
            } else {
              let tempr = response.data.data.empPermisData.map(
                (x) => x.permission_ID
              );
              for (let x = 0; x < tempr.length; x++) {
                self.updateper.push({
                  permission_ID: tempr[x],
                });
              }
              //console.log(self.updateper);
            }

            //UserName //Password
            if (response.data.data.user_Name != null) {
              self.edituser.userid = response.data.data.user_Name;
            } else {
              self.edituser.userid = self.generateUserID();
            }
            if (response.data.data.password != null) {
              self.edituser.password = response.data.data.password;
              self.showbtn = false;
            } else {
              self.edituser.password = self.generate();
              self.showbtn = true;
            }
            self.loadingdialog = false;
          }
          self.loadingdialog = false;
        });
    },
    goto() {
      localStorage.setItem("UserEditUserGp", JSON.stringify(null));
      localStorage.setItem("UserEditRole", JSON.stringify(null));
      localStorage.setItem("UserEditPer", JSON.stringify(null));
      this.$router.push("/usermanagement/user").catch(()=>{});
    },
    generate() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let tempPassword = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      if (charactersArray.indexOf("0-9") >= 0) {
        CharacterSet += "0123456789";
      }
      if (charactersArray.indexOf("#") >= 0) {
        CharacterSet += "![]{}()%&*$#^<>~@|";
      }

      for (let i = 0; i < this.size; i++) {
        tempPassword += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return tempPassword;
    },
    generateUserID() {
      let charactersArray = this.characters.split(",");
      let CharacterSet = "";
      let tempPassword = "";

      if (charactersArray.indexOf("a-z") >= 0) {
        CharacterSet += "abcdefghijklmnopqrstuvwxyz";
      }
      if (charactersArray.indexOf("A-Z") >= 0) {
        CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      }
      for (let i = 0; i < this.size; i++) {
        tempPassword += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      return tempPassword;
    },
    DeleteoldUserGP() {
      let self = this;
      self.loadingdialog = true;
      let id = parseInt(self.$route.params.uid);
      let tempdel = {
        employee_ID: id,
      };
      axios
        .post(`${self.url}Confirmemployee/DeleteOldData`, tempdel)
        .then(function (response) {
          if (response.data.status == 0) {
            self.loadingdialog = false;
          }
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    Validation() {
      let self = this;
      if (
        self.edituser.employeename != null &&
        self.edituser.usergroup != null &&
        self.edituser.userrole != null &&
        self.edituser.userid != null &&
        self.edituser.password != null &&
        self.edituser.userpermis != null &&
        self.edituser.employeename != "" &&
        self.edituser.usergroup != "" &&
        self.edituser.userrole != "" &&
        self.edituser.userid != "" &&
        self.edituser.password != "" &&
        self.edituser.userpermis != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    ValidateAccount() {
      let self = this;
      let result;
      let FilterUserNameList = self.UserNameList.filter(
        (person) => person.userId != self.user_ID
      );
      for (let i = 0; i < FilterUserNameList.length; i++) {
        if (
          FilterUserNameList[i].userName.toLowerCase() ==
          self.edituser.userid.toLowerCase()
        ) {
          alert(
            "This username is already taken by another user. Please use a different username."
          );
          result = false;
          return false;
        }
      }
      if (result != false) {
        return true;
      }
    },
    GetUserNameByCompany() {
      let self = this;
      let tempp = {
        companyId: store.state.companyID,
      };

      axios
        .post(`${self.url}UserInfo/GetUserbyCompanyId`, tempp)
        .then(function (response) {
          self.UserNameList = response.data.data;
        });
    },
    UpdateEmpInfo() {
      let self = this;
      self.loadingdialog = true;
      let IsExistedUserName = self.ValidateAccount();
      let CheckMatch = self.ValidateUserGroupRole();
      self.userroleEdit = [(v) => !!v || "Role is required"];
      self.useridEdit = [(v) => !!v || "Username is required"];
      self.passwordEdit = [(v) => !!v || "Password is required"];
      let tempvalidate = self.$refs.formEdit.validate();
      if (
        tempvalidate == true &&
        self.edituser.userrole != "" &&
        self.edituser.userrole != null &&
        self.edituser.userid != "" &&
        self.edituser.userid != null &&
        self.edituser.password != "" &&
        self.edituser.password != null
      ) {
        if (IsExistedUserName == true) {
          if (CheckMatch == true) {
            self.DeleteoldUserGP();
            let temp = {
              employee_ID: parseInt(self.$route.params.uid),
              user_ID: self.user_ID,
              company_ID: store.state.companyID,
              user_Name: self.edituser.userid,
              password: self.edituser.password,
              is_Locked: self.showlock,
              empUserGroup: self.updateusergp,
              empRoleData: self.updaterole,
              empPermisData: self.updateper,
            };

            axios
              .post(`${self.url}Confirmemployee/EditConfirmEmployee`, temp)
              .then(function (response) {
                if (response.data.status == 0) {
                  alert("Update Successfully");
                  self.loadingdialog = false;
                  localStorage.setItem("UserEditUserGp", JSON.stringify(null));
                  localStorage.setItem("UserEditRole", JSON.stringify(null));
                  localStorage.setItem("UserEditPer", JSON.stringify(null));
                  localStorage.setItem("TempRole", null);
                  localStorage.setItem("TempUserGroup", null);
                  self.$router.push("/usermanagement/user").catch(()=>{});
                  self.LogTrace(null, "Update User", 34, "Medium");
                }
              })
              .catch(function (error) {
                // alert(error);
                self.LogTrace(error, "Update User Fail", 34, "Medium");
                self.loadingdialog = false;
              });
          } else {
            alert("Selected UserGroup and Role doesn't match.Please recheck");
            self.loadingdialog = false;
          }
        } else {
          self.loadingdialog = false;
        }
      } else {
        self.loadingdialog = false;
        if (self.edituser.userrole == "" || self.edituser.userrole == null) {
          alert("Please select Role");
        } else {
          alert("please fill all the fields");
        }
      }
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>