<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <p style="font-size: 25px; color: #444444; margin-top: 15px">
        {{ $t("GroupMember") }}
      </p>
    </div>
    <v-card class="mt-6">
      <div>
        <v-row
          class="pl-md-0 pt-9"
          style="margin-left: 15px; margin-right: 10px"
        >
          <v-col cols="12" xs="12" sm="6" md="3" lg="4">
            <v-text-field
              autocomplete="off"
              v-model.lazy="searchtxt"
              color="#F99D20"
              dense
              :placeholder="$t('Search')"
              style="margin-top: -0.3rem; margin-left: 1rem"
            ></v-text-field>
          </v-col>
          <v-btn
            color="#F99D20"
            @click="Search()"
            class="white--text text-capitalize ml-6 mb-2"
            width="120"
            max-height="35"
          >
            <v-icon class="pr-1">mdi-magnify</v-icon>{{ $t("Search") }}
          </v-btn>
        </v-row>
        <!-- <v-data-table
          v-model="selected"
          style="margin-left:40px; margin-right:40px;"
          :headers="headers"
          :search="searchtxt"
          :items="PersonalList"
          :single-select="singleSelect"
          item-key="employee_ID"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          show-select
          @page-count="pageCount = $event"
        >-->
        <v-data-table
          style="margin-left: 40px; margin-right: 40px"
          :items="PersonalList"
          :headers="headers"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          hide-default-header
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th>
                  <!-- <label class="form-checkbox">
                    <input
                      type="checkbox"
                      v-model="selectAll"
                      @click="select"
                    />
                    <i class="form-icon"></i>
                  </label> -->
                  <v-checkbox class="custom-checkbox mt-0"
                    v-model="selectAll"
                    @click="select(selectAll)" 
                    color="#F89D1F" hide-details>
                  </v-checkbox>
                </th>
                <th>{{ $t("EmployeeID") }}</th>
                <th>{{ $t("EmployeeName") }}</th>
                <th>{{ $t("position") }}</th>
                <th>{{ $t("department") }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>
                <!-- <label class="form-checkbox">
                  <input
                    type="checkbox"
                    :value="
                      item.employee_ID != null
                        ? { empid: item.employee_ID, empname: item.fullName }
                        : { empid: empid, empname: empname }
                    "
                    v-model="selected"
                  />
                  <i class="form-icon"></i>
                </label> -->
                <v-checkbox class="custom-checkbox mt-0" 
                    v-model="item.checked"
                    @change="checkmyemp()" 
                    color="#F89D1F" hide-details>
                </v-checkbox>
              </td>
              <td>{{ item.employee_Code }}</td>
              <td>{{ item.fullName }}</td>
              <td>{{ item.position_Name }}</td>
              <td>{{ item.department_Name }}</td>
            </tr>
          </template>
        </v-data-table>

        <div class="text-right">
          <v-row justify="end" class="ml-5 mt-5 mr-5">
            <v-col cols="12" md="3">
              <v-pagination
                v-model="page"
                :length="pageCount"
                color="#FFCD2C"
                :total-visible="6"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="2" class="mt-1">
              <v-select
                dense
                style="width: 120px; float: right"
                solo
                label="10/page"
                v-model="itemsPerPage"
                :items="items"
                @input="itemsPerPage = parseInt($event, 10)"
              ></v-select>
            </v-col>
          </v-row>
        </div>
      </div>
      <v-card-actions class="pb-6 mr-5">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          class="mr-2 text-capitalize commoncancelbtn"
          @click="goback"
          text
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          class="mr-4 text-capitalize commonsavebtn"
          @click="goto"
          >{{ $t("Add") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- Loadingdialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>

  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [utils],
  data() {
    return {
      url: enurl.apiUrl,
      page: 1,
      pageCount: 15,
      itemsPerPage: 10,
      items: [10, 20, 30],
      searchtxt: "",
      LoadingDialog: false,
      selected: [],
      selectAll: false,
      PersonalList: [],
      PersonalList1: [],
      UsergroupMemList: [],
      UserRoleList: [],
      tempuser: [],
      afterclck: 0,
      headers: [
        {
          value: "employee_Code",
          align: "left",
          sortable: false,
        },
        {
          value: "fullName",
          align: "left",
          sortable: false,
        },
        {
          value: "position_Name",
          align: "left",
          sortable: false,
        },
        { value: "department_Name", align: "left", sortable: false },
      ],
    };
  },
  mounted() {
    this.GetPersonal();
    this.GetGroupMember();
    this.GetRoleOfMember();
  },
  watch: {
    searchtxt: function () {
      if(this.afterclck == 1){
        this.GetPersonal();
      }
    },
    "selectAll"(){
      if(this.PersonalList1.length == this.selected.length){
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    }
  },
  methods: {
    goto() {
      let self = this;
      let namearr = [];
      let rolelist = [];
      let condi = false;
      for (let i = 0; i < self.selected.length; i++) {
        let regular = self.UsergroupMemList.filter(
          (val) => val.employee_ID == self.selected[i].empid
        );
        if (regular.length != 0) {
          condi = true;
          namearr.push(self.selected[i].empname);
        }
        for (let j = 0; j < self.UserRoleList.length; j++) {
          if (self.UserRoleList[j].employee_ID == self.selected[i].empid) {
            rolelist.push(self.UserRoleList[j].role_ID);
          }
        }
      }
      if (condi == true) {
        if (namearr.length > 3) {
          alert("Some already have group!");
        } else {
          alert(namearr + " already has group!");
        }
      } else {
        let cheekycheese = rolelist.filter((data, index) => {
          return rolelist.indexOf(data) === index;
        });
        if (cheekycheese.length == 1 || cheekycheese.length == 0) {
          if (cheekycheese.length == 0) {
            localStorage.setItem("check_role_mem", 0);
          } else {
            localStorage.setItem("check_role_mem", cheekycheese);
          }
          this.SaveLocal();
          this.$router.push({
            name: "usergroupadd",
            params: { usergroupadd: "usergroupadd" },
          }).catch(()=>{});
        } else {
          alert("Different role can't add to group!");
        }
      }
    },
    goback() {
      localStorage.setItem("localmember", JSON.stringify(null));
      this.$router.push({
        name: "usergroupadd",
        params: { usergroupadd: "usergroupadd" },
      }).catch(()=>{});
    },
    GetPersonal() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}PersonalInfo/GetPersonalInfoByCompanyID`, tempp)
        .then(function (response) {
          let llist = response.data.data;
          self.tempuser = JSON.parse(localStorage.getItem("localmember"));
          if (self.tempuser != null) {
            self.PersonalList = llist.map((v) => ({
              ...v,
              checked: self.tempuser.some((user) => user.empid === v.employee_ID),
            }));
            self.selected = 
            self.PersonalList
            .filter(user => user.checked)
            .map((v) => {
              return {
                empid: v.employee_ID,
                empname: v.fullName
              };
            });
            self.SaveLocal();
          } else {
            self.PersonalList = llist.map((v) => ({
              ...v,
              checked: false,
            }));
          }
          if(self.selected.length != 0){
            self.PersonalList = llist.map((v) => ({
              ...v,
              checked: self.selected.some((user) => user.empid === v.employee_ID),
            }));
          }
          self.PersonalList1 = self.PersonalList;
          self.afterclck = 0;
        });
    },
    GetGroupMember() {
      let self = this;
      self.LoadingDialog = true;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}UserGroupMember/GetAllUserGroupMember`, tempp)
        .then(function (response) {
          self.UsergroupMemList = response.data.data;
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    GetRoleOfMember() {
      let self = this;
      let tempp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}UserGroupMember/GetUserRoleByCompanyID`, tempp)
        .then(function (response) {
          self.UserRoleList = response.data.data;
        })
        .catch(function (error) {
          alert(error);
        });
    },
    select(value) {
      // this.selected = [];
      // if (this.selectAll) {
      //   for (let i in this.PersonalList) {
      //     this.selected.push({
      //       empid: this.PersonalList[i].employee_ID,
      //       empname: this.PersonalList[i].fullName,
      //     });
      //   }
      // }
      this.PersonalList = this.PersonalList.map((v) => ({
        ...v,
        checked: value,
      }));
      this.selected = 
      this.PersonalList
      .filter(user => user.checked)
      .map((v) => {
        return {
          empid: v.employee_ID,
          empname: v.fullName
        };
      });

    },
    checkmyemp() {
      let checkvalidate = this.PersonalList.filter((data) => data.checked == true);

      let checkfalsevalidate = this.PersonalList.filter((date) => date.checked == false);
      if (checkfalsevalidate.length != 0) {
        this.selectAll = false;
      }

      if (checkvalidate.length != 0) {
        if (checkvalidate.length == this.PersonalList.length) {
          this.selectAll = true;
        }
      }
      this.selected = 
      this.PersonalList
      .filter(user => user.checked)
      .map((v) => {
        return {
          empid: v.employee_ID,
          empname: v.fullName
        };
      });
    },
    SaveLocal() {
      localStorage.setItem("localmember", JSON.stringify(this.selected));
    },
    Search() {
      let self = this;
      if (self.searchtxt != "" && self.searchtxt != undefined) {
        self.afterclck = 1;
        let temp = self.PersonalList1.filter(
          (v) =>
            v.fullName.toLowerCase().indexOf(self.searchtxt.toLowerCase()) >
              -1 ||
            v.position_Name
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1 ||
            v.department_Name
              .toLowerCase()
              .indexOf(self.searchtxt.toLowerCase()) > -1 ||
            v.employee_Code.toString().indexOf(self.searchtxt) > -1
        );
        let List = self.PersonalList;
        self.PersonalList = [];
        let empListMap = new Map();
        self.PersonalList = temp;
        temp.forEach(item => {
          empListMap.set(item.employee_ID, item);
        });
        List.forEach(item => {
          if (item.checked) {
            empListMap.set(item.employee_ID, item);
          }
        });
        self.PersonalList = Array.from(empListMap.values());
      } else {
        alert("Please enter anything!!");
        //self.GetPersonal();
      }
    },
  },
};
</script>
<style scoped>
>>> .theme--light.v-pagination .v-pagination__item {
  background: #fff !important;
  color: rgba(0, 0, 0, 0.87);
}
>>> .theme--light.v-pagination .v-pagination__item--active {
  background: #f99d20 !important;
  color: white !important;
}
>>> .v-data-table__mobile-row__header {
  font-weight: unset !important;
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20 !important;
}
>>> .theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-size: 17px;
  font-weight: 300;
}
>>> .theme--light.v-data-table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border: unset;
}
>>> tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>