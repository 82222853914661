<template>
  <div>
    <v-row class="ml-5 pt-3 mr-5">
      <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="mt-8">
        <v-text-field prepend-inner-icon=" search" v-model="searchtxt" autocomplete="off"
          :placeholder="$t('SearchEmployee')" outlined color="#F99D20" dense></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="mt-2" v-if="$vuetify.breakpoint.mdAndUp">
        <p class="mb-0" style="color: #a3a7aa">{{ $t("startdate") }}</p>
        <date-picker v-bind:clearable="false" v-model="reportDateFrom" format="DD/MM/YYYY" placeholder="DD/MM/YYYY"
          color="#F99D20" width="100%" class="custom-vuedatepicker1" readonly @change="Getbydatelist()"
          :disabled-date="(date) => date > new Date(reportDateTo)">
          <!-- <template slot="icon-calendar">
            <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
          </template> -->
        </date-picker>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="mt-2" v-if="$vuetify.breakpoint.mdAndUp">
        <p class="mb-0" style="color: #a3a7aa">{{ $t("enddate") }}</p>
        <date-picker v-bind:clearable="false" v-model="reportDateTo" format="DD/MM/YYYY" placeholder="DD/MM/YYYY" class="custom-vuedatepicker1"
          color="#F99D20" width="100%" readonly @change="Getbydatelist()"
          :disabled-date="(date) => date < new Date(reportDateFrom)">
          <!-- <template slot="icon-calendar">
            <img src="@/assets/images/calendaricon.png" style="width: 20px; height: 20px" />
          </template> -->
        </date-picker>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2" xl="1" class="mt-8">
        <v-autocomplete v-model="itemInner" :placeholder="$t('status')" :items="status" item-text="txt" color="#F99D20"
          item-value="val" outlined dense>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" md="4" lg="2" xl="1" class="mt-8">
        <v-menu ref="positionMenu" v-model="positionMenu" offset-y :close-on-content-click="false"
          @focus="$event.target.click()">
          <template v-slot:activator="{ on }">
            <v-btn text block @click="togglePositionMenu" v-on="on" outlined color="#707070" height="40px"
              style="border-color:#A3A7AA">
              <v-icon left class="v-icon mdi mdi-filter-variant theme--light" />
              {{ $t("Filter") }}
              <v-icon>{{
                positionMenu ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </template>

          <v-list @focus="$event.target.click()">
            <v-list-item class="tile" v-for="(position, index) in positions" :key="index"
              :style="{ backgroundColor: position.color }" @focus="$event.target.click()">
              <v-list-item-title @click="handlePositionSelect(position)" @focus="$event.target.click()"
                style="cursor: pointer">{{ position.text }}</v-list-item-title>
              <v-list-item-icon>
                <v-icon v-if="selectedPosition &&
                  selectedPosition.value === position.value
                  ">mdi-check</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12" xs="6" sm="2" md="2" lg="2" xl="2" class="mt-8 ml-3">
        <v-btn @click="Openexport()" icon>
          <img width="35" height="35" :src="images.excel" />
        </v-btn>
      </v-col>

      <v-menu ref="subMenu" v-if="subMenuVisible" v-model="subMenu" :close-on-content-click="false" offset-y
        :position-x="subMenuX" :position-y="subMenuY" absolute @focus="$event.target.click()">
        <v-card max-height="400px">
          <v-text-field v-model="searchInput" :label="$t('Search')" class="pa-3" color="#F99D20"
            @focus="$event.target.click()" @click="positionMenu = true"></v-text-field>
          <v-list style="margin-top: -1.5rem" @focus="$event.target.click()">
            <v-list-item style="border-radius: 5px" class="tile2 ml-1 mr-1"
              v-for="(subPosition, index) in filteredSubPositions" :key="index" @focus="$event.target.click()">
              <v-list-item-content @click="handleSubPositionSelect(subPosition)">
                <v-list-item-title style="cursor: pointer">
                  {{ subPosition.text }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon color="#F99D20" v-if="selectedSubPosition.includes(subPosition)">mdi-check</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-col cols="12" sm="6" md="4" lg="2" xl="1" class="mt-8">
        <v-btn color="#F99D20" class="white--text text-capitalize btnres text-right" max-height="35" style="width: 200px"
          @click="newReq()">{{ $t("newRequest") }}</v-btn>
      </v-col>
    </v-row>
    <div style="margin-left: 2.5rem; margin-right: 2.5rem; margin-top: 1rem">
      <v-data-table :items="requestlist" :page.sync="page" :headers="headers" hide-default-header
        @page-count="pageCount = $event" hide-default-footer :mobile-breakpoint="0"
        style="border-top: 1px solid #cfcdcd; border-bottom: 1px solid #cfcdcd">
        <template v-slot:header="{ props: {} }">
          <thead>
            <tr style="height: 60px">
              <th style="border-right: 1px solid #cfcdcd; text-align: center;width: 80px;">
                <div style="display: flex;align-items: center;justify-content: center;">
                  <v-checkbox color="#F89D1F" class="custom-checkbox" @change="checkall()" v-model="tablecheck"></v-checkbox>
                </div>
              </th>
              <th class="tdborder" style="text-align: center">
                {{ headers[1].text }}
              </th>
              <th class="txtcenter" style="text-align: center">
                {{ headers[2].text }}
              </th>
              <th style="text-align: center">{{ headers[3].text }}</th>
              <th style="text-align: center">{{ headers[4].text }}</th>
              <th style="text-align: center">{{ headers[6].text }}</th>
              <th style="text-align: center">{{ headers[7].text }}</th>
              <th style="text-align: center">{{ headers[8].text }}</th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td style="text-align: center; border-right: 1px solid #cfcdcd">
              <div style="display: flex;align-items: center;justify-content: center;">
                <v-checkbox color="#F89D1F" class="custom-checkbox" @change="checkmylist(item)" v-model="item.check"></v-checkbox>
              </div>
            </td>
            <td @click="detailrequest(item)" class="pt-4 tdborder" style="text-align: left">
              <p style="color: #444444; font-weight: 100; font-size: 15px">
                {{ item.employee_Code }} : {{ item.name }}
              </p>
              <p style="color: #8a8c8e">{{ item.positionName }}</p>
            </td>
            <td @click="detailrequest(item)" class="pt-4" style="text-align: center">
              <p v-if="item.oT_Hours != null">{{ item.oT_Hours }}</p>
              <p v-else>-</p>
            </td>
            <td @click="detailrequest(item)" class="pt-4" style="text-align: center">
              {{ formatDate(item.start_Date) }}
              <p>{{ item.oT_Start_Time }}</p>
            </td>
            <td @click="detailrequest(item)" class="pt-4" style="text-align: center">
              {{ formatDate(item.end_Date) }}
              <p>{{ item.oT_End_Time }}</p>
            </td>
            <td @click="detailrequest(item)" style="text-align: center">
              <p>
                {{ convertUTCDateToLocalDate(item.oT_Approved_Date) }}
              </p>
            </td>
            <td @click="detailrequest(item)" :style="{
              color: item.status == 'Approved' ? '#00C092' : ' #FF0000',
            }" style="text-align: center">
              <div :style="{
                backgroundColor:
                  item.status == 'Approved' ? '#CCF3E9' : '#FFCCCC',
                textAlign: 'center',
                borderRadius: '20px',
                padding: '5px',
              }">
                {{
                  item.status == "Approved"
                  ? $t("approved")
                  : item.status == "Denied"
                    ? $t("denied")
                    : item.status == "Draft Request"
                      ? $t("Draftreq")
                      : item.status == "Canceled"
                        ? $t("Cancelled")
                        : $t("waitingApproval")
                }}
                <!-- {{ item.status }} -->
              </div>
            </td>
            <td @click="detailrequest(item)" style="text-align: center">
              {{ item.firstName }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div class="text-right" v-if="requestlist.length">
      <PaginationComponent
          :page.sync="page"
          :pageCount="pageCount"
          :itemsPerPage.sync="itemsPerPage"
      />
    </div>
    
    <!-- Detail request -->
    <v-dialog v-model="Detaildialog" persistent max-width="1000px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span> {{ $t("Overtime") }} </span>
          <v-spacer></v-spacer>
          <v-btn @click="Closedetaildialog()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-4">
          <hr />
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3"> {{ $t("requestedDate") }}:</v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field v-model="Detailrequest.RequestedDate" height="10px" color="#F99D20" single-line outlined
                    class="readonly" dense readonly></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3"> {{ $t("status") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-row class="mt-1">
                    <div v-if="cancelstatus == false" :style="{
                      width: '100px',
                      height: '40px',
                      display: 'flex',
                      backgroundColor:
                        Detailrequest.status == 'Approved'
                          ? '#CCF3E9'
                          : '#FFCCCC',
                      textAlign: 'center',
                      justifyContent: 'center',
                      borderRadius: '20px',
                      padding: '5px',
                    }">
                      <p class="mb-0 mt-1" :style="{
                        color:
                          Detailrequest.status == 'Approved'
                            ? '#22A534'
                            : Detailrequest.status == 'Waiting Approval'
                              ? '#F99D20'
                              : Detailrequest.status == 'Canceled'
                                ? '#FF0000'
                                : '#FF6565',
                      }">
                        {{
                          Detailrequest.status == "Approved"
                          ? $t("approved")
                          : Detailrequest.status == "Denied"
                            ? $t("denied")
                            : Detailrequest.status == "Draft Request"
                              ? $t("Draftreq")
                              : Detailrequest.status == "Canceled"
                                ? $t("Cancelled")
                                : $t("waitingApproval")
                        }}
                      </p>
                    </div>
                    <v-btn @click="showcancel = true" v-if="Detailrequest.status == 'Approved' && cancelstatus != true"
                      style="text-decoration: underline" text class="text-capitalize ml-4">{{ $t("Cancel") }}</v-btn>
                  </v-row>
                  <div v-if="cancelstatus == true" style="
                      background-color: #ffcccc;
                      width: 100px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      border-radius: 20px;
                      padding: 5px;
                      margin-top: 5px;
                    ">
                    <p class="mb-0" style="color: #ff0000">
                      {{ $t("Cancelled") }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3"> {{ $t("EmployeeName") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field v-model="Detailrequest.EmployeeName" height="10px" color="#F99D20" single-line outlined
                    class="readonly" readonly dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3"> {{ $t("position") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field v-model="Detailrequest.Position" height="10px" color="#F99D20" single-line outlined dense
                    class="readonly" readonly></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("oTHours") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field v-model="Detailrequest.OThours" height="10px" color="#F99D20" single-line outlined readonly
                    class="readonly" dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3"> {{ $t("otType") }} : </v-col>
                <v-col style="position:relative" cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field v-model="Detailrequest.OTtype" height="10px" color="#F99D20" single-line outlined dense
                    class="readonly" readonly></v-text-field>
                    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <img :src="isHovered ? outlineinfoimg : infoimg" @mouseover="handleMouseOver" v-bind="attrs"
          v-on="on" @mouseleave="handleMouseLeave"  class="infoimg"/>
      </template>
      <span>
        {{ $t("ot1") }}<br>
        {{ $t("ot2") }}<br>
        {{ $t("ot3") }}<br>
        {{ $t("ot4") }}<br> 
      </span>
      
    </v-tooltip>
                </v-col>
                
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("otStartTime") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field v-model="Detailrequest.Startime" height="10px" color="#F99D20" single-line outlined
                    class="readonly" readonly dense append-icon="mdi-calendar-month-outline"></v-text-field>
                    
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <v-row>
                <v-col cols="12" xs="3" sm="3" md="3" lg="3" xl="3">{{ $t("otEndTime") }} : </v-col>
                <v-col cols="12" xs="9" sm="9" md="9" lg="9" xl="9" class="pt-0">
                  <v-text-field v-model="Detailrequest.Endtime" height="10px" color="#F99D20" single-line outlined dense
                    class="readonly" readonly append-icon="mdi-calendar-month-outline"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2">{{ $t("DescriptionOT") }}</v-col>
            <v-col cols="12" xs="9" sm="9" md="10" lg="10" xl="10">
              <div :style="{ width: $vuetify.breakpoint.lgAndUp ? '823px' : $vuetify.breakpoint.md ? '800px' : '' }">
                <v-textarea v-model="Detailrequest.Description" solo no-resize rows="3" min-height="80" name="input-7-4"
                  readonly class="mb-4 readonly"
                  :style="$vuetify.breakpoint.mdAndUp ? 'caret-color: orange !important;margin-left:-2.5rem;' : 'caret-color: orange !important'"
                  hide-details></v-textarea>
              </div>
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2"> {{ $t("AttachmentOT") }}</v-col>
            <v-col cols="12" xs="9" sm="9" md="10" lg="10" xl="10">
              <div v-if="Detailrequest.attachmentlist.length === 0" class="mr-0 pa-3 relative"
                :style="$vuetify.breakpoint.mdAndUp ? 'margin-left : -2.5rem; ' : ''" style="
                  width: 130px;
                  height: 120px;
                  border: 1px solid #ccc;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border-radius: 7px;
                ">
                <v-img class="mb-3 mt-2" src="@/assets/images/none.png" width="60px"></v-img>
                <div class="text-center">None</div>
              </div>
              <div class="container" :style="$vuetify.breakpoint.mdAndUp ? 'margin-left : -2.5rem; ' : ''">
                <div v-for="file in Detailrequest.attachmentlist" :key="file.filename" class="mr-0 pa-3 relative"
                  style="width: 130px;height:120px;border: 1px solid #ccc;border-radius: 7px;">
                  <div @click="showiframeforDetail(file)" class="mt-2">
                    <v-img class="mb-3 ml-5" v-if="file.filetype == 'png'" src="@/assets/images/png.png"
                      width="50px"></v-img>
                    <v-img class="mb-3 ml-5" v-if="file.filetype == 'jpeg'" src="@/assets/images/jpg.png"
                      width="50px"></v-img>
                    <v-img class="mb-3 ml-5" v-if="file.filetype == 'jpg'" src="@/assets/images/jpg.png"
                      width="50px"></v-img>
                    <v-img class="mb-3 ml-5" v-if="file.filetype == 'pdf'" src="@/assets/images/pdf.png"
                      width="50px"></v-img>
                    <div class="text-center">{{ file.filename ? (file.filename.substring(0, 10) +
                      " ...") : '' }}</div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2"> {{ $t("approver") }}</v-col>
            <v-col>
              <v-row>
                <v-col cols="12" xs="6" sm="6" md="6" lg="6" xl="6">
                  <v-text-field v-model="Detailrequest.Approver" height="10px" color="#F99D20" single-line outlined dense
                    class="readonly" :style="$vuetify.breakpoint.mdAndUp ? 'margin-left : -2.5rem; ' : ''"
                    readonly></v-text-field>
                </v-col>
                <v-col>
                  <p class="mt-3">{{ Detailrequest.ApprovalDatetime }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <hr color="#EEEEEE" class="mb-3" v-if="showcancel == true" />
          <v-row v-if="showcancel == true || Detailrequest.status == 'Canceled'">
            <v-col cols="12" xs="3" sm="3" md="2" lg="2" xl="2">
              <span style="font-weight:bold;">{{ $t("ActionNote") }}</span><span class="red--text">*</span>
            </v-col>
            <v-col cols="12" xs="9" sm="9" md="10" lg="10" xl="10">
              <div :style="{ width: $vuetify.breakpoint.lgAndUp ? '823px' : $vuetify.breakpoint.md ? '800px' : '' }">
                <v-textarea v-model="Detailrequest.ActionNote" solo no-resize rows="3" min-height="80" name="input-7-4"
                  :disabled="Detailrequest.status == 'Canceled'" placeholder="Please fill in the reason for cancellation*"
                  class="mb-4" outlined color="#F99D20" :error-messages="ActionnoteSent"
                  :style="$vuetify.breakpoint.mdAndUp ? 'caret-color: orange !important;margin-left:-2.5rem;' : 'caret-color: orange !important'">
                  <template v-slot:message="{}">
                    <img src="@/assets/images/Icon awesome-exclamation-circle.png" width="13" height="13" /> <span>
                      {{ $t("Action Note is required") }}
                    </span>
                  </template>
                </v-textarea>
              </div>
              <!-- <span v-if="ActionnoteSent.length != 0" style="font-size: 13px" class="red--text"
                :style="$vuetify.breakpoint.mdAndUp ? 'margin-left : -2.5rem;' : ''">Action Note is
                required</span> -->
            </v-col>
          </v-row>
          <v-row v-if="showcancel == true" class="mt-7 mb-5">
            <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
            <v-btn color="darken-1" class="mr-3 text-capitalize fix-btn" width="120" max-height="35" text
              @click="showcancel = false">
              {{ $t("Cancel") }} testing
            </v-btn>
            <v-btn color="#F99D20" class="text-capitalize white--text mr-3" width="90" max-height="35"
              @click="CancelOTreq()">
              {{ $t("Confirm") }}</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <!-- exportdialog -->
    <v-dialog v-model="exportdialog" persistent max-width="600px">
      <v-card style="border-radius: 15px">
        <v-card-title>
          <span>{{ $t("ExportOTRequest") }} </span>
          <v-spacer></v-spacer>
          <v-btn @click="exportdialog = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="pb-0">
              <p class="mt-2">{{ $t("Employees") }}</p>
              <v-text-field v-model="computedForTranslate" readonly height="10px" class="mt-3" color="#F99D20" single-line
                outlined dense required></v-text-field>
            </v-col>
            <v-col v-show="!hidestartandenddate" cols="6" class="pb-0">
              <p class="mt-2">{{ $t("date") }}</p>
              <v-text-field :value="this.$t('allDate')" readonly height="10px" class="mt-3" color="#F99D20" single-line
                outlined dense required></v-text-field>
            </v-col>
          </v-row>

          <v-row v-show="hidestartandenddate">
            <v-col cols="6" class="pt-0">
              <p class="mt-2">
                {{ $t("startDate") }}<span class="red--text"> *</span>
              </p>
              <v-menu v-model="exportstartdatemenu" :close-on-content-click="false" transition="scale-transition" offset-y
                fluid max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field autocomplete="off" single-line dense outlined class="mt-3"
                    v-model="computedDateforstartdate" append-icon="mdi-calendar-month-outline" color="#F99D20"
                    persistent-hint v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="exportrequest.Otstart" no-title
                  @input="exportstartdatemenu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" class="pt-0">
              <p class="mt-2">
                {{ $t("enddata") }}<span class="red--text"> *</span>
              </p>
              <v-menu v-model="exportenddatemenu" :close-on-content-click="false" transition="scale-transition" offset-y
                fluid max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field autocomplete="off" single-line dense outlined class="mt-3"
                    v-model="computedDateforenddate" append-icon="mdi-calendar-month-outline" color="#F99D20"
                    persistent-hint v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="exportrequest.Otend" no-title @input="exportenddatemenu = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-row class="ml-0 mr-0">
            <v-spacer></v-spacer>
            <v-col cols="6" md="3">
              <v-btn outlined class="text-capitalize commoncancelbtn" text @click="exportdialog = false" width="100%" max-height="35">
                {{ $t("Cancel") }}</v-btn>
            </v-col>
            <v-col cols="6" md="3">
              <v-btn color="#F99D20" class="white--text text-capitalize" width="100%" max-height="35"
                @click="exportexcel()" :disabled="permission.export">
                {{ $t("Export") }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <!-- Loadingdialog -->
    <LoadingDialog :LoadingDialog="LoadingDialog"></LoadingDialog>
 
   <template>
      <v-dialog v-model="dialogforDownload" persistent width="500" height="500" class="rounded-lg"
        style="overflow: hidden">
        <v-card style="padding-left:1.5rem; padding-right:1.5rem; padding-bottom:1.5rem;">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn icon @click="downloadImage()"><v-icon size="30">mdi-download</v-icon></v-btn>

            <v-btn icon @click="dialogforDownload = false"><v-icon size="30">mdi-window-close</v-icon></v-btn>
          </v-card-actions>

          <v-img :src="imgForshow" max-width="500"></v-img>

        </v-card>
      </v-dialog>
    </template>

    <v-dialog v-model="printdialogforupdate" max-width="75%" style="overflow: hidden">
      <v-card style="background-color: white">
        <v-btn @click="
          printdialogforupdate = false;
        iframeShow = false;
        " icon style="margin-right: 3rem; margin-top: 1rem">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card class="pdf-container">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <iframe :src="pdfString" width="100%" height="1000px" frameborder="0" scrolling="no" />
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import jsPDF from "jspdf";
import XLSX from "xlsx";
import moment from "moment";
import CheckViewExport from "@/function/RolePermissions.js";
import { ConverttolocalwithStroke } from '@/plugins/toLocal.js';
import LoadingDialog from "@/components/Loading.vue";
import PaginationComponent from '@/components/Pagination.vue';

export default {
  components:{
    LoadingDialog,
    PaginationComponent
  },
  mixins: [LogTrace, CheckViewExport],
  name: "Request",
  data() {
    return {
      permission: {
        export: true,
      },
      tablecheck: false,
      positionMenu: false,
      subMenu: false,
      subMenuVisible: false,
      // positions: [
      //   { text: "Position", value: 1, color: "" },
      //   { text: "Department", value: 2, color: "" },
      //   { text: "Employee Type", value: 3, color: "" },
      // ],
      subPositions: [],
      subemptype: [
        { text: "Full Time", value: 1 },
        { text: "Daily", value: 2 },
        { text: "Part-time", value: 3 },
        { text: "Contract", value: 4 },
        { text: "Fixed Pay", value: 5 },
      ],
      printdialogforupdate: false,
      dialogforDownload: false,
      imgName: "",
      imgString: "",
      imgForshow: "",
      dllink: null,
      iframeShow: false,
      pdfString: "",
      selectedPosition: null,
      selectedSubPosition: [],
      selected: null,
      positionMenuX: 0,
      positionMenuY: 0,
      subMenuX: 0,
      subMenuY: 0,
      searchInput: "",

      url: enurl.apiUrl,
      Detaildialog: false,
      exportdialog: false,
      itemInner: "",
      searchtxt: "",
      images: {
        excel: require("@/assets/images/excel@2x.png"),
      },
      ActionnoteSent: [],
      requestlist: [],
      myrequestlist: [],
      filteremplist: [],
      showcancel: false,
      cancelstatus: false,
      alldate: true,
      selectusertype: "Everyone",
      hidestartandenddate: false,
      exportstartdatemenu: false,
      exportenddatemenu: false,
      checklist: false,
      exportrequest: {
        Otstart: "",
        Otend: "",
      },
      exportlist: [],
      myexportlist: [],
      infoimg : require("@/assets/images/Vector.png"),
      outlineinfoimg: require("@/assets/images/information-variant-circle 1.png"),
      isHovered:false,
      Detailrequest: {
        WFID: 0,
        RequestedDate: "",
        attachmentlist: [],
        status: "",
        EmployeeName: "",
        Position: "",
        OTdate: "",
        OThours: "",
        Startime: "",
        Endtime: "",
        Description: "",
        Attachement: [],
        Approver: "",
        ApprovalDatetime: "",
        Allowupdate: true,
        ActionNote: "",
        OTtype:""
      },
      page: 1,
      itemsPerPage: 10,
      pageCount: 15,
      LoadingDialog: false,
      reportDateFrom: "",
      reportDateTo: "",
      positionList: [],
      subPositionList: [],
      subDepartmentList: [],
      deparmentList: [],
    };
  },
  // data: () => ({

  // }),
  mounted() {
    this.Getrequestlist();
    this.GetPosition();
    this.GetDepartment();
    this.permission = this.CheckViewExport(18, 19);
  },

  computed: {
    filteredSubPositions() {
      return this.subPositions.filter((subPosition) =>
        subPosition.text.toLowerCase().includes(this.searchInput.toLowerCase())
      );
    },

    positions() {
      return [
        { value: 1, text: this.$t("position"), color: "" },
        { value: 2, text: this.$t("department"), color: "" },
        { value: 3, text: this.$t("employeetype"), color: "" },
      ];
    },
    status() {
      return [
        { val: "All", txt: this.$t("All") },
        { val: "Canceled", txt: this.$t("Cancelled") },
        { val: "Approved", txt: this.$t("approved") },
        { val: "Denied", txt: this.$t("denied") },
      ];
    },
    computedForTranslate() {
      return this.selectusertype == "Everyone"
        ? this.$t("Everyone")
        : this.selectusertype == "Selected User"
          ? this.$t("SelectUser")
          : this.$t("SelectUsers");
    },
    headers() {
      return [
        {
          text: this.$t("EmployeeID"),
          align: "middle",
          sortable: true,
          value: "employee_Code",
        },
        {
          text: this.$t("EmployeeName"),
          value: "name",
          align: "center",
          sortable: true,
        },

        {
          text: this.$t("oTHours"),
          align: "left",
          sortable: true,
          filterable: false,
        },

        {
          text: this.$t("oTStartDate"),
          align: "left",
          value: "otdate",
          sortable: true,
        },
        {
          text: this.$t("oTEndDate"),
          align: "left",
          value: "otenddate",
          sortable: true,
        },
        {
          text: this.$t("purposeofOT"),
          value: "purposeot",
          sortable: true,
          align: "left",
        },
        {
          text: this.$t("requestedDate"),
          value: "requestdate",
          sortable: true,
          align: "left",
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: true,
          align: "left",
        },
        {
          text: "Supervisor",
          sortable: true,
          align: "left",
          value: "firstName",
        },
        {
          text: this.$t("dateOfAction"),
          value: "approvedate",
          sortable: true,
          align: "left",
        },
      ];
    },
    computedDateforstartdate() {
      return this.formatDate(this.exportrequest.Otstart);
    },
    computedDateforenddate() {
      return this.formatDate(this.exportrequest.Otend);
    },
  },
  watch: {
    "Detailrequest.ActionNote"() {
      this.ActionnoteSent = [];
    },
    itemInner(v) {
      if (v) 
      {
        let self = this;
        let list = self.filteremplist;
        self.requestlist = [];
        if (v == "All") 
        {
          self.requestlist = list;
        } 
        else 
        {
          self.requestlist = list.filter((x) => x.status === v);
        }
      }
    },
    searchtxt(v) {
      if (v) 
      {
        let List = this.filteremplist;
        this.requestlist = [];
        //let Stxt = this.searchtxt.toLowerCase();
        let temp = List.filter(
          (v) =>
            v.employee_Code
              .toLowerCase()
              .indexOf(this.searchtxt.toLowerCase()) > -1 ||
            v.name.toLowerCase().indexOf(this.searchtxt.toLowerCase()) > -1
        );
        for (let i = 0; i < temp.length; i++) {
          this.requestlist.push(temp[i]);
        }
      } 
      else 
      {
        this.requestlist = this.filteremplist;
      }
    },
  },

  methods: {
    addDateAndTime(date,time){
      // Convert the time string to a Date object
      const timeDate = new Date(`2000-01-01T${time}:00.000Z`);
      // Extract the time components
      const hours = timeDate.getUTCHours();
      const minutes = timeDate.getUTCMinutes();
      // Convert the ISO string to a Date object
      const isoDate = new Date(date);
      // Set the time components from the time string to the ISO date
      isoDate.setUTCHours(hours, minutes, 0, 0);
      // Get the resulting ISO string
      const resultIsoString = isoDate.toISOString();
      return resultIsoString;
    },

    handleMouseOver() {
      this.isHovered = true;
    },

    handleMouseLeave() {
      this.isHovered = false;
    },

    async showiframeforDetail(item) {
      let self = this;
      if (item.filetype == "pdf") 
      {
        const blob = await this.base64ToBlob(
          item.attachment_file,
          "application/pdf"
        );
        self.printdialogforupdate = true;
        self.iframeShow = true;
        const blobUrl = URL.createObjectURL(blob);
        self.pdfString = blobUrl;
      } 
      else 
      {
        //const pdfBlob = await this.convertBase64ImageToPdf(
        // item.attachment_file
        //);
        self.dialogforDownload = true;
        self.imgForshow = 'data:image/png;base64,' + item.attachment_file;
        self.dllink = self.imgForshow;
        self.imgName = item.filename;
      }
    },

    downloadImage() {
      let self = this;
      const link = document.createElement('a');
      link.href = self.dllink;
      link.download = self.imgName;
      link.click();
    },

    base64ToBlob(base64String, contentType) {
      return fetch(`data:${contentType};base64,${base64String}`)
        .then((response) => response.blob())
        .catch((error) => {
          throw error;
        });
    },

    async convertBase64ImageToPdf(base64Image) {
      const pdf = new jsPDF();
      const blob = this.base64ToBlobforimg(base64Image, "image/jpeg"); // Adjust content type if needed
      const imgData = URL.createObjectURL(blob);
      pdf.addImage(imgData, "JPEG", 10, 10, 190, 277); // Adjust dimensions as needed
      const pdfData = pdf.output("blob");
      return pdfData;
    },

    base64ToBlobforimg(base64String, contentType) {
      const byteCharacters = atob(base64String);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) 
      {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    },

    GetPosition() {
      let self = this;
      let temp = { company_ID: store.state.companyID };
      axios.post(`${self.url}Position/GetPosition`, temp)
        .then(function (response) {
          self.positionList = response.data.data;
          self.positionList.map(function (data) {
            self.subPositionList.push({
              text: data.position_Name,
              value: data.position_ID,
            });
          });
        });
    },

    GetDepartment() {
      let self = this;
      let temp = { company_ID: store.state.companyID };
      axios.post(`${self.url}Department/GetAllDepartmentsByCompanyID`, temp)
        .then(function (response) {
          self.departmentList = response.data.data;
          self.departmentList.map(function (data) {
            self.subDepartmentList.push({
              text: data.department_Name,
              value: data.department_ID,
            });
          });
        });
    },

    togglePositionMenu(event) {
      this.positionMenuX = event.clientX;
      this.positionMenuY = event.clientY;
      this.positionMenu = !this.positionMenu;
      // this.subMenuVisible = false;
    },

    handlePositionSelect(position) {
      this.selectedPosition = position;
      this.selected = position.value;
      if (position.value == 1) 
      {
        this.subPositions = this.subPositionList;
      }
      if (position.value == 2) 
      {
        this.subPositions = this.subDepartmentList;
      }
      if (position.value == 3) 
      {
        this.subPositions = this.subemptype;
      }
      this.subMenuVisible = true;
      this.subMenu = true;
      this.subMenuX = this.positionMenuX + 100; // Adjust the value for proper positioning
      this.subMenuY = this.positionMenuY;
      this.positions.forEach((position) => {
        if (position.value == this.selected) 
        {
          position.color = "#F99D20";
        } 
        else 
        {
          position.color = "initial";
        }
      });
    },

    handleSubPositionSelect1(subPosition) {
      this.selectedSubPosition = subPosition;
      this.positionMenu = true;
      let myallotreqlist = this.filteremplist;
      this.requestlist = [];
      if (this.selected == 1) 
      {
        this.requestlist = myallotreqlist.filter(
          (date) => date.positionName == subPosition.text
        );
      }
      if (this.selected == 2) 
      {
        this.requestlist = myallotreqlist.filter(
          (date) => date.departmentName == subPosition.text
        );
      }
      if (this.selected == 3) 
      {
        this.requestlist = myallotreqlist.filter(
          (date) => date.emptype == subPosition.text
        );
      }
      // this.subMenu = false;
    },

    handleSubPositionSelect(subPosition) {
      this.positionMenu = this.subMenu;
      const index = this.selectedSubPosition.indexOf(subPosition);
      if (index === -1) 
      {
        this.selectedSubPosition.push(subPosition);
      } 
      else 
      {
        this.selectedSubPosition.splice(index, 1);
      }
      this.positionMenu = true;
      let myalllist = this.filteremplist;
      this.requestlist = [];
      if (this.selected == 1) 
      {
        this.requestlist = myalllist.filter((date) =>
          this.selectedSubPosition.some(
            (item) => item.text === date.position_Name
          )
        );
      }
      if (this.selected == 2) 
      {
        this.requestlist = myalllist.filter((date) =>
          this.selectedSubPosition.some(
            (item) => item.text === date.departmentName
          )
        );
      }
      if (this.selected == 3) 
      {
        this.requestlist = myalllist.filter((date) =>
          this.selectedSubPosition.some((item) => item.text === date.emptype)
        );
      }
      if (this.selectedSubPosition.length == 0) 
      {
        this.requestlist = myalllist;
      }
    },

    newReq() {
      this.$router.push("/EmployeeOTrequest/NewOTReq");
      // this.$router.push({ name: "MyOTrequest" });
    },

    async Closedetaildialog() {
      this.Detaildialog = false;
      this.showcancel = false;
      if (this.cancelstatus) 
      {
        await this.Getrequestlist();
      }
      this.cancelstatus = false;
    },

    formatDate(date) {
      if (!date) return null;
      let finaldate = date.slice(0, 10).split("-");
      return finaldate[2] + "/" + finaldate[1] + "/" + finaldate[0];
    },

    convertUTCDateToLocalDateForReq(date) {
      date = new Date(date);
      let timezone = date.getTimezoneOffset() * 60000;
      let newDate = new Date(date.getTime() - timezone);
      let output = null;
      if (newDate.getHours() < 10 && newDate.getMinutes() < 10) 
      {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } 
      else if (newDate.getHours() < 10 && newDate.getMinutes() >= 10) 
      {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          "0" +
          newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      } 
      else if (newDate.getHours() >= 10 && newDate.getMinutes() < 10) 
      {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          "0" +
          newDate.getMinutes();
      } 
      else 
      {
        output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear() +
          " " +
          +newDate.getHours() +
          ":" +
          +newDate.getMinutes();
      }
      return output;
    },

    convertUTCDateToLocalDate(date) {
      if (date != null) {
        date = new Date(date);
        let timezone = date.getTimezoneOffset() * 60000;
        let newDate = new Date(date.getTime() - timezone);
        let output =
          newDate.getDate() +
          "/" +
          (newDate.getMonth() + 1) +
          "/" +
          newDate.getFullYear();
        return output;
      }
    },

    checkmylist(temp) {
      let self = this;
      let checkvalidate = this.requestlist.filter((date) => date.check == true);
      let checkfalsevalidate = this.requestlist.filter(
        (date) => date.check == false
      );
      if (checkfalsevalidate.length != 0) 
      {
        this.tablecheck = false;
      }
      let removeitem = [];
      if (checkvalidate.length != 0) 
      {
        if (checkvalidate.length == this.requestlist.length) 
        {
          this.tablecheck = true;
        }
        self.checklist = true;
        if (self.myexportlist.length != 0) 
        {
          let checkduplicate = this.myexportlist.filter(
            (date) => date == temp.requester_ID
          );
          if (checkduplicate.length == 0) 
          {
            self.myexportlist.push(temp.requester_ID);
          }
        } 
        else 
        {
          self.myexportlist.push(temp.requester_ID);
        }
        for (let i = 0; i < self.myexportlist.length; i++) {
          let a = checkvalidate.filter(
            (date) => date.requester_ID == self.myexportlist[i]
          );
          let mydata = self.myexportlist[i];
          if (a.length == 0) 
          {
            removeitem.push(mydata);
          }
        }
        if (removeitem.length != 0) 
        {
          for (let a = 0; a < removeitem.length; a++) 
          {
            self.myexportlist.splice(
              self.myexportlist.findIndex((v) => v == removeitem[a]),
              1
            );
          }
        }
      } 
      else 
      {
        //self.myexportlist=[];
        self.checklist = false;
      }
    },

    checkall() {
      this.exportlist = [];
      if (this.tablecheck) 
      {
        this.requestlist.map((e) => (e.check = event.target.checked));
        this.checklist = true;
        for (let i = 0; i < this.requestlist.length; i++) 
        {
          if (this.requestlist[i].check) 
          {
            let mydata = [];
            if (this.myexportlist.length != 0) 
            {
              let myrequester = this.requestlist[i].requester_ID;
              mydata = this.myexportlist.filter((date) => date == myrequester);
              if (mydata.length == 0) 
              {
                this.myexportlist.push(myrequester);
              }
            } 
            else 
            {
              this.myexportlist.push(this.requestlist[i].requester_ID);
            }
          }
        }
      } 
      else 
      {
        this.requestlist.forEach((e) => {
          e.check = false;
        });
        this.myexportlist = [];
      }
    },

    checkDateExport(value) {
      if (value < 10) 
      {
        return "0" + value;
      } 
      else 
      {
        return value;
      }
    },

    excelExportDateRange() {
      let self = this;
      let inputdate = self.exportrequest.Otstart.split("-");
      let inputenddate = self.exportrequest.Otend.split("-");
      let datestring1 = inputdate[2] + "-" + inputdate[1] + "-" + inputdate[0];
      let datestring2 =
        inputenddate[2] + "-" + inputenddate[1] + "-" + inputenddate[0];
      return `${datestring1}-${datestring2}`;
    },

    async exportexcel() {
      let self = this;
      self.exportdialog = true;
      if (self.selectusertype == "Everyone" && self.alldate) 
      {
        let tempsheetname = "";
        let wb = XLSX.utils.book_new();
        let wsdata = [];
        self.exportdialog = false;
        for (let v of self.requestlist) 
        {
          wsdata.push({
            ["Employee ID"]: v.employee_Code,
            ["Employee Name"]: v.name,
            ["OT hours"]: v.oT_Hours,
            ["OT start date"]: self.formatDate(v.start_Date),
            ["OT End Date"]: self.formatDate(v.end_Date),
            ["OT Start Time"]: v.oT_Start_Time,
            ["OT End Time"]: v.oT_End_Time,
            ["Purpose Of OT"]: v.purpose_OT,
            ["Requested Date"]: self.convertUTCDateToLocalDate(v.oT_Requested_Date),
            ["Status"]: v.status,
            ["Reason"]: v.reason,
            ["Supervisor"]: v.firstName,
            ["DateofAction"]: self.convertUTCDateToLocalDate(v.oT_Approved_Date),
          });
        }

        const ws = XLSX.utils.json_to_sheet(wsdata);
        ws["!cols"] = [
          { wch: 10 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
        ];
        tempsheetname = `OptimisticOTRequestHistory_AllDate`;
        self.sheetTitle = ` All OT Request History`;
        XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);
        XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
          compression: true,
        });
      } 
      else if (self.selectusertype == "Everyone" && self.alldate == false) 
      {
        if (
          moment(this.exportrequest.Otstart) <
          moment(this.exportrequest.Otend).add(1, "day")
        ) 
        {
          let inputdate = self.exportrequest.Otstart.split("-");
          let inputenddate = self.exportrequest.Otend.split("-");
          self.exportlist = self.myrequestlist.filter((v) => {
            if (this.exportrequest.Otstart && this.exportrequest.Otend) 
            {
              const between = moment.utc(v.oT_Approved_Date);
              return between.isBetween(
                moment(this.exportrequest.Otstart),
                moment(this.exportrequest.Otend).add(1, "day")
              );
            }
            return true;
          });
          self.LoadingDialog = false;
          let hasData = false;
          let lastSheetData = [];
          self.selectusertype = "Everyone";
          self.myexportlist = [];
          let wb = XLSX.utils.book_new();
          let mynewstartdate = new Date(
            inputdate[0],
            inputdate[1],
            inputdate[2]
          );
          let mynewenddate = new Date(
            inputenddate[0],
            inputenddate[1],
            inputenddate[2]
          );
          let wsdata = [];
          let Difference_In_Time =
            mynewenddate.getTime() - mynewstartdate.getTime();
          let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
          let totalsheet = Difference_In_Days;

          for (let sheet = 0; sheet < totalsheet; sheet++) {
            let tempsheetdata = [];
            let input = parseInt(inputdate[2]) + sheet;
            wsdata = [];
            let startoftheyear = new Date(
              inputdate[0],
              inputdate[1] - 1,
              input
            );

            tempsheetdata = self.exportlist.filter((x) => {
              let tempdate2 = moment.utc(x.oT_Approved_Date).toDate();
              let date2 = moment(tempdate2).local().format("YYYY-MM-DD ");
              let myinputdate = date2.split("-");
              let mydatelist = new Date(
                myinputdate[0],
                myinputdate[1] - 1,
                myinputdate[2]
              );

              return mydatelist.getTime() === startoftheyear.getTime();
            });
            let tempsheetname =
              self.checkDateExport(startoftheyear.getDate()) +
              "_" +
              self.checkDateExport(startoftheyear.getMonth() + 1) +
              "_" +
              startoftheyear.getFullYear();

            if (self.exportlist.length != lastSheetData.length) {
              lastSheetData = [...lastSheetData, ...tempsheetdata];
            }
            if (tempsheetdata.length) {
              for (let j = 0; j < tempsheetdata.length; j++) {
                wsdata.push({
                  ["Employee ID"]: tempsheetdata[j].employee_Code,
                  ["Employee Name"]: tempsheetdata[j].name,
                  ["OT hours"]: tempsheetdata[j].oT_Hours,
                  ["OT start date"]: self.formatDate(
                    tempsheetdata[j].start_Date
                  ),
                  ["OT End Date"]: self.formatDate(tempsheetdata[j].end_Date),
                  ["OT Start Time"]: tempsheetdata[j].oT_Start_Time,
                  ["OT End Time"]: tempsheetdata[j].oT_End_Time,
                  ["Purpose Of OT"]: tempsheetdata[j].purpose_OT,
                  ["Requested Date"]: self.convertUTCDateToLocalDate(
                    tempsheetdata[j].oT_Requested_Date
                  ),
                  ["Status"]: tempsheetdata[j].status,
                  ["Reason"]: tempsheetdata[j].reason,
                  ["Supervisor"]: tempsheetdata[j].firstName,
                  ["Date of Action"]: self.convertUTCDateToLocalDate(
                    tempsheetdata[j].oT_Approved_Date
                  ),
                });
              }
              var ws = XLSX.utils.json_to_sheet(wsdata);
              ws["!cols"] = [
                { wch: 20 },
                { wch: 25 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
              ];
              XLSX.utils.book_append_sheet(wb, ws, tempsheetname);
              hasData = true;
            }
          }

          if (!hasData) {
            alert("Your selected date range doesn't have Data!");
          } else {
            let wsalldata = [];
            lastSheetData.sort(function (a, b) {
              var order = 1;
              a = new Date(self.convertUTCDateToLocalDate(a.oT_Approved_Date));
              b = new Date(self.convertUTCDateToLocalDate(b.oT_Approved_Date));
              // a = mydate;
              // b = mysecdate;
              var results = a > b ? -1 : a < b ? 1 : 0;

              return results * order;
            });
            lastSheetData.reverse();

            for (let j = 0; j < lastSheetData.length; j++) {
              wsalldata.push({
                ["Employee ID"]: lastSheetData[j].employee_Code,
                ["Employee Name"]: lastSheetData[j].name,
                ["OT hours"]: lastSheetData[j].oT_Hours,
                ["OT start date"]: self.formatDate(lastSheetData[j].start_Date),
                ["OT End Date"]: self.formatDate(lastSheetData[j].end_Date),
                ["OT Start Time"]: lastSheetData[j].oT_Start_Time,
                ["OT End Time"]: lastSheetData[j].oT_End_Time,
                ["Purpose Of OT"]: lastSheetData[j].purpose_OT,
                ["Requested Date"]: self.convertUTCDateToLocalDate(
                  lastSheetData[j].oT_Requested_Date
                ),
                ["Status"]: lastSheetData[j].status,
                ["Reason"]: lastSheetData[j].reason,
                ["Supervisor"]: lastSheetData[j].firstName,
                ["Date of Action"]: self.convertUTCDateToLocalDate(
                  lastSheetData[j].oT_Approved_Date
                ),
              });
            }
            ws = XLSX.utils.json_to_sheet(wsalldata);
            ws["!cols"] = [
              { wch: 20 },
              { wch: 25 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
            ];

            let datestring1 =
              inputdate[2] + "_" + inputdate[1] + "_" + inputdate[0];
            let datestring2 =
              inputenddate[2] + "_" + inputenddate[1] + "_" + inputenddate[0];
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              datestring1 + "-" + datestring2
            );
          }

          if (hasData) {
            let exportExcelName = `OptimisticOTRequestHistory_${self.excelExportDateRange()}.xlsx`;

            self.exportList = [];
            self.exportrequest.Otstart = "";
            self.exportrequest.Otend = "";
            XLSX.writeFile(wb, exportExcelName);
          }
        } else {
          self.loadingdialog = false;
          alert("Start date can’t be after end date");
        }
      } else if (
        self.selectusertype == "Selected Individual" &&
        self.alldate == true
      ) {
        let combilearrary = [];

        for (let i = 0; i < self.myexportlist.length; i++) {
          self.tempexport = {
            oT_Request_ID: self.myexportlist[i],
          };
          combilearrary.push(self.tempexport);
        }
        let temp = {
          requestID: combilearrary,

          company_ID: store.state.companyID,
        };
        await axios
          .post(`${self.url}OT_Request/Getspecifichralldate`, temp)
          .then(function (response) {
            self.exportlist = response.data.data;
            let tempsheetname = "";
            let wb = XLSX.utils.book_new();
            self.exportdialog = false;

            let wsdata = [];
            for (let v of self.exportlist) {
              wsdata.push({
                ["Employee ID"]: v.employee_Code,
                ["Employee Name"]: v.name + " " + v.lastName,
                ["OT hours"]: v.oT_Hours,
                ["OT start date"]: self.formatDate(v.start_Date),
                ["OT End Date"]: self.formatDate(v.end_Date),
                ["OT Start Time"]: v.oT_Start_Time,
                ["OT End Time"]: v.oT_End_Time,
                ["Purpose Of OT"]: v.purpose_OT,
                ["Requested Date"]: self.convertUTCDateToLocalDate(
                  v.oT_Requested_Date
                ),
                ["Status"]: v.status,
                ["Reason"]: v.reason,
                ["Supervisor"]: v.firstName + " " + v.myLastName,
                ["DateofAction"]: v.oT_Approved_Date,
              });
            }

            for (var j = 0; j < wsdata.length; j++) {
              wsdata[j].DateofAction = self.convertUTCDateToLocalDate(
                wsdata[j].DateofAction
              );
            }
            const ws = XLSX.utils.json_to_sheet(wsdata);
            ws["!cols"] = [
              { wch: 10 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
            ];
            tempsheetname = `OptimisticOTRequestHistory_AllDate`;
            self.sheetTitle = ` All OT Request History`;
            XLSX.utils.book_append_sheet(wb, ws, `${self.sheetTitle}`);

            XLSX.writeFile(wb, `${tempsheetname}.xlsx`, {
              compression: true,
            });
          });
      } else if (
        self.selectusertype == "Selected Individual" &&
        self.alldate == false
      ) {
        if (
          self.exportrequest.Otstart != "" &&
          self.exportrequest.Otend != ""
        ) {
          let inputdate = self.exportrequest.Otstart.split("-");

          let inputenddate = self.exportrequest.Otend.split("-");
          let combilearrary = [];
          for (let i = 0; i < self.myexportlist.length; i++) {
            self.tempexport = {
              oT_Request_ID: self.myexportlist[i],
            };
            combilearrary.push(self.tempexport);
          }
          let result = this.myrequestlist.filter((o1) =>
            combilearrary.some((o2) => o1.requester_ID === o2.oT_Request_ID)
          );
          self.exportlist = result.filter((v) => {
            if (this.exportrequest.Otstart && this.exportrequest.Otend) {
              const between = moment.utc(v.oT_Approved_Date);
              return between.isBetween(
                moment(this.exportrequest.Otstart),
                moment(this.exportrequest.Otend).add(1, "day")
              );
            }

            return true;
          });

          self.LoadingDialog = false;
          self.Getforrequestlist();
          self.exportdialog = false;
          let hasData = false;
          let lastSheetData = [];

          self.selectusertype = "Everyone";
          //self.myexportlist = [];
          let wb = XLSX.utils.book_new();
          let mynewstartdate = new Date(
            inputdate[0],
            inputdate[1],
            inputdate[2]
          );
          let mynewenddate = new Date(
            inputenddate[0],
            inputenddate[1],
            inputenddate[2]
          );
          let wsdata = [];
          let Difference_In_Time =
            mynewenddate.getTime() - mynewstartdate.getTime();
          let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
          let totalsheet = Difference_In_Days;

          for (let sheet = 0; sheet < totalsheet; sheet++) {
            let tempsheetdata = [];
            let input = parseInt(inputdate[2]) + sheet;
            wsdata = [];
            let startoftheyear = new Date(
              inputdate[0],
              inputdate[1] - 1,
              input
            );

            tempsheetdata = self.exportlist.filter((x) => {
              let tempdate2 = moment.utc(x.oT_Approved_Date).toDate();
              let date2 = moment(tempdate2).local().format("YYYY-MM-DD ");
              let myinputdate = date2.split("-");
              let mydatelist = new Date(
                myinputdate[0],
                myinputdate[1] - 1,
                myinputdate[2]
              );
              return mydatelist.getTime() === startoftheyear.getTime();
            });
            let tempsheetname =
              self.checkDateExport(startoftheyear.getDate()) +
              "_" +
              self.checkDateExport(startoftheyear.getMonth() + 1) +
              "_" +
              startoftheyear.getFullYear();

            if (self.exportlist.length != lastSheetData.length) {
              lastSheetData = [...lastSheetData, ...tempsheetdata];
            }
            if (tempsheetdata.length) {
              for (let j = 0; j < tempsheetdata.length; j++) {
                wsdata.push({
                  ["Employee ID"]: tempsheetdata[j].employee_Code,
                  ["Employee Name"]: tempsheetdata[j].name,
                  ["OT hours"]: tempsheetdata[j].oT_Hours,
                  ["OT start date"]: self.formatDate(
                    tempsheetdata[j].start_Date
                  ),
                  ["OT End Date"]: self.formatDate(tempsheetdata[j].end_Date),
                  ["OT Start Time"]: tempsheetdata[j].oT_Start_Time,
                  ["OT End Time"]: tempsheetdata[j].oT_End_Time,
                  ["Purpose of OT"]: tempsheetdata[j].purpose_OT,
                  ["Requested Date"]: self.convertUTCDateToLocalDate(
                    tempsheetdata[j].oT_Requested_Date
                  ),
                  ["Status"]: tempsheetdata[j].status,
                  ["Reason"]: tempsheetdata[j].reason,
                  ["Supervisor"]: tempsheetdata[j].firstName,
                  ["Date of Action"]: self.convertUTCDateToLocalDate(
                    tempsheetdata[j].oT_Approved_Date
                  ),
                });
              }
              var ws1 = XLSX.utils.json_to_sheet(wsdata);
              ws1["!cols"] = [
                { wch: 20 },
                { wch: 25 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
                { wch: 20 },
              ];
              XLSX.utils.book_append_sheet(wb, ws1, tempsheetname);
              hasData = true;
            }
          }

          if (!hasData) {
            alert("Your selected date range doesn't have Data!");
          } else {
            let wsalldata = [];
            lastSheetData.sort(function (a, b) {
              var order = 1;
              a = new Date(self.convertUTCDateToLocalDate(a.oT_Approved_Date));
              b = new Date(self.convertUTCDateToLocalDate(b.oT_Approved_Date));
              // a = mydate;
              // b = mysecdate;
              var results = a > b ? -1 : a < b ? 1 : 0;

              return results * order;
            });
            lastSheetData.reverse();
            for (let j = 0; j < lastSheetData.length; j++) {
              wsalldata.push({
                ["Employee ID"]: lastSheetData[j].employee_Code,
                ["Employee Name"]: lastSheetData[j].name,
                ["OT hours"]: lastSheetData[j].oT_Hours,
                ["OT start date"]: self.formatDate(lastSheetData[j].start_Date),
                ["OT End Date"]: self.formatDate(lastSheetData[j].end_Date),
                ["OT Start Time"]: lastSheetData[j].oT_Start_Time,
                ["OT End Time"]: lastSheetData[j].oT_End_Time,
                ["Purpose Of OT"]: lastSheetData[j].purpose_OT,
                ["Requested Date"]: self.convertUTCDateToLocalDate(
                  lastSheetData[j].oT_Requested_Date
                ),
                ["Status"]: lastSheetData[j].status,
                ["Reason"]: lastSheetData[j].reason,
                ["Supervisor"]: lastSheetData[j].firstName,
                ["Date of Action"]: self.convertUTCDateToLocalDate(
                  lastSheetData[j].oT_Approved_Date
                ),
              });
            }
            ws = XLSX.utils.json_to_sheet(wsalldata);
            ws["!cols"] = [
              { wch: 20 },
              { wch: 25 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
              { wch: 20 },
            ];
            let datestring1 =
              inputdate[2] + "_" + inputdate[1] + "_" + inputdate[0];
            let datestring2 =
              inputenddate[2] + "_" + inputenddate[1] + "_" + inputenddate[0];
            XLSX.utils.book_append_sheet(
              wb,
              ws,
              datestring1 + "-" + datestring2
            );
          }

          if (hasData) {
            let exportExcelName = `OptimisticOTRequestHistory_${self.excelExportDateRange()}.xlsx`;

            self.exportList = [];
            self.exportrequest.Otstart = "";
            self.exportrequest.Otend = "";
            XLSX.writeFile(wb, exportExcelName);
            // self.LogTrace(null, "Export OT request", 24, "Verbose");
          }
        }
      }
    },
    Getrequestlist() {
      let self = this;

      self.LoadingDialog = true;
      let temp = {
        company_ID: store.state.companyID,
      };
      axios
        .post(`${self.url}OT_Request/GetHROT_Request`, temp)
        .then(function (response) {
          let list = response.data.data;
          self.myrequestlist = response.data.data;
          self.requestlist = list.map((e) => ({
            ...e,
            check: false,
          }));
          self.filteremplist = self.requestlist;
          self.LoadingDialog = false;
          self.runningvalue = 0;
        })
        .catch(function (error) {
          alert(error);
          self.LoadingDialog = false;
        });
    },
    Openexport() {
      let self = this;
      self.exportdialog = true;
      if (self.alldate == true) {
        self.hidestartandenddate = false;
      } else {
        self.hidestartandenddate = true;
      }
      self.results = self.requestlist.filter(
        (date) => (date.request_On = date.oT_Approved_Date + "Z")
      );
      self.liesting = [];
      for (let i = 0; i < self.results.length; i++) {
        if (self.results[i].check == false) {
          self.liesting.push(false);
        } else {
          self.liesting.push(true);
        }
      }
      self.checkData = false;

      if (self.liesting.some((e) => e == true)) {
        self.checkData = false;
      } else {
        self.checkData = true;
      }
      if (self.checkData == true) {
        self.selectusertype = "Everyone";
      } else {
        self.selectusertype = "Selected Individual";
      }
    },
    async Getbydatelist() {
      try {
        //  this.reportDateTo = new Date();
        if (this.reportDateFrom != "" && this.reportDateTo != "") {
          this.LoadingDialog=true;
          let temp = {
            company_ID: store.state.companyID,
          };
          const response = await axios.post(
            `${this.url}OT_Request/GetHROT_Request`,
            temp
          );
          let list = response.data.data;
          this.alldate = false;
          let filterList = list.filter((v) => {
            let stillUtc = moment.utc(v.oT_Approved_Date).toDate(); //still utc
            let request_On = moment(stillUtc).local().format("YYYY-MM-DD");
            return (
              request_On >= moment(this.reportDateFrom).format("YYYY-MM-DD") &&
              request_On <= moment(this.reportDateTo).format("YYYY-MM-DD")
            );
          });

          this.requestlist = filterList.map((e) => ({
            ...e,
            check: false,
          }));

          this.LoadingDialog = false;
        }
      } catch (ex) {
        alert(ex + "Correlation ID is 24");
        throw ex;
      }
    },
    detailrequest(temp) {
      let self = this;
      self.Detaildialog = true;
      self.Detailrequest.WFID = temp.wF_ID;
      self.Detailrequest.attachmentlist = temp.attachmentlist;
      self.Detailrequest.RequestedDate = self.convertUTCDateToLocalDateForReq(
        temp.oT_Requested_Date
      );
      self.Detailrequest.status = temp.status;
      self.Detailrequest.EmployeeName = temp.name;
      self.Detailrequest.Position = temp.positionName;
      if (temp.wholeDay == true) {
        self.Detailrequest.OTdate =
          self.formatDate(temp.start_Date) +
          " " +
          self.formatDate(temp.end_Date);
      } else {
        self.Detailrequest.OTdate =
          self.formatDate(temp.start_Date) +
          " " +
          self.formatDate(temp.end_Date);
      }
      self.Detailrequest.OTtype=temp.oT_Type==null||temp.oT_Type==""?"OT2":temp.oT_Type;
      self.Detailrequest.OThours = temp.oT_Hours;
      let localstartdate = ConverttolocalwithStroke(temp.start_Date);
      let localenddate = ConverttolocalwithStroke(temp.end_Date);
      self.Detailrequest.Startime = self.$moment(localstartdate).format('DD/MM/YYYY HH:mm');
      self.Detailrequest.Endtime = self.$moment(localenddate).format('DD/MM/YYYY HH:mm');

      // self.Detailrequest.Startime = self.convertUTCDateToLocalDateForReq(self.addDateAndTime(temp.start_Date,temp.oT_Start_Time));
      // self.Detailrequest.Endtime = self.convertUTCDateToLocalDateForReq(self.addDateAndTime(temp.end_Date,temp.oT_End_Time));

      self.Detailrequest.Description = temp.purpose_OT;
      self.Detailrequest.ActionNote = temp.reason;
      self.Detailrequest.Approver = temp.firstName;
      self.Detailrequest.ApprovalDatetime =
        self.convertUTCDateToLocalDateForReq(temp.oT_Approved_Date);
    },

    async CancelOTreq() {
      let self = this;
      self.ActionnoteSent = [(v) => !!v || "Action Note is required"];
      if (self.Detailrequest.ActionNote != "" && self.Detailrequest.ActionNote != null) {
        let temp = {
          wF_ID: self.Detailrequest.WFID,
          status: "Canceled",
          reason: self.Detailrequest.ActionNote,
        };
        self.LoadingDialog = true;
        await axios
          .post(`${self.url}MyTasks/UpdateOTStatus`, temp)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              // self.Detailrequest.ActionNote = "";
              self.Detaildialog = false;
              self.LoadingDialog = false;
              self.cancelstatus = true;
              self.showcancel = true;
            }
          })
          .catch(function (error) {
            alert(error);
          });
      } else {
        self.LoadingDialog = false;
        alert("Please fill required field");
      }
    },
    clearDetail() {
      let self = this;
      self.Detailrequest.WFID = "";
      self.Detailrequest.RequestedDate = "";
      self.Detailrequest.status = "";
      self.Detailrequest.EmployeeName = "";
      self.Detailrequest.Position = "";
    },
  },
};
</script>
<style scoped>
.container {
  display: flex;
  /* Use flexbox to align children horizontally */
  align-items: center;
  /* Center vertically within container */
  /* margin-left: 10px; */
  flex-wrap: wrap;
  /* justify-content: space-around; */
  gap: 20px;
}

.tdborder {
  border-right: 1px solid #cfcdcd;
}

.txtcenter {
  text-align: center;
}

>>>.v-data-table tr td {
  height: 70px;
}

>>>.mdi-download:hover {
  color: #f99d20;
}

>>>.theme--light.v-data-table thead tr th {
  color: #f99d20;
  font-weight: normal;
  font-size: 15px;
  text-align: center;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  color: #f99d20;
  font-weight: normal;
  font-size: 15px;
  /* text-align: center; */
}

>>>.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  height: 70px;
}

tbody tr:nth-of-type(even) {
  background-color: #f8f8f8 !important;
}

>>>.tile:hover {
  background: #f2e4d1 !important;
}

>>>.tile:active {
  background: #f99d20 !important;
}

>>>.tile2:hover {
  background: #f2e4d1 !important;
}

>>>.tile2:active {
  background: #f99d20 !important;
}

.readonly.v-text-field>.v-input__control>.v-input__slot {
  background-color: #eeeeee !important;
}
.infoimg{
  display: inline-block;
  width: 15px!important;
  position: absolute;
  top: 13px;
  right: -7px;
  transition: transform 0.6s ease-in-out;
  margin-bottom: -4px!important;
}
</style>