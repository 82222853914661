<template>
  <div style="margin-left: 1.5rem; margin-right: 1.5rem; margin-bottom: 5rem">
    <div>
      <p style="font-size: 25px; color: #43425d; margin-top: 15px">
        {{ $t("RoleEdit") }}
      </p>
    </div>
    <v-card class="mt-6">
      <div style="padding-top: 20px; margin-left: 2rem; margin-right: 2rem">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              autocomplete="off"
              color="#F99D20"
              :error-messages="error.role_Code"
              v-model="add.role_Code"
              dense
              ><template v-slot:label>
                <span>
                  {{ $t("RoleCode") }}<span class="red--text"> *</span></span
                >
              </template></v-text-field
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              autocomplete="off"
              v-model="add.role_Name"
              :error-messages="error.role_Name"
              color="#F99D20"
              dense
              ><template v-slot:label>
                <span>
                  {{ $t("RoleName") }}<span class="red--text"> *</span></span
                >
              </template></v-text-field
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              autocomplete="off"
              v-model="add.rolepermis"
              readonly
              color="#F99D20"
              :error-messages="error.permission"
              dense
              ><template v-slot:label>
                <span>
                  {{ $t("Permission") }}<span class="red--text"> *</span></span
                >
              </template></v-text-field
            >
          </v-col>
          <v-col>
            <v-btn
              @click="gotoPermission"
              color="#F99D20"
              class="mr-4 text-capitalize"
              width="120"
              rounded
              height="40"
              >{{ $t("edit") }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <div style="height: 40vh"></div>
      <v-card-actions class="pb-6 mr-5">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          class="mr-2 text-capitalize commoncancelbtn"
          @click="back()"
          text
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          class="mr-4 text-capitalize commonsavebtn"
          :disabled="disabled"
          @click="EditRole()"
          >{{ $t("save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- loadingdialog -->
    <LoadingDialog :LoadingDialog="loadingdialog"></LoadingDialog>
  </div>
</template>
<script>
import store from "@/store";
import axios from "axios";
import enurl from "@/api/environment";
import LogTrace from "@/function/Log.js";
import { utils } from "@/function/AuthenticatedRedirect.js";
import LoadingDialog from "@/components/Loading.vue";

export default {
  components:{
    LoadingDialog,
  },
  mixins: [LogTrace, utils],
  data() {
    return {
      loadingdialog: false,
      disabled: false,
      rId: 0,
      url: enurl.apiUrl,
      tempper: [],
      tempRolePer: [],
      krisRole: [],
      RoleList: [],
      TempPerList: [],
      PermissionList: [],
      updateper: [],

      error: {
        role_Code: null,
        role_Name: null,
        permission: null,
      },
      add: {
        role_Code: null,
        role_Name: null,
        perId: [],
        rolepermis: [],
      },
      edit: {
        rolId: [],
      },
      perlist: [],
    };
  },
  mounted() {
    this.getRole();
    this.GetPermission();
  },
  methods: {
    GetPermission() {
      let self = this;
      axios
        .get(`${self.url}Permission/GetPermission`)
        .then(function (response) {
          self.perlist = response.data.data;
          localStorage.setItem("lists", JSON.stringify(self.perlist));
        });
    },
    getRole() {
      let self = this;
      self.loadingdialog = true;
      let temp = {
        role_ID: parseInt(self.$route.params.rid),
        company_ID: store.state.companyID,
      };
      axios.post(`${self.url}Role/GetRoleInfo`, temp).then(function (response) {
        self.RoleList = response.data.data;
        self.role_ID = response.data.data.role_ID;

        //console.log(self.RoleList);

        if (self.RoleList != null) {
          self.add.role_Code = response.data.data.role_Code;
          self.add.role_Name = response.data.data.role_Name;
          localStorage.setItem("rname", JSON.stringify(self.add.role_Name));
          self.add.rolepermis = response.data.data.rolePerData.map(
            (x) => x.permission_Name
          );
          if (self.add.role_Name == "Admin" || self.add.role_Name == "HR") {
            self.add.rolepermis =
              "Home - View,Announcement - Add,Announcement - Edit,Announcement - Delete,Home - Clock In,Home - CommonStatisticsPieChart_UpcomingTimeOff,Personnel Management - View,Personnel Management - Add,Personnel Management - Batch Upload,Staff Time Attendance - View,Staff Time Attendance - Approve";
            self.disabled = true;
          } else {
            self.disabled = false;
          }

          self.tempper = JSON.parse(localStorage.getItem("krislocal"));
          if (self.tempper != null) {
            self.add.rolepermis = [...self.tempper.map((x) => x.pername)];
            self.add.rolepermis = [...new Set(self.add.rolepermis)];
            self.tempRolePer = [...self.tempper.map((x) => x.perid)];
            if (self.tempRolePer != null) {
              for (let j = 0; j < self.tempRolePer.length; j++) {
                self.updateper.push({
                  permission_ID: self.tempRolePer[j],
                });
              }
            }
          } else {
            let tempr = response.data.data.rolePerData.map(
              (x) => x.permission_ID
            );
            for (let x = 0; x < tempr.length; x++) {
              self.updateper.push({
                permission_ID: tempr[x],
              });
            }
          }
        }
        self.loadingdialog = false;
      });
    },
    DeleteRole() {
      let self = this;
      let id = parseInt(self.$route.params.rid);
      let tempdel = {
        role_ID: id,
      };
      axios
        .post(`${self.url}Role/DeleteOldRoleInfo`, tempdel)
        .then(function () {})
        .catch(function (error) {
          alert(error);
        });
    },
    EditRole() {
      let self = this;
      self.loadingdialog = true;
      self.error.role_Code = !self.add.role_Code
        ? "Please fill in the required fields"
        : "";
      self.error.role_Name = !self.add.role_Name
        ? "Please fill in the required fields"
        : "";

      self.error.permission = !self.add.rolepermis
        ? "Please fill in the required fields"
        : "";
      self.error.permission =
        self.add.rolepermis == "" ? "Please fill in the required fields" : "";
      let tempvali = self.ValidateAddTrue();

      if (tempvali == true) {
        self.DeleteRole();

        let roleshift = {
          role_ID: parseInt(self.$route.params.rid),
          company_ID: store.state.companyID,
          role_Name: self.add.role_Name,
          role_Code: self.add.role_Code,
          rolePerData: self.updateper,
        };

        axios
          .post(`${self.url}Role/UpdateRole`, roleshift)
          .then(function (response) {
            if (response.data.status == 0) {
              alert(response.data.message);
              localStorage.setItem("krislocal", JSON.stringify(null));
              self.LogTrace(null, "Update Role ", 37, "Medium");
              self.$router.push("/usermanagement/usergroup/role").catch(()=>{});
              self.loadingdialog = false;
            }
          })
          .catch(function (error) {
            self.LogTrace(error, "Update Role Fail", 37, "Medium");
            alert(error);
            self.loadingdialog = false;
          });
      } else {
        this.ValidateAddFalse();
      }
    },

    ValidateAddTrue() {
      let self = this;
      if (
        self.add.role_Code != null &&
        self.add.role_Name != null &&
        self.add.rolepermis != null &&
        self.add.rolepermis != "" &&
        self.add.role_Code != "" &&
        self.add.role_Name != ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    ValidateAddFalse() {
      let self = this;
      if (
        self.add.role_Code == null ||
        self.add.role_Name == null ||
        self.add.rolepermis == null ||
        self.add.rolepermis == "" ||
        self.add.role_Code == "" ||
        self.add.role_Name == ""
      ) {
        alert("Please fill in the required fields!");
      }
    },

    gotoPermission() {
      let self = this;
      self.rId = parseInt(self.$route.params.rid);
      self.$router.push({
        name: "roleeditper",
        params: {
          role: "rolepermissionedit",
          rid: self.rId,
          roleper: "roleperedit",
        },
      }).catch(()=>{});
    },
    back() {
      this.$router.push("/usermanagement/usergroup/role").catch(()=>{}).catch(()=>{});
      localStorage.setItem("krislocal", JSON.stringify(null));
      localStorage.setItem("krislocal", JSON.stringify(null));
    },
  },
};
</script>
<style scoped></style>
